import { AspectRatioProps as ChakraAspectRatioProps, ResponsiveValue } from '@chakra-ui/react';
import { SystemProps } from '@elemeno/ui';

export interface AspectRatioProps extends ChakraAspectRatioProps {
	ratio?: ResponsiveValue<number>;
	maxWidth?: SystemProps['maxWidth'];
}

export const AspectRatioDefaultProps: AspectRatioProps = {
	ratio: 4 / 3,
};
