import { forwardRef, useMemo } from 'react';

import { HiOutlineMinusCircle, HiOutlinePlusCircle } from 'react-icons/hi';
import {
	Accordion as ChakraAccordion,
	AccordionItem,
	AccordionButton as ChakraAccordionButton,
	AccordionPanel,
} from '@chakra-ui/react';
import { Text, SystemProps, Icon, Flex, IconProps } from '@elemeno/ui';

import { AccordionProps, AccordionDefaultProps } from './Accordion.types';

const AccordionButton: React.FC<AccordionProps & Pick<IconProps, 'as'>> = ({ title, as, display, _expanded }) => {
	return (
		<ChakraAccordionButton display={display} _expanded={_expanded}>
			<Flex.H>
				<Text {...TitleStyles}>{title}</Text>
				<Icon as={as} />
			</Flex.H>
		</ChakraAccordionButton>
	);
};

const Accordion = forwardRef<HTMLDivElement, AccordionProps>(
	({ items, isMotionless, canOpenMultiple, canToggle, ...props }, ref) => {
		const accordionItems = useMemo(
			() =>
				items?.map(({ key, title, content }) => (
					<AccordionItem key={key || title}>
						<h2>
							<AccordionButton
								title={title}
								as={HiOutlineMinusCircle}
								display="none"
								_expanded={{ display: 'block' }}
							/>
							<AccordionButton
								title={title}
								as={HiOutlinePlusCircle}
								display="block"
								_expanded={{ display: 'none' }}
							/>
						</h2>
						<AccordionPanel {...ContentStyles}>{content}</AccordionPanel>
					</AccordionItem>
				)) || null,
			[items],
		);

		return (
			<ChakraAccordion
				ref={ref}
				reduceMotion={isMotionless}
				allowMultiple={canOpenMultiple}
				allowToggle={canToggle}
				{...AccordionStyles}
				{...props}
			>
				{accordionItems}
			</ChakraAccordion>
		);
	},
);

const AccordionStyles: SystemProps = {
	width: 'full',
};

const TitleStyles: SystemProps = {
	flex: '1',
	textAlign: 'left',
};

const ContentStyles: SystemProps = {
	paddingBottom: 4,
};

Accordion.displayName = 'Accordion';
Accordion.defaultProps = AccordionDefaultProps;

export { Accordion };
