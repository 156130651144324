// / <reference types="@types/segment-analytics" />

import { isOffline, isTest } from 'helpers/constants';
import { captureException } from 'utils/ErrorLogger';
import logger from 'utils/logger';
import { AnalyticsEvents, AnalyticsEvent, AnalyticsProps } from './AnalyticsEvents';
import { wrapProps } from './useAnalytics';

/**
 * Analytics Core : base layer for analytics
 */

// export const load = () => {
//   window.analytics.load(config.segmentWriteKey)
// }

declare global {
	interface Window {
		analytics: SegmentAnalytics.AnalyticsJS;
		Intercom: any;
	}
}

/**
 * Analytics code
 */

interface AnalyticsCompany {
	name?: string;
	id?: string | number;
	industry?: string;
	employee_count?: number;
	plan?: string;
}

// prettier-ignore
export interface AnalyticsUserTraits {
	site          : string;
	siteType      : string;
	role          : string;
	primaryTeam  ?: string;

	address    ?: AnalyticsAddress; // Street address of a user optionally containing: city, country, postalCode, state or street
	age        ?: number          ; // Age of a user
	birthday   ?: string          ; // User’s birthday
	company    ?: AnalyticsCompany; // Company the user represents, optionally containing: name (a String), id (a String or Number), industry (a String), employee_count (a Number) or plan (a String)
	createdAt  ?: string          ; // Date the user’s account was first created. Segment recommends using ISO-8601 date strings.
	description?: string          ; // Description of the user
	email      ?: string          ; // Email address of a user
	firstName  ?: string          ; // First name of a user
	gender     ?: string          ; // Gender of a user
	id         ?: string          ; // Unique ID in your database for a user
	lastName   ?: string          ; // Last name of a user
	name       ?: string          ; // Full name of a user. If you only pass a first and last name Segment automatically fills in the full name for you.
	phone      ?: string          ; // Phone number of a user
	title      ?: string          ; // Title of a user, usually related to their position at a specific company. Example: “VP of Engineering”
	username   ?: string          ; // User’s username. This should be unique to each user, like the usernames of Twitter or GitHub.
	website    ?: string          ; // Website of a user
}

interface AnalyticsAddress {
	city?: string;
	country?: string;
	postalCode?: string;
	state?: string;
	street?: string;
}

// This is the definition of page options that we're using, but does not mesh well with SegmentAnalytics.SegmentOpts
// type IntercomOptions = {
// 	hideDefaultLauncher: boolean;
// };
// type AnalyticsPageOptions = {
// 	Intercom: IntercomOptions;
// };

// use a generic object to work with SegmentAnalytics.SegmentOpts
export type AnalyticsPageOptions = { [name: string]: number | string | boolean | AnalyticsPageOptions | undefined };

interface AnalyticsGroupTraits {
	name: string; // reserved: Name of a group
	plan: string; // reserved: Plan that a group is in (repurposed to type)
	type: string; // custom: type prop

	// id: string; // reserved: Unique ID in your database for a group
	// address: AnalyticsAddress; // 	Street address of a group This should be a dictionary containing optional city, country, postalCode, state or street.
	// createdAt: string; // 	Date the group’s account was first created We recommend ISO-8601 date strings.
	// description: string; // 	Description of the group, like their personal bio
	// email: string; // 	Email address of group
	// employees: string; // 	Number of employees of a group, typically used for companies
	// industry: string; // 	Industry a user works in, or a group is part of
	// phone: string; // 	Phone number of a group
	// website: string; // 	Website of a group
}

// interface AnalyticsOptions {
// 	integrations?: { [name: string]: boolean };
// 	// enable/disable certain integrations: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#managing-data-flow-with-the-integrations-object
// 	obfuscate?: boolean;
// 	// obscure url to avoid ad-blockers from triggering? https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#bundle-obfuscation
// }

interface AnalyticsServiceInterface {
	page: (
		category?: string,
		name?: string,
		properties?: AnalyticsProps,
		options?: AnalyticsPageOptions,
		callback?: () => void,
	) => void;
	track: (event: AnalyticsEvent, callback?: () => void) => void;
	// trackLink / trackForm also accept arrays of elements/forms, but not used here
	trackLink: (element: HTMLAnchorElement, event: AnalyticsEvent) => void;
	trackForm: (form: HTMLFormElement, event: AnalyticsEvent) => void;
	group: (groupId: string, traits?: AnalyticsGroupTraits, callback?: () => void) => void;
	identify: (userId: string, intercomHash: string, traits: AnalyticsUserTraits, callback?: () => void) => void;
	alias: (userId: string, callback?: () => void) => void;
	reset: () => void;
}

// helpers for running trackLink as an event listener
const testTrackLinkContext = {};
const testTrackLinkEvent = (e) => {
	logger.debug('analytics.trackLink()', JSON.stringify(testTrackLinkContext[e.target.href]));
};

/**
 * Compose appropriate options based on event (i.e. included/exclude integrations as required)
 * @param event An analytics event that may be selected for logging to Intercom
 * @returns AnalyticsOptions
 */
function composeOptions(event: AnalyticsEvent) {
	if (event.intercomId) {
		return undefined; // for now just pass to everyone (to do later: send intercom a better id)
	} else {
		return { integrations: { Intercom: false } };
	}
}

let _AnalyticsService: AnalyticsServiceInterface;

let _embargoActive = 0;

const USER_EVENTS = ['mousedown', 'mousemove', 'touchstart', 'keydown', 'scroll'];
const EMBARGO_DELAY = 1000 * 5; // delay lifting embargo for 5 seconds

function onUserEvent(e) {
	if (_embargoActive && _embargoActive + EMBARGO_DELAY < Date.now()) {
		disableEmbargo(e.type);
	}
}

export function enableEmbargo() {
	logger.debug('enableEmbargo', _embargoActive);
	if (_embargoActive) return;
	_embargoActive = Date.now();
	_AnalyticsService = setUpEmbargoedAnalytics(getUnderlyingAnalytics());
	USER_EVENTS.forEach((event) => window.addEventListener(event, onUserEvent));
}

const embargoedCalls = [] as (() => void)[]; // queue of calls to make once embargo is lifted

function disableEmbargo(type) {
	logger.debug('disableEmbargo', _embargoActive);
	if (!_embargoActive) return;
	USER_EVENTS.forEach((event) => window.removeEventListener(event, onUserEvent));
	_embargoActive = 0;
	_AnalyticsService = getUnderlyingAnalytics();
	while (embargoedCalls.length) {
		embargoedCalls[0]();
		embargoedCalls.shift(); // only remove from array if call successful
		// TODO: an error should break this loop (and leave the call), but need to setup a retry mechanism.
	}

	_AnalyticsService.track(AnalyticsEvents.embargoCleared({ type }));
}

const mockAnalytics = {
	page: (category, name, properties, options, callback) => {
		logger.debug(
			`analytics.page() ${category}/${name}, props:`,
			JSON.stringify(properties),
			JSON.stringify(options),
		);
		if (callback) callback();
	},
	track: (event, callback) => {
		logger.debug('analytics.track()', JSON.stringify(event), `${callback ? '' : 'no '}cb`);
		if (callback) callback();
	},
	trackLink: (element, event) => {
		testTrackLinkContext[element.href] = event;
		element.addEventListener('click', testTrackLinkEvent);
	},
	trackForm: (form, event) => logger.debug('analytics.trackForm()', event.code, JSON.stringify(event.props)),
	group: (groupId, traits, callback) => {
		logger.debug('analytics.group()', groupId, traits);
		if (callback) callback();
	},
	identify: (userId, intercomHash, traits, callback) => {
		logger.debug('analytics.identify()', userId, intercomHash, traits);
		if (callback) callback();
	},
	alias: (userId, callback) => {
		logger.debug('analytics.alias()', userId);
		if (callback) callback();
	},
	reset: () => logger.debug('analytics.reset()'),
};

// dictionary of recent events, to prevent reload spamming
const RECENT_EVENTS: Record<string, number> = {};
const ANALYTICS_THROTTLE = 1000; // 1 second

function throttle(...args: string[]) {
	// clear any old events
	Object.entries(RECENT_EVENTS).forEach(([key, value]) => {
		if (value < Date.now() - ANALYTICS_THROTTLE) {
			delete RECENT_EVENTS[key];
		}
	});

	const event_code = args.join('.');
	const recentlyLogged = RECENT_EVENTS[event_code] > Date.now() - ANALYTICS_THROTTLE;
	RECENT_EVENTS[event_code] = Date.now(); // update the last logged date
	return recentlyLogged;
}

const realAnalytics = {
	// options undefined (send to all integrations)
	page: (category, name, properties, options, callback) =>
		// don't worry about props, there shouldn't be the same page logged more than once in a second
		throttle('page', category, name) ||
		window.analytics.page(category, name, wrapProps(properties), options, callback),
	track: (event, callback) =>
		// include props in throttle as we may log an event multiple times with different props (i.e. multiple announcement views on home page)
		throttle('track', event.code, JSON.stringify(event.props)) ||
		window.analytics.track(event.code, wrapProps(event.props), composeOptions(event), callback),
	trackLink: (element, event) => window.analytics.trackLink(element, event.code, wrapProps(event.props)),
	trackForm: (form, event) => window.analytics.trackForm(form, event.code, wrapProps(event.props)),
	// Implemented on the server when registering/changing groups
	group: (groupId, traits, callback) => window.analytics.group(groupId, traits, undefined, callback),
	// options undefined (send to all integrations)
	identify: (userId, intercomHash, traits, callback) => {
		const options = intercomHash ? ({ Intercom: { user_hash: intercomHash } } as any) : undefined;
		window.analytics.identify(userId, traits, options, callback);
	},
	// previousId defaults to current id (anon), options undefined (send to all integrations)
	alias: (userId, callback) => window.analytics.alias(userId, undefined, undefined, callback),
	reset: () => analytics.reset(),
};

function getUnderlyingAnalytics(): AnalyticsServiceInterface {
	if (!isTest && !isOffline && !window.analytics) {
		captureException(new Error('window.analytics has not loaded'));
	}
	return isTest || isOffline || !window.analytics ? mockAnalytics : realAnalytics;
}

function setUpEmbargoedAnalytics(underlyingAnalytics) {
	return {
		page: (category, name, properties, options, callback) => {
			embargoedCalls.push(() => underlyingAnalytics.page(category, name, properties, options));
			if (callback) callback(); // don't embargo callbacks as they may be used to trigger other events
		},
		track: (event, callback) => {
			embargoedCalls.push(() => underlyingAnalytics.track(event));
			if (callback) callback();
		},
		trackLink: (element, event) => {
			// TODO: how to embargo the event from the link, instead of the linking
			underlyingAnalytics.trackLink(element, event);
		},
		trackForm: (form, event) => {
			// TODO: how to embargo the event from the link, instead of the linking
			underlyingAnalytics.trackForm(form, event);
		},
		group: (groupId, traits, callback) => {
			embargoedCalls.push(() => underlyingAnalytics.group(groupId, traits));
			if (callback) callback();
		},
		identify: (userId, intercomHash, traits, callback) => {
			embargoedCalls.push(() => underlyingAnalytics.identify(userId, intercomHash, traits));
			if (callback) callback();
		},
		alias: (userId, callback) => {
			embargoedCalls.push(() => underlyingAnalytics.alias(userId));
			if (callback) callback();
		},
		reset: () => embargoedCalls.push(() => underlyingAnalytics.reset()),
	};
}

export function getAnalyticsService(): AnalyticsServiceInterface {
	if (!_AnalyticsService) {
		_AnalyticsService = getUnderlyingAnalytics();
	}
	return _AnalyticsService;
}

/**
 * Static method for event tracking, useful for logging outside of React context.
 * @param event
 */
export function analyticsTrack(event: AnalyticsEvent): void {
	getAnalyticsService().track(event);
}

/**
 * Static method for event tracking, useful for logging outside of React context.
 * @param event
 */
export function analyticsPage(category?: string, name?: string, hideIntercom?: boolean, properties = {}): void {
	const options = { Intercom: { hideDefaultLauncher: !!hideIntercom } };
	getAnalyticsService().page(category, name, properties, options);
}
