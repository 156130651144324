import { FlexProps } from '@elemeno/ui';

export interface DividerProps
	extends Omit<FlexProps, 'align' | 'basis' | 'direction' | 'grow' | 'justify' | 'shrink' | 'wrap'> {
	variant?: 'solid' | 'dashed';
	color?: string;
	textColor?: string;
	isVertical?: boolean;
}
export const DividerDefaultProps: DividerProps = {
	variant: 'solid',
	color: 'gray.300',
	textColor: 'gray.500',
	isVertical: false,
};
