import type { ReactDatePickerProps } from 'react-datepicker';

import { InputProps } from '../Input';

export interface DatePickerProps<
	CustomModifierNames extends string = never,
	WithRange extends boolean | undefined = undefined,
> extends ReactDatePickerProps<CustomModifierNames, WithRange>,
		Pick<
			InputProps,
			| 'size'
			| 'variant'
			| 'label'
			| 'subLabel'
			| 'placeholder'
			| 'hint'
			| 'error'
			| 'isInvalid'
			| 'isRequired'
			| 'isDisabled'
			| 'isReadOnly'
		> {
	label?: string;
	/** A sub label that appears below the component */
	hint?: string;
	caption?: string;
	/** If present, it will replace the hint and apply invalid styles */
	error?: string;
}

export const DatePickerDefaultProps: Omit<DatePickerProps, 'onChange'> = {
	label: '',
	subLabel: '',
	hint: '',
	caption: '',
	error: '',
	placeholder: '',
	size: 'md',
	variant: 'outline',
	isReadOnly: false,
	isRequired: false,
	isDisabled: false,
	isInvalid: false,
};
