import { forwardRef, useMemo, Children } from 'react';
import { List as ChakraList, ListItem, ListIcon, OrderedList, UnorderedList } from '@chakra-ui/react';
import { Box, Flex, Text } from '@elemeno/ui';

import { ListProps, ListDefaultProps } from './List.types';

const List = forwardRef<HTMLDivElement, ListProps>(
	({ children, icon, iconColor, isOrdered, isUnordered, ...props }, ref) => {
		const listItems = useMemo(
			() =>
				children
					? Children.toArray(children).map((child, index) => (
							<ListItem key={index} pl={2}>
								<Flex.H alignItems="flex-start">
									{icon && !isOrdered && !isUnordered && (
										// list icons are slightly low, so pushing up slightly
										<Text position="relative" marginTop="-1px">
											<ListIcon as={icon} color={iconColor} />
										</Text>
									)}
									{child}
								</Flex.H>
							</ListItem>
					  ))
					: null,
			[children, icon, iconColor, isOrdered, isUnordered],
		);

		let listComponent = <ChakraList {...props}>{listItems}</ChakraList>;

		if (isOrdered) {
			listComponent = <OrderedList {...props}>{listItems}</OrderedList>;
		} else if (isUnordered) {
			listComponent = <UnorderedList {...props}>{listItems}</UnorderedList>;
		}

		return <Box ref={ref}>{listComponent}</Box>;
	},
);

List.displayName = 'List';
List.defaultProps = ListDefaultProps;

export { List };
