import { forwardRef } from 'react';
import { Heading as ChakraHeading, Highlight } from '@chakra-ui/react';

import { HeadingProps, HeadingDefaultProps } from './Heading.types';

// TODO: Replace highlight prop with query + highlightVariant
const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(({ children, highlight, ...props }, ref) => {
	return (
		<ChakraHeading ref={ref} {...props}>
			{highlight ? <Highlight {...highlight}>{`${children}`}</Highlight> : children}
		</ChakraHeading>
	);
});

Heading.displayName = 'Heading';
Heading.defaultProps = HeadingDefaultProps;

export { Heading };
