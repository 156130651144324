import { forwardRef } from 'react';
import { Grid as ChakraGrid, GridItem, SimpleGrid } from '@chakra-ui/react';

import { GridProps, GridDefaultProps } from './Grid.types';

const Grid = forwardRef<HTMLDivElement, GridProps>(({ ...props }, ref) => {
	return <ChakraGrid ref={ref} {...props} />;
});

Grid.displayName = 'Grid';
Grid.defaultProps = GridDefaultProps;

const GridNotation = Object.assign(Grid, { Item: GridItem, Simple: SimpleGrid });
export { GridNotation as Grid };
