import { forwardRef } from 'react';
import { Portal as ChakraPortal } from '@chakra-ui/react';
import { Box } from '@elemeno/ui';

import { PortalProps, PortalDefaultProps } from './Portal.types';

const Portal = forwardRef<HTMLDivElement, PortalProps>(({ children, ...props }, ref) => {
	return (
		<ChakraPortal {...props}>
			<Box ref={ref}>{children}</Box>
		</ChakraPortal>
	);
});

Portal.displayName = 'Portal';
Portal.defaultProps = PortalDefaultProps;

export { Portal };
