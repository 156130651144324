import { forwardRef, useState, useMemo, useEffect } from 'react';
import { Reorder } from 'framer-motion';
import { Box } from '@elemeno/ui';

import { DraggableListProps, DraggableListDefaultProps } from './DraggableList.types';

const DraggableList = forwardRef<HTMLDivElement, DraggableListProps>(({ initialItems, onChange, ...props }, ref) => {
	const [items, setItems] = useState(initialItems);

	const listItems = useMemo(
		() =>
			items
				? items.map((item) => (
						<Reorder.Item key={item} as="div" value={item} style={{ cursor: 'grab', padding: '0.25rem' }}>
							{item}
						</Reorder.Item>
				  ))
				: null,
		[items],
	);

	// TODO: Call on drag end
	useEffect(() => {
		if (items && onChange) {
			onChange(items);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	return (
		<Box ref={ref} {...props}>
			<Reorder.Group axis="y" values={items} onReorder={setItems}>
				{listItems}
			</Reorder.Group>
		</Box>
	);
});

DraggableList.displayName = 'DraggableList';
DraggableList.defaultProps = DraggableListDefaultProps;

export { DraggableList };
