import { forwardRef, useMemo } from 'react';
import {
	Modal as ChakraModal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
} from '@chakra-ui/react';
import { Button, Flex } from '@elemeno/ui';

import { ModalProps, ModalDefaultProps } from './Modal.types';

const Modal = forwardRef<HTMLDivElement, ModalProps>(
	(
		{
			children,
			title,
			titleStyles,
			buttons,
			canPinchZoom,
			startFocused,
			isScrollDisabled,
			isCloseDisabled,
			canEscape,
			canOverlayClose,
			hasScrollGap,
			isFocusReturned,
			alignButtons,
			...props
		},
		ref,
	) => {
		const actionButtons = useMemo(
			() => buttons?.map(({ ...props }, index) => <Button key={index} {...props} />) || null,
			[buttons],
		);

		return (
			<ChakraModal
				// eslint-disable-next-line jsx-a11y/no-autofocus
				autoFocus={startFocused}
				returnFocusOnClose={isFocusReturned}
				allowPinchZoom={canPinchZoom}
				closeOnEsc={canEscape}
				closeOnOverlayClick={canOverlayClose}
				blockScrollOnMount={isScrollDisabled}
				preserveScrollBarGap={hasScrollGap}
				{...props}
			>
				<ModalOverlay {...OverlayStyles} />
				<ModalContent ref={ref}>
					{title && (
						<ModalHeader {...HeaderStyles} {...titleStyles}>
							{title}
						</ModalHeader>
					)}
					{isCloseDisabled ? null : <ModalCloseButton />}

					<ModalBody>{children}</ModalBody>

					{buttons?.length && (
						<Flex.V align={alignButtons}>
							<ModalFooter>
								<Button.Group {...ButtonGroupStyles}>{actionButtons}</Button.Group>
							</ModalFooter>
						</Flex.V>
					)}
				</ModalContent>
			</ChakraModal>
		);
	},
);

const OverlayStyles = {
	background: 'none',
	backdropFilter: 'auto',
	backdropInvert: '20%',
	backdropBlur: '2px',
};

const HeaderStyles = {
	fontSize: 'lg',
	fontWeight: 'bold',
};

const ButtonGroupStyles = {
	spacing: '0.75rem',
};

Modal.displayName = 'Modal';
Modal.defaultProps = ModalDefaultProps;

export { Modal };
