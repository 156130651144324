import { HTMLAttributeReferrerPolicy } from 'react';
import { AvatarProps as ChakraAvatarProps, AvatarBadgeProps } from '@chakra-ui/react';

export interface AvatarProps extends Omit<ChakraAvatarProps, 'ignoreFallback' | 'showBorder'> {
	children?: any;
	size?: '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'full';
	src?: string;
	srcSet?: string;
	name?: string;
	icon?: any;
	iconLabel?: string;
	badge?: AvatarBadgeProps;
	/** If `true`, opt out of the fallback logic and render the `img` at all times */
	isFallbackDisabled?: boolean;
	/** If `true`, the `Avatar` will have a border. It is best used to show status or for a
	 * group of avatars */
	hasBorder?: boolean;
	loading?: 'eager' | 'lazy';
	referrerPolicy?: HTMLAttributeReferrerPolicy;
	getInitials?: (name: string) => string;
	onError?: () => void;
}

export const AvatarDefaultProps: AvatarProps = {
	size: 'md',
	src: '',
	srcSet: '',
	name: '',
	iconLabel: '',
	isFallbackDisabled: false,
	hasBorder: false,
	loading: 'eager',
	referrerPolicy: 'no-referrer-when-downgrade',
};
