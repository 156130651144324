import { forwardRef } from 'react';

import {
	NumberInput as ChakraNumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
} from '@chakra-ui/react';
import { FormControl, FormControlProps, popFormControlProps } from '@elemeno/ui';

import { NumberInputProps, NumberInputDefaultProps } from './NumberInput.types';

const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(({ canScroll, formatAria, ...props }, ref) => {
	const { poppedProps, otherProps } = popFormControlProps(props);

	return (
		<FormControl {...(poppedProps as FormControlProps)}>
			<ChakraNumberInput ref={ref} allowMouseWheel={canScroll} getAriaValueText={formatAria} {...otherProps}>
				<NumberInputField />
				<NumberInputStepper>
					<NumberIncrementStepper />
					<NumberDecrementStepper />
				</NumberInputStepper>
			</ChakraNumberInput>
		</FormControl>
	);
});

NumberInput.displayName = 'NumberInput';
NumberInput.defaultProps = NumberInputDefaultProps;

export { NumberInput };
