import { useEffect, useMemo } from 'react';
import { flexRender, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';

import { Table as ChakraTable, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';

import { Box } from '../../Layout/Box';
import { Button } from '../../Form/Button';
import { TableDefaultProps, TableProps } from './Table.types';

const Table: React.FC<TableProps> = ({
	data,
	size,
	variant,
	columns,
	tableStyles,
	pageSize,
	page,
	onPageChange,
	...props
}) => {
	const pageToIndex = page && !isNaN(page) && page > 0 ? page - 1 : 0;

	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		initialState: {
			pagination: { pageIndex: pageToIndex },
		},
	});

	useEffect(() => {
		pageSize && table.setPageSize(pageSize);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageSize]);

	useEffect(() => {
		table.setPageIndex(pageToIndex);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageToIndex]);

	const tableState = table.getState();
	const totalPages = table.getPageCount();

	const paginationIndices = useMemo(() => {
		const indices: number[] = [];
		if (totalPages < 10) {
			for (let i = 0; i < totalPages; ++i) {
				indices.push(i);
			}
		} else {
			// Always show the first and last page.
			// Show up to 5 pages centered on the current page if not at the end.
			// Show the first or last up-to-5 pages if we're close enough to one end to overlap with it.
			const halfSpan = 2;
			const fullSpan = 2 * halfSpan;
			const lastPage = totalPages - 1;
			const nearStart = Math.max(Math.min(pageToIndex - halfSpan, lastPage - fullSpan), 0);
			const nearEnd = Math.min(nearStart + fullSpan, lastPage);
			if (nearStart > 0) {
				indices.push(0);
			}
			for (let i = nearStart; i <= nearEnd; ++i) {
				indices.push(i);
			}
			if (nearEnd < lastPage) {
				indices.push(lastPage);
			}
		}
		return indices;
	}, [totalPages, pageToIndex]);

	let lastIndex = 0;
	const pagination = paginationIndices.reduce((acc: JSX.Element[], index) => {
		const isActive = index === tableState.pagination.pageIndex;
		// If page numbers aren't consecutive, add an ellipsis.
		const showEllipsis = index > lastIndex + 1;
		lastIndex = index;
		if (showEllipsis) {
			acc.push(
				<Button
					key={`dots_${index}`}
					variant="unstyled"
					fontFamily="body"
					fontWeight="regular"
					size="sm"
					borderRadius="xl"
					backgroundColor="transparent"
					borderStyle="none"
				>
					...
				</Button>,
			);
		}
		acc.push(
			<Button
				key={index}
				variant="unstyled"
				fontFamily="body"
				fontWeight="regular"
				size="sm"
				borderRadius="xl"
				backgroundColor={isActive ? '#ffffff' : 'transparent'}
				borderStyle="solid"
				borderWidth={1}
				borderColor={isActive ? 'gray.400' : 'transparent'}
				onClick={() => {
					if (onPageChange) {
						onPageChange(index + 1);
					} else {
						table.setPageIndex(index);
					}
				}}
			>
				{index + 1}
			</Button>,
		);
		return acc;
	}, []);

	const { theadTrStyles, theadThStyles, theadStyles, tbodyTrStyles, tbodyTdStyles, tbodyStyles } = useMemo(() => {
		const { tr: theadTrStyles, th: theadThStyles, ...theadStyles } = tableStyles?.thead || {};
		const { tr: tbodyTrStyles, td: tbodyTdStyles, ...tbodyStyles } = tableStyles?.tbody || {};

		return {
			theadTrStyles,
			theadThStyles,
			theadStyles,
			tbodyTrStyles,
			tbodyTdStyles,
			tbodyStyles,
		};
	}, [tableStyles]);

	return (
		<>
			<Box pb={8} ml={-3} px={3} mr={-3}>
				<ChakraTable
					minW={{ base: 'full', desktop: '800px' }}
					{...props}
					{...{ size, variant }}
					{...tableStyles?.table}
				>
					<Thead {...theadStyles}>
						{table.getHeaderGroups().map((headerGroup) => (
							<Tr key={headerGroup.id} {...theadTrStyles}>
								{headerGroup.headers.map((header) => (
									<Th key={header.id} {...theadThStyles}>
										{header.isPlaceholder
											? null
											: flexRender(header.column.columnDef.header, header.getContext())}
									</Th>
								))}
							</Tr>
						))}
					</Thead>

					<Tbody {...tbodyStyles}>
						{table.getRowModel().rows.map((row) => {
							return (
								<Tr key={row.id} {...tbodyTrStyles}>
									{row.getVisibleCells().map((cell) => (
										<Td key={cell.id} {...tbodyTdStyles} py={2}>
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</Td>
									))}
								</Tr>
							);
						})}
					</Tbody>
				</ChakraTable>
			</Box>

			{totalPages > 1 && (
				<Button.Group display="flex" justifyContent="flex-end" spacing={1} mb={6} textAlign="right">
					{pagination}
				</Button.Group>
			)}
		</>
	);
};

Table.displayName = 'Table';
Table.defaultProps = TableDefaultProps;

export { Table };
