import { forwardRef, Fragment, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { HiChevronDown } from 'react-icons/hi';
import { Menu as ChakraMenu, MenuButton, MenuList, MenuItem, MenuGroup, MenuDivider } from '@chakra-ui/react';
import { Button, Icon } from '@elemeno/ui';

import { MenuProps, MenuDefaultProps } from './Menu.types';

const Menu = forwardRef<HTMLButtonElement, MenuProps>(
	(
		{
			children,
			items,
			groups,
			startSelected,
			isClosedOnBlur,
			isClosedOnSelect,
			isPositionEager,
			startOpened,
			canPositionFlip,
			isWidthMatched,
			isOverflowHidden,
			isIconHidden,
			triggerStyles,
			ariaLabel,
			...props
		},
		ref,
	) => {
		const menuItems = useMemo(
			() =>
				items?.map(({ text, onClick, to, icon, divider }, i) => {
					const linkProps = to ? { to, as: Link } : {};
					return divider ? (
						<MenuDivider key={'divider_' + i} />
					) : text ? (
						<MenuItem
							key={text}
							width="100%"
							minWidth="16rem"
							height="3.75rem"
							padding="0 1.5rem"
							onClick={onClick}
							{...linkProps}
						>
							{icon && <Icon as={icon} boxSize="5" marginRight="0.625rem" />}
							{text}
						</MenuItem>
					) : null;
				}) || null,
			[items],
		);

		const menuGroups = useMemo(
			() =>
				groups?.map(({ title, items }, index) => (
					<Fragment key={title}>
						<MenuGroup title={title}>
							{items?.map(({ text, onClick, to }) => {
								const linkProps = to ? { to, as: Link } : {};
								return (
									<MenuItem key={text} onClick={onClick} {...linkProps}>
										{text}
									</MenuItem>
								);
							})}
						</MenuGroup>
						{index !== groups.length - 1 && <MenuDivider />}
					</Fragment>
				)) || null,
			[groups],
		);

		return (
			<ChakraMenu
				autoSelect={startSelected}
				closeOnBlur={isClosedOnBlur}
				closeOnSelect={isClosedOnSelect}
				computePositionOnMount={isPositionEager}
				defaultIsOpen={startOpened}
				flip={canPositionFlip}
				matchWidth={isWidthMatched}
				preventOverflow={isOverflowHidden}
				{...props}
			>
				{({ isOpen }) => (
					<>
						<MenuButton
							ref={ref}
							as={Button}
							aria-label={ariaLabel}
							colorScheme="gray"
							isActive={isOpen}
							rightIcon={isIconHidden ? undefined : HiChevronDown}
							{...triggerStyles}
						>
							{children}
						</MenuButton>
						<MenuList minWidth="6.25rem" width="fit-content" borderRadius="xl" boxShadow="2xl">
							{menuItems || menuGroups}
						</MenuList>
					</>
				)}
			</ChakraMenu>
		);
	},
);

Menu.displayName = 'Menu';
Menu.defaultProps = MenuDefaultProps;

export { Menu };
