import { ListProps as ChakraListProps } from '@chakra-ui/react';

export interface ListProps extends ChakraListProps {
	/** Icon to display before list elements. This wont be visible with the isOrdered or
	 * isUnordered props. */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	icon?: any;
	iconColor?: string;
	/** List the items numerically */
	isOrdered?: boolean;
	/** List the items with bullets */
	isUnordered?: boolean;
}

export const ListDefaultProps: ListProps = { isOrdered: false, isUnordered: false };
