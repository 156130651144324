import { makeVar } from '@apollo/client';

import { SimpleUserProps } from 'types/common';

export interface UserListModalInfoProps {
	open: boolean;
	size: 'small';
	dimmer: undefined;
	users: SimpleUserProps[];
	title: string;
}

export const UserListModalInfo = makeVar<UserListModalInfoProps>({
	open: false,
	size: 'small',
	dimmer: undefined,
	users: [],
	title: '',
});
