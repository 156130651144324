import { forwardRef } from 'react';
import { Icon as ChakraIcon, IconButton, IconButtonProps } from '@chakra-ui/react';

import { IconProps, IconDefaultProps } from './Icon.types';

const Icon = forwardRef<SVGSVGElement, IconProps>(({ canFocus, viewBox, ...props }, ref) => {
	return (
		<ChakraIcon ref={ref} alignSelf="center" focusable={canFocus} viewBox="0 0 200 200" {...props}>
			{props.children}
		</ChakraIcon>
	);
});

Icon.displayName = 'Icon';
Icon.defaultProps = IconDefaultProps;

const IconNotation = Object.assign(Icon, { Button: IconButton });
export { IconNotation as Icon };
export type { IconButtonProps };
