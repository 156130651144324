import { MenuProps as ChakraMenuProps, MenuGroupProps as ChakraMenuGroupProps, ButtonProps } from '@chakra-ui/react';

export type MenuItemProps = {
	text?: string;
	onClick?: () => void;
	divider?: boolean;
	to?: string;
	icon?: any;
};

interface MenuGroupProps extends ChakraMenuGroupProps {
	title?: string;
	items: MenuItemProps[];
}

export interface MenuProps
	extends Omit<
		ChakraMenuProps,
		| 'flip'
		| 'autoSelect'
		| 'closeOnBlur'
		| 'closeOnSelect'
		| 'computePositionOnMount'
		| 'defaultIsOpen'
		| 'matchWidth'
		| 'preventOverflow'
		| 'children'
	> {
	children?: any;
	items?: MenuItemProps[];
	groups?: MenuGroupProps[];
	boundary?: HTMLElement | 'clippingParents' | 'scrollParent';
	placement?: ChakraMenuProps['placement'];
	strategy?: 'fixed' | 'absolute';
	offset?: [number, number];
	arrowPadding?: number;
	gutter?: number;
	/** If `true`, the first enabled menu item will receive focus and be selected when the
	 * menu opens */
	startSelected?: boolean;
	/** If `true`, the `Menu` will be opened in controlled mode */
	isOpen?: boolean;
	/** If `true`, the `Menu` will match the width of the reference at all times */
	isWidthMatched?: boolean;
	isLazy?: boolean;
	lazyBehavior?: ChakraMenuProps['lazyBehavior'];
	/** If `true`, the `Menu` will close when you click outside of the menu list */
	isClosedOnBlur?: boolean;
	/** If `true`, the `Menu` will close when a menu item is selected */
	isClosedOnSelect?: boolean;
	/** If `true`, the `Menu` will be positioned when it mounts (even if it's not open)
	 * Note 🚨: We don't recommend using this in a popover/menu intensive UI or page as it
	 * might affect scrolling performance. */
	isPositionEager?: boolean;
	/** If `true`, will prevent the popper from being cut off and ensure it's visible within
	 * the boundary area */
	isOverflowHidden?: boolean;
	/** If `true`, the `Menu` will be initially opened */
	startOpened?: boolean;
	/** If `true`, the popper will change its placement and flip when it's about to overflow
	 * it's boundary area */
	canPositionFlip?: boolean;
	isIconHidden?: boolean;
	triggerStyles?: ButtonProps;
	ariaLabel?: string;
	onOpen?: () => void;
	onClose?: () => void;
}

export const MenuDefaultProps: MenuProps = {
	children: '',
	boundary: 'clippingParents',
	placement: 'bottom',
	strategy: 'absolute',
	arrowPadding: 8,
	gutter: 8,
	startSelected: true,
	isWidthMatched: false,
	isLazy: false,
	lazyBehavior: 'unmount',
	isClosedOnBlur: true,
	isClosedOnSelect: true,
	isPositionEager: false,
	isOverflowHidden: true,
	startOpened: false,
	canPositionFlip: true,
};
