import { forwardRef } from 'react';

import { HiOutlineSpeakerphone } from 'react-icons/hi';
import { Alert as ChakraAlert, AlertTitle, AlertDescription, CloseButton } from '@chakra-ui/react';
import { Box, Flex, Icon } from '@elemeno/ui';

import { AlertProps, AlertDefaultProps, Status } from './Alert.types';

const borderColor = '#ccc';

const getColorByStatus = (status?: Status): AlertProps['backgroundColor'] => {
	switch (status) {
		case 'info':
			return 'elemenoMediumBlue.500';
		case 'success':
			return 'elemenoGreen.500';
		case 'warning':
			return 'elemenoYellow.500';
		case 'error':
			return 'elemenoRed.500';
		default:
			return 'elemenoBlueGray.500';
	}
};

const Alert = forwardRef<HTMLDivElement, AlertProps>(({ children, title, onClose, icon, ...props }, ref) => {
	return (
		<ChakraAlert
			ref={ref}
			{...props}
			rounded="xl"
			backgroundColor="white"
			padding="0"
			border={`1px solid ${borderColor}`}
		>
			<Flex.V>
				<Box h="2" w="full" backgroundColor={getColorByStatus(props?.status)} />
				<AlertTitle borderBottom={`1px solid ${borderColor}`} m={0} py={3} pl={7} pr={1}>
					<Flex.H alignItems="center">
						<Icon as={icon || HiOutlineSpeakerphone} boxSize="6" mr={4} />
						{title || ''}
						{onClose && <CloseButton onClick={onClose} ml="auto" />}
					</Flex.H>
				</AlertTitle>
				{children && (
					<AlertDescription pt={3} pb={5} px={7}>
						{children}
					</AlertDescription>
				)}
			</Flex.V>
		</ChakraAlert>
	);
});

Alert.displayName = 'Alert';
Alert.defaultProps = AlertDefaultProps;

export { Alert };
