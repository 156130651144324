import { SkeletonProps as ChakraSkeletonProps } from '@chakra-ui/react';

export interface SkeletonProps extends ChakraSkeletonProps {
	isLoaded?: boolean;
	fadeDuration?: number;
	speed?: number;
	startColor?: string;
	endColor?: string;
}

export const SkeletonDefaultProps: SkeletonProps = {
	isLoaded: false,
	fadeDuration: 0.4,
	speed: 0.8,
};
