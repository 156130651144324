import { forwardRef } from 'react';
import { Flex as ChakraFlex, Spacer } from '@chakra-ui/react';

import { FlexProps, FlexDefaultProps } from './Flex.types';
import { VFlex } from './VFlex';
import { HFlex } from './HFlex';

const Flex = forwardRef<HTMLDivElement, FlexProps>(({ ...props }, ref) => {
	return <ChakraFlex ref={ref} width="full" {...props} />;
});

Flex.displayName = 'Flex';
Flex.defaultProps = FlexDefaultProps;

const FlexNotation = Object.assign(Flex, { Spacer, V: VFlex, H: HFlex });
export { FlexNotation as Flex };
