import { forwardRef } from 'react';
import { Box, BoxProps } from '@elemeno/ui';

import { ScrollProps, ScrollDefaultProps } from './Scroll.types';

// TODO: Add hasScrollbar as a prop
// TODO: Add isHorizontal as a prop
const Scroll = forwardRef<HTMLDivElement, ScrollProps>(({ ...props }, ref) => {
	return <Box ref={ref} {...FlexStyles} {...props} />;
});

const FlexStyles: BoxProps = {
	overflowY: 'auto',
	width: 'full',
	css: {
		'::-webkit-scrollbar': {
			display: 'none',
		},
	},
};

Scroll.displayName = 'Scroll';
Scroll.defaultProps = ScrollDefaultProps;

export { Scroll };
