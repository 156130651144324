import { forwardRef } from 'react';
import { Divider as ChakraDivider } from '@chakra-ui/react';
import { Flex, Text, SystemProps } from '@elemeno/ui';

import { DividerProps, DividerDefaultProps } from './Divider.types';

const Divider = forwardRef<HTMLDivElement, DividerProps>(
	({ children, variant, color, textColor, isVertical, width = '1px', ...props }, ref) => {
		const divider = (
			<ChakraDivider
				variant={variant}
				borderColor={color}
				orientation={isVertical ? 'vertical' : 'horizontal'}
				borderLeftWidth={isVertical ? width : '0px'}
				borderBottomWidth={!isVertical ? width : '0px'}
				{...DividerStyles(Boolean(children), isVertical)}
				{...props}
			/>
		);

		if (isVertical) {
			return divider;
		}

		return (
			<Flex.H ref={ref} {...props}>
				{children ? (
					<>
						{divider}
						<Text width="fit-content" color={textColor} {...TextStyles}>
							{children}
						</Text>
						{divider}
					</>
				) : (
					divider
				)}
			</Flex.H>
		);
	},
);

const DividerStyles = (hasChildren: boolean, isVertical?: boolean): SystemProps => {
	const dividerHeight = isVertical ? 'full' : '1px';
	const test_variable = 8;

	return {
		marginTop: hasChildren ? '10px' : '0px',
		height: dividerHeight,
		maxHeight: dividerHeight,
	};
};

const TextStyles: SystemProps = {
	textTransform: 'uppercase',
	padding: '0 8px',
	fontWeight: 600,
	fontSize: 'sm',
};

Divider.displayName = 'Divider';
Divider.defaultProps = DividerDefaultProps;

export { Divider };
