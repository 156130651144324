import { TabsProps as ChakraTabsProps } from '@chakra-ui/react';
import { ColorScheme } from '@elemeno/ui';

export interface TabsProps extends Omit<ChakraTabsProps, 'children' | 'onChange'> {
	children?: JSX.Element[];
	tabs: string[];
	variant?: 'line' | 'enclosed' | 'enclosed-colored' | 'soft-rounded' | 'solid-rounded' | 'unstyled';
	size?: 'sm' | 'md' | 'lg';
	align?: 'start' | 'end' | 'center';
	colorScheme?: ColorScheme;
	selectedColor?: string | undefined;
	isFitted?: boolean;
	isLazy?: boolean;
	isManual?: boolean;
	lazyBehavior?: ChakraTabsProps['lazyBehavior'];
	orientation?: 'vertical' | 'horizontal';
	onChange?: (tab: string, tabIndex: number) => void;
	designScheme?: 'manage' | 'default';
}

export const TabsDefaultProps: TabsProps = {
	tabs: [],
	variant: 'line',
	size: 'md',
	align: 'start',
	colorScheme: 'elemenoMediumBlue',
	selectedColor: undefined,
	orientation: 'horizontal',
	lazyBehavior: 'unmount',
	isFitted: false,
	isLazy: false,
	isManual: false,
};
