import { SVGProps } from 'react';
import { IconProps as ChakraIconProps } from '@chakra-ui/react';

export interface IconProps extends Omit<ChakraIconProps, 'focusable'> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	as?: any;
	viewBox?: string;
	color?: string;
	iconColor?: string;
	/** If `false`,	denotes that it is not an interactive element, and only used for
	 * presentation. */
	canFocus?: boolean;
}

export interface SVGIconProps extends SVGProps<SVGSVGElement>, Pick<IconProps, 'iconColor'> {}

export const IconDefaultProps: IconProps = {
	viewBox: '0 0 24 24',
	boxSize: '1rem',
	color: 'gray.700',
	canFocus: false,
};
