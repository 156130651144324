export function capitalizeIfLowerCase(input?: string): string {
	if (!input) {
		return '';
	}
	if (input.toLowerCase() === input) {
		// Capitalize the first letter of each word as long as it's not also the last letter before a space [so Ben's rather than Ben'S].

		return input.replace(/(\b[a-z](?!\s))/g, (c) => c.toUpperCase());
	}
	return input;
}
