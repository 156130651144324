/**
 * Analytics Events : definitions of events that can be logged
 */

import { HOURS_TO_MS } from 'helpers/dates';

export type AnalyticsProps = { [name: string]: number | string | boolean | undefined };

export interface AnalyticsEvent {
	code: string;
	props?: AnalyticsProps;
	intercomId?: string; // specify to log to intercom (initially will use event code, eventually will be converted to use custom intercom id)
}

const _cache: Record<string, AnalyticsEvent> = {};
const _cacheLastUsed: Record<string, number> = {};

// stabilize the AnalyticsEvent objects to avoid triggering effects.
function memoize(event: AnalyticsEvent): AnalyticsEvent {
	const key = `${event.code}-${JSON.stringify(event.props)}`;
	_cacheLastUsed[key] = Date.now();
	if (_cache[key]) return _cache[key];
	_cache[key] = event;
	return event;
}

// simple garbage collection to keep cache from growing too large
setInterval(() => {
	const now = Date.now();
	Object.entries(_cacheLastUsed).forEach(([key, lastUsed]) => {
		if (now - lastUsed > HOURS_TO_MS) {
			delete _cache[key];
			delete _cacheLastUsed[key];
		}
	});
}, HOURS_TO_MS);

type AnnouncementProps = { announcementId: string; type: string; title?: string };

// prettier-ignore
const AnalyticsEvents = {
	launch : ( props: { site : string, invalidSite?: boolean, siteId?: string, error?: string, windowWidth: number, windowHeight: number, referer: string } ): AnalyticsEvent => memoize({ code: 'launch', props }),

	alertBadSite : ( props: { site   : string                                                             } ): AnalyticsEvent => memoize({ code: 'alertBadSite'     , props }),
	campaign     : ( props: { siteId : string, campaign_id: string, redirect_to: string, emailId?: string } ): AnalyticsEvent => memoize({ code: 'Campaign Tracker' , props }),

	// main navigation
	logoClick           : ( props: { source  ?: string } ): AnalyticsEvent => memoize({ code: 'logoClick'        , props }),
	homeClick           : ( props: { source  ?: string } ): AnalyticsEvent => memoize({ code: 'homeClick'        , props }),
	newslettersClick    : ( props: { source  ?: string } ): AnalyticsEvent => memoize({ code: 'newslettersClick' , props }),
	bookmarksClick      : ( props: { source  ?: string } ): AnalyticsEvent => memoize({ code: 'bookmarksClick'   , props }),
	assignmentsClick    : ( props: { source  ?: string } ): AnalyticsEvent => memoize({ code: 'assignmentsClick' , props }),
	shoutOutsClick      : ( props: { source  ?: string } ): AnalyticsEvent => memoize({ code: 'shoutOutsClick'   , props }),
	challengesClick     : ( props: { source  ?: string } ): AnalyticsEvent => memoize({ code: 'challengesClick'  , props }),
	auditFormsClick     : ( props: { source  ?: string } ): AnalyticsEvent => memoize({ code: 'auditFormsClick'  , props }),
	helpClick           : ( props: { source  ?: string } ): AnalyticsEvent => memoize({ code: 'helpClick'        , props }),
	manageHelpClick     : ( props: { source  ?: string } ): AnalyticsEvent => memoize({ code: 'manageHelpClick'  , props }),
	passwordUpdateClick : ( props: { username : string } ): AnalyticsEvent => memoize({ code: 'passwordUpdate'   , props }),

	// user navigation
	profileClick    : ( props: { source ?: string } ): AnalyticsEvent => memoize({ code: 'profileClick'    , props }),
	switchSiteClick : ( props: { source ?: string } ): AnalyticsEvent => memoize({ code: 'switchSiteClick' , props }),
	logoutClick     : ( props: { source ?: string } ): AnalyticsEvent => memoize({ code: 'logoutClick'     , props }),

	// manage navigation
	manageOverviewClick               : ( props: { source ?: string } ): AnalyticsEvent => memoize({ code: 'manageOverviewClick'               , props }),
	manageAnnouncementsClick          : ( props: { source ?: string } ): AnalyticsEvent => memoize({ code: 'manageAnnouncementsClick'          , props }),
	manageAssignmentsClick            : ( props: { source ?: string } ): AnalyticsEvent => memoize({ code: 'manageAssignmentsClick'            , props }),
	manageSuggestedResourcesClick     : ( props: { source ?: string } ): AnalyticsEvent => memoize({ code: 'manageSuggestedResourcesClick'     , props }),
	manageFeedbacksClick              : ( props: { source ?: string } ): AnalyticsEvent => memoize({ code: 'manageFeedbacksClick'              , props }),
	manageUsersClick                  : ( props: { source ?: string } ): AnalyticsEvent => memoize({ code: 'manageUsersClick'                  , props }),
	manageReportingClick              : ( props: { source ?: string } ): AnalyticsEvent => memoize({ code: 'manageReportingClick'              , props }),
	manageReportingSummaryClick       : ( props: { source ?: string } ): AnalyticsEvent => memoize({ code: 'manageReportingSummaryClick'       , props }),
	manageReportingResourcesClick     : ( props: { source ?: string } ): AnalyticsEvent => memoize({ code: 'manageReportingResourcesClick'     , props }),
	manageReportingAnnouncementsClick : ( props: { source ?: string } ): AnalyticsEvent => memoize({ code: 'manageReportingAnnouncementsClick' , props }),
	manageReportingAuditsClick        : ( props: { source ?: string } ): AnalyticsEvent => memoize({ code: 'manageReportingAuditsClick'        , props }),
	manageReportingFormsClick         : ( props: { source ?: string } ): AnalyticsEvent => memoize({ code: 'manageReportingFormsClick'         , props }),
	managePrintQRCodesClick           : ( props: { source ?: string } ): AnalyticsEvent => memoize({ code: 'managePrintQRCodesClick'           , props }),
	manageNewslettersClick            : ( props: { source ?: string } ): AnalyticsEvent => memoize({ code: 'manageNewslettersClick'            , props }),
	manageReportingUsersClick         : ( props: { source ?: string } ): AnalyticsEvent => memoize({ code: 'manageReportingUsersClick'         , props }),
	manageResourceNetworkClick        : ( props: { source ?: string } ): AnalyticsEvent => memoize({ code: 'manageResourceNetworkClick'        , props }),
	manageContentRequestClick         : ( props: { source ?: string } ): AnalyticsEvent => memoize({ code: 'manageContentRequestClick'         , props }),
	manageResourceReviewClick         : ( props: { source ?: string } ): AnalyticsEvent => memoize({ code: 'manageResourceReviewClick'         , props }),

	// sidebar navigation
	currentChallengeClick   : ( props: { source ?: string } ): AnalyticsEvent => memoize({ code: 'currentChallengeClick'   , props }),
	currentAssignmentsClick : ( props: { source ?: string } ): AnalyticsEvent => memoize({ code: 'currentAssignmentsClick' , props }),
	newsletterClick         : ( props: { source ?: string } ): AnalyticsEvent => memoize({ code: 'newsletterClick'         , props }),
	unitEssentialsClick     : ( props: { source ?: string } ): AnalyticsEvent => memoize({ code: 'unitEssentialsClick'     , props }),

	// home cards
	suggestedResourcesClick           : ( props: { id : string; position ?: string } ): AnalyticsEvent => memoize({ code: 'suggestedResourcesClick'           , props }),
	newAndUpdatedResourceClick        : ( props: { id : string; position ?: string } ): AnalyticsEvent => memoize({ code: 'newAndUpdatedResourceClick'        , props }),
	newAndUpdatedResourceViewAllClick : (                                            ): AnalyticsEvent => memoize({ code: 'newAndUpdatedResourceViewAllClick'         }),
	shoutOutsViewAllClick             : (                                            ): AnalyticsEvent => memoize({ code: 'shoutOutsViewAllClick'                     }),
	safetyReportViewAllClick          : (                                            ): AnalyticsEvent => memoize({ code: 'safetyReportViewAllClick'                  }),

	homeView             : (                                                       ): AnalyticsEvent => memoize({ code: 'homeView'                                                       }),
	assignmentsView      : ( props: { source?: string }                            ): AnalyticsEvent => memoize({ code: 'assignmentsView'      , props, intercomId: 'view-assignments'   }),
	recognitionsView     : ( props: { source?: string }                            ): AnalyticsEvent => memoize({ code: 'recognitionsView'     , props, intercomId: 'view-recognitions'  }),
	pinsView             : ( props: { source?: string }                            ): AnalyticsEvent => memoize({ code: 'pinsView'             , props, intercomId: 'view-pins'          }),
	resourcesView        : ( props: { source?: string }                            ): AnalyticsEvent => memoize({ code: 'resourcesView'        , props, intercomId: 'view-resource-list' }),
	challengeListView    : ( props: { source?: string, activeContest: boolean }    ): AnalyticsEvent => memoize({ code: 'challengeListView'    , props                                   }),
	challengeView        : ( props: { source?: string, id : string }               ): AnalyticsEvent => memoize({ code: 'challengeView'        , props                                   }),
	challengeViewInvalid : ( props: { source?: string, id : string, error:string } ): AnalyticsEvent => memoize({ code: 'challengeViewInvalid' , props                                   }),
	safetyReportView     : ( props: { source?: string, team : string }             ): AnalyticsEvent => memoize({ code: 'safetyReportView'     , props                                   }),
	profileView          : ( props: { source?: string }                            ): AnalyticsEvent => memoize({ code: 'profileView'          , props                                   }),

	resourceView : ( props: { resourceId: string, resourceTitle: string, source?: string, pinned: boolean, type: string, assigned: boolean, status: string, resourceType: string, } ): AnalyticsEvent => memoize({ code: 'resourceView', props : {id: props.resourceId, ...props}, intercomId: 'view-resource' }),

	resourceViewError : ( props: { resourceId: string, resourceTitle: string, source?: string, error : string                                   } ): AnalyticsEvent => memoize({ code: 'resourceViewError' , props : {id: props.resourceId, ...props} }),
	sourceLinkView    : ( props: { resourceId: string, resourceTitle: string, url: string                                                       } ): AnalyticsEvent => memoize({ code: 'sourceLinkView'    , props : {id: props.resourceId, ...props} }),
	externalLinkClick : ( props: { resourceId: string, resourceTitle: string, url: string                                                       } ): AnalyticsEvent => memoize({ code: 'externalLinkClick' , props : {id: props.resourceId, ...props} }),
	togglePin         : ( props: { resourceId: string, resourceTitle: string, pinned: boolean, type: string, assigned: boolean, source?: string } ): AnalyticsEvent => memoize({ code: 'togglePin'         , props : {id: props.resourceId, ...props} }),
	toggleRevisions   : ( props: { resourceId: string, resourceTitle: string, show: string                                                      } ): AnalyticsEvent => memoize({ code: 'toggleRevisions'   , props : {id: props.resourceId, ...props} }),
	scrollBottom      : ( props: { resourceId: string, resourceTitle: string                                                                    } ): AnalyticsEvent => memoize({ code: 'scrollBottom'      , props : {id: props.resourceId, ...props} }),
	playVideo         : ( props: { resourceId: string, resourceTitle: string, url : string, code: string, videoTitle: string                    } ): AnalyticsEvent => memoize({ code: 'videoplayer'       , props : {id: props.resourceId, ...props} }),

	changeTeamsSubmit : ( props: { primary: string } ): AnalyticsEvent => memoize({ code: 'changeTeamsSubmit', props }),

	checklistSubmit      : ( props: {                  pinned: boolean, type: string, assigned: boolean, id: string } ): AnalyticsEvent => memoize({ code: 'answersSubmit' , props }),
	checklistSubmitError : ( props: { error:string,    pinned: boolean, type: string, assigned: boolean, id: string } ): AnalyticsEvent => memoize({ code: 'answersSubmit' , props }),

	feedbackStart       : (                                                    ): AnalyticsEvent => memoize({ code: 'feedbackStart'               }),
	feedbackSubmit      : ( props: { code: string }                            ): AnalyticsEvent => memoize({ code: 'feedbackSubmit'      , props }),
	feedbackSubmitError : ( props: { code: string, error: string }             ): AnalyticsEvent => memoize({ code: 'feedbackSubmitError' , props }),
	contentRequestStart : (                                                    ): AnalyticsEvent => memoize({ code: 'contentRequestStart'         }),
	contentRequest      : ( props: { message: string }                         ): AnalyticsEvent => memoize({ code: 'contentRequest'      , props }),
	contentRequestError : ( props: { message: string, error: string }          ): AnalyticsEvent => memoize({ code: 'contentRequestError' , props }),

	searchPageChange : ( props: { page      : number                                                                 } ): AnalyticsEvent => memoize({ code: 'searchPageChange' , props }),
	searchSubmit     : ( props: { searchTerm: string, source : string                                                } ): AnalyticsEvent => memoize({ code: 'searchSubmit'     , props }),
	searchSelect     : ( props: { searchTerm: string, source : string , id : string                                  } ): AnalyticsEvent => memoize({ code: 'searchSelect'     , props }), // selection of result from search
	searchComplete   : ( props: { searchTerm: string, searchTeams : string, searchTypes: string, resultCount: number } ): AnalyticsEvent => memoize({ code: 'searchComplete'   , props }),
	searchResult     : ( props: { searchTerm: string, resultCount : number                                           } ): AnalyticsEvent => memoize({ code: 'searchResult'     , props }),
	searchFailure    : ( props: { searchTerm: string, error : string                                                 } ): AnalyticsEvent => memoize({ code: 'searchFailure'    , props }),

	recognitionsFilter   : ( props: { name: string }                               ): AnalyticsEvent => memoize({ code: 'recognition tab'   , props }),
	recognitionStart     : (                                                       ): AnalyticsEvent => memoize({ code: 'recognition_write'         }),
	recognitionSent      : ( props: { recipientCount: number, isPrivate: boolean } ): AnalyticsEvent => memoize({ code: 'recognition_sent'  , props }),
	recognitionSentError : ( props: { error: string }                              ): AnalyticsEvent => memoize({ code: 'recognition_error', props  }),

	// ANNOUNCEMENTS
	announcementView                : ( props: AnnouncementProps                                                 ): AnalyticsEvent => memoize({ code: 'announcement_view'                  , props }),
	announcementClick               : ( props: AnnouncementProps                                                 ): AnalyticsEvent => memoize({ code: 'announcement_click'                 , props }),
	announcementViewAllClick        : (                                                                          ): AnalyticsEvent => memoize({ code: 'announcement_view_all_click'                }),
	announcementsListView           : ( props: { source?: string, unitId: string }                               ): AnalyticsEvent => memoize({ code: 'announcements_list_view'            , props }),
	announcementsQuickPostView      : ( props: { source?: string, announcementText: string } & AnnouncementProps ): AnalyticsEvent => memoize({ code: 'announcements_quick_post_view'      , props }),
	announcementsManageListView     : ( props: { source?: string }                                               ): AnalyticsEvent => memoize({ code: 'announcements_manage_list_view'     , props }),
	announcementsManageNewView      : ( props: { source?: string }                                               ): AnalyticsEvent => memoize({ code: 'announcements_manage_new_view'      , props }),
	announcementsManageEditView     : ( props: { source?: string } & AnnouncementProps                           ): AnalyticsEvent => memoize({ code: 'announcements_manage_edit_view'     , props }),
	announcementsManageNew          : ( props: AnnouncementProps                                                 ): AnalyticsEvent => memoize({ code: 'announcements_manage_new'           , props }),
	announcementsManageNewError     : ( props: { error: string }                                                 ): AnalyticsEvent => memoize({ code: 'announcements_manage_new_error'     , props }),
	announcementsManageEdit         : ( props: AnnouncementProps                                                 ): AnalyticsEvent => memoize({ code: 'announcements_manage_edit'          , props }),
	announcementsManageEditError    : ( props: { error: string } & AnnouncementProps                             ): AnalyticsEvent => memoize({ code: 'announcements_manage_edit_error'    , props }),
	announcementsManageRemove       : ( props: AnnouncementProps                                                 ): AnalyticsEvent => memoize({ code: 'announcements_manage_remove'        , props }),
	announcementsManageRemoveError  : ( props: { error: string } & AnnouncementProps                             ): AnalyticsEvent => memoize({ code: 'announcements_manage_remove_error'  , props }),
	announcementsManageRestore      : ( props: AnnouncementProps                                                 ): AnalyticsEvent => memoize({ code: 'announcements_manage_restore'       , props }),
	announcementsManageRestoreError : ( props: { error: string } & AnnouncementProps                             ): AnalyticsEvent => memoize({ code: 'announcements_manage_restore_error' , props }),
	announcementsManageDelete       : ( props: AnnouncementProps                                                 ): AnalyticsEvent => memoize({ code: 'announcements_manage_delete'        , props }),
	announcementsManageDeleteError  : ( props: { error: string } & AnnouncementProps                             ): AnalyticsEvent => memoize({ code: 'announcements_manage_delete_error'  , props }),

	contentRequestAddStart : ( props: { announcementId?: string                           } ): AnalyticsEvent => memoize({ code: 'manage_content_request_add_start' , props }),
	contentRequestAdd      : ( props: { announcementId?: string, contentRequestId: string } ): AnalyticsEvent => memoize({ code: 'manage_content_request_add'       , props }),
	contentRequestAddError : ( props: { announcementId?: string, error : string           } ): AnalyticsEvent => memoize({ code: 'manage_content_request_add_error' , props }),

	// NEWSLETTERS
	newsletterView                : ( props: { id: string                  } ): AnalyticsEvent => memoize({ code: 'newsletter_view'                  , props }),
	newslettersManageListView     : ( props: { source?: string             } ): AnalyticsEvent => memoize({ code: 'newsletters_manage_list_view'     , props }),
	newslettersManageNewView      : ( props: { source?: string             } ): AnalyticsEvent => memoize({ code: 'newsletters_manage_new_view'      , props }),
	newslettersManageEditView     : ( props: { id: string; source?: string } ): AnalyticsEvent => memoize({ code: 'newsletters_manage_edit_view'     , props }),
	newslettersManageNew          : ( props: { id: string                  } ): AnalyticsEvent => memoize({ code: 'newsletters_manage_new'           , props }),
	newslettersManageNewError     : ( props: { error: string               } ): AnalyticsEvent => memoize({ code: 'newsletters_manage_new_error'     , props }),
	newslettersManageEdit         : ( props: { id: string                  } ): AnalyticsEvent => memoize({ code: 'newsletters_manage_edit'          , props }),
	newslettersManageEditError    : ( props: { id: string; error: string   } ): AnalyticsEvent => memoize({ code: 'newsletters_manage_edit_error'    , props }),
	newslettersManageDelete       : ( props: { id: string                  } ): AnalyticsEvent => memoize({ code: 'newsletters_manage_delete'        , props }),
	newslettersManageDeleteError  : ( props: { id: string; error: string   } ): AnalyticsEvent => memoize({ code: 'newsletters_manage_delete_error'  , props }),

	// SUGGESTED RESOURCES
	suggestedResourcesManageListView    : ( props: { source?: string                                          } ): AnalyticsEvent => memoize({ code: 'suggested_resources_manage_list_view'    , props }),
	suggestedResourcesManageUpdate      : ( props: { id: string, action: string, suggestedResourceIds: string } ): AnalyticsEvent => memoize({ code: 'suggested_resources_manage_update'       , props }),
	suggestedResourcesManageUpdateError : ( props: { error: string                                            } ): AnalyticsEvent => memoize({ code: 'suggested_resources_manage_update_error' , props }),
	suggestedResourcesManageMaxReached  : ( props: { id: string, source?: string                              } ): AnalyticsEvent => memoize({ code: 'suggested_resources_manage_max_reached'  , props }),

	// PRINT QR CODES
	printQrCodesView : ( props: { source?: string } ): AnalyticsEvent => memoize({ code: 'print_qr_codes_view' , props }),

	// RESOURCE REVIEW
	resourceReview      : ( props: { id: string                } ): AnalyticsEvent => memoize({ code: 'resource_review'       , props }),
	resourceReviewError : ( props: { id: string; error: string } ): AnalyticsEvent => memoize({ code: 'resource_review_error' , props }),

	// site is backwards compatible, added siteId for id consistency
	siteSelect : ( props: { siteId: string, site: string,              } ): AnalyticsEvent => memoize({ code: 'siteSelect' , props }),
	siteSwitch : ( props: { siteId: string, site: string, from: string } ): AnalyticsEvent => memoize({ code: 'siteSwitch' , props }),

	// MANAGE
	// groupAdd         : ( props: { name: string, id   :string  } ): AnalyticsEvent => memoize({ code: 'groupAdd'          , props }),
	// groupDelete      : ( props: { name: string, id   :string  } ): AnalyticsEvent => memoize({ code: 'groupDelete'       , props }),
	assignmentAdd         : ( props: { id : string, count: number, reassign: boolean                } ): AnalyticsEvent => memoize({ code: 'Assignment Add'    , props }),
	assignmentAddError    : ( props: { id : string, count: number, reassign: boolean, error: string } ): AnalyticsEvent => memoize({ code: 'Assignment Add Error'    , props }),
	assignmentRemove      : ( props: { id : string, count: number                                   } ): AnalyticsEvent => memoize({ code: 'Assignment Remove' , props }),
	assignmentRemoveError : ( props: { id : string, count: number, error: string                    } ): AnalyticsEvent => memoize({ code: 'Assignment Remove Error'    , props }),

	// manage user - add, user - bulk add, user - bulk resend, user - deactivate, user - edit
	//

	// AUTH
	requestAccessView     : (                                            ): AnalyticsEvent => memoize({ code: 'REQUEST_ACCESS page'           }),
	requestAccessSubmit   : ( props: { email: string }                   ): AnalyticsEvent => memoize({ code: 'REQUEST_ACCESS submit' , props }),
	requestAccessError    : ( props: { email: string , error: string }   ): AnalyticsEvent => memoize({ code: 'REQUEST_ACCESS ERR'    , props }),
	registerStep1View     : (                                            ): AnalyticsEvent => memoize({ code: 'REGISTER1 page'                }), // enter email
	registerStep1Submit   : ( props: { email: string }                   ): AnalyticsEvent => memoize({ code: 'REGISTER1 submit'      , props }),
	registerStep1Error    : ( props: { email: string , error: string }   ): AnalyticsEvent => memoize({ code: 'REGISTER1 ERR'         , props }),
	registerStep2View     : ( props: { email: string , }                 ): AnalyticsEvent => memoize({ code: 'REGISTER2 page'        , props }), // enter name?
	registerStep2Error    : ( props: { email: string , error: string }   ): AnalyticsEvent => memoize({ code: 'REGISTER2 ERR'         , props }),
	registerStep3View     : ( props: { email: string }                   ): AnalyticsEvent => memoize({ code: 'REGISTER3 page'        , props }), // click to send email?
	registerStep3Resend   : ( props: { email: string }                   ): AnalyticsEvent => memoize({ code: 'REGISTER3 resend link' , props }),
	newUserNameView       : ( props: { username: string }                ): AnalyticsEvent => memoize({ code: 'NEWUSERNAME page'      , props }), // post-reg username prompt
	newUserNameSubmit     : ( props: { username: string }                ): AnalyticsEvent => memoize({ code: 'NEWUSERNAME submit'    , props }),
	newUserNameError      : ( props: { username: string, error: string } ): AnalyticsEvent => memoize({ code: 'NEWUSERNAME ERR'       , props }),
	newUserPassView       : ( props: { username: string }                ): AnalyticsEvent => memoize({ code: 'NEWUSERPASS page'      , props }),
	newUserPassSubmit     : ( props: { username: string }                ): AnalyticsEvent => memoize({ code: 'NEWUSERPASS submit'    , props }),
	newUserPassError      : ( props: { username: string, error: string } ): AnalyticsEvent => memoize({ code: 'NEWUSERPASS ERR'       , props }),
	register              : ( props: { email: string }                   ): AnalyticsEvent => memoize({ code: 'registration'          , props }),
	registerError         : ( props: { email: string , error: string }   ): AnalyticsEvent => memoize({ code: 'registrationError'     , props }),
	startAuthSession      : (                                            ): AnalyticsEvent => memoize({ code: 'authSessionStart'              }), // starting from an external auth token
	startAuthSessionError : ( props: { error: string }                   ): AnalyticsEvent => memoize({ code: 'authSessionStartError', props  }),

	authMagicLink        : (props: { username?: string, message: string, email?: string                 } ): AnalyticsEvent => memoize({ code: 'auth-llink'       , props }),
	authMagicLinkError   : (props: { username?: string, message: string, error?: string, email?: string } ): AnalyticsEvent => memoize({ code: 'auth-llink-error' , props }),
	authCredentials      : (props: { username?: string, message: string, email?: string                 } ): AnalyticsEvent => memoize({ code: 'auth-cred'        , props }),
	authCredentialsError : (props: { username?: string, message: string, error?: string, email?: string } ): AnalyticsEvent => memoize({ code: 'auth-cred-error'  , props }),
	authSso              : (props: { username?: string, message: string                                 } ): AnalyticsEvent => memoize({ code: 'auth-sso'         , props }),
	authSsoError         : (props: { username?: string, message: string, error?: string                 } ): AnalyticsEvent => memoize({ code: 'auth-sso-error'   , props }),

	loginPage    : ( ): AnalyticsEvent => memoize({ code: 'loginPage'    }),
	logout       : ( ): AnalyticsEvent => memoize({ code: 'logout'       }),
	welcome      : ( ): AnalyticsEvent => memoize({ code: 'welcome'      }), // deprecate due to unclear naming, and replace with...
	startSession : ( ): AnalyticsEvent => memoize({ code: 'sessionStart' }), // all session "starts"

	passwordUpdate       : (props: { username: string                 } ): AnalyticsEvent => memoize({ code: 'passwordUpdate'        , props }),
	passwordUpdateError  : (props: { username: string, error : string } ): AnalyticsEvent => memoize({ code: 'passwordUpdate ERR'    , props }),
	passwordUpdateSubmit : (props: { username: string                 } ): AnalyticsEvent => memoize({ code: 'password_updateSubmit' , props }),

	authMagicLinkSend          : (props: { email: string                                 } ): AnalyticsEvent => memoize({ code: 'AUTH LLINK sent email'              , props }),
	authMagicLinkSendError     : (props: { email: string, message: string, error?:string } ): AnalyticsEvent => memoize({ code: 'AUTH LLINK sent email ERR'          , props }),
	authRequestAccessSend      : (props: { email: string                                 } ): AnalyticsEvent => memoize({ code: 'AUTH REQUEST_ACCESS sent email'     , props }),
	authRequestAccessSendError : (props: { email: string, message: string, error?:string } ): AnalyticsEvent => memoize({ code: 'AUTH REQUEST_ACCESS sent email ERR' , props }),
	unsubscribe                : (props: { email: string                                 } ): AnalyticsEvent => memoize({ code: 'Unsubscribe'                        , props }),

	// Deprecated - maintaining consistency for launch

	cognitoLogin        : (props: { username: string } ): AnalyticsEvent => memoize({ code: 'AUTH COGNITO login'         , props }),
	cognitoLoginSuccess : (props: { username: string } ): AnalyticsEvent => memoize({ code: 'AUTH COGNITO login success' , props }),
	cognitoLoginFail    : (props: { username: string } ): AnalyticsEvent => memoize({ code: 'AUTH COGNITO login fail'    , props }),
	ssoRedirect         : (                            ): AnalyticsEvent => memoize({ code: 'AUTH SSO redirect'                  }),

	testPage     : ( props: { siteId: string, site: string                                     } ): AnalyticsEvent => memoize({ code: 'TEST page'         , props }),
	testFailure  : ( props: { siteId: string, site: string, error: string                      } ): AnalyticsEvent => memoize({ code: 'TEST item failure' , props }),
	testComplete : ( props: { siteId: string, site: string, successful: number, errors: number } ): AnalyticsEvent => memoize({ code: 'TEST complete'     , props }),


	manageSort             : ( props: { page: string, field: string                                 } ): AnalyticsEvent => memoize({ code: 'manage - sort'              , props }),
	manageFilter           : ( props: { page: string, field: string, value: string                  } ): AnalyticsEvent => memoize({ code: 'manage - filter'            , props }),
	manageTab              : ( props: { page: string, tab: string                                   } ): AnalyticsEvent => memoize({ code: 'manage - tab'               , props }),
	manageExport           : ( props: { page: string                                                } ): AnalyticsEvent => memoize({ code: 'manage - export'            , props }),
	manageCreate           : ( props: { page: string, type: string, count: number, context?: string } ): AnalyticsEvent => memoize({ code: 'manage - create'            , props }),
	manageCreateComplete   : ( props: { page: string, type: string, count: number, context?: string } ): AnalyticsEvent => memoize({ code: 'manage - create complete'   , props }),
	manageCreateError      : ( props: { page: string, type: string, count: number, error: string    } ): AnalyticsEvent => memoize({ code: 'manage - create ERROR'      , props }),
	manageRemove           : ( props: { page: string, type: string, count: number, context?: string } ): AnalyticsEvent => memoize({ code: 'manage - remove'            , props }),
	manageRemoveComplete   : ( props: { page: string, type: string, count: number, context?: string } ): AnalyticsEvent => memoize({ code: 'manage - remove complete'   , props }),
	manageRemoveError      : ( props: { page: string, type: string, count: number, error: string    } ): AnalyticsEvent => memoize({ code: 'manage - remove ERROR'      , props }),
	manageRecreate         : ( props: { page: string, type: string, count: number, context?: string } ): AnalyticsEvent => memoize({ code: 'manage - recreate'          , props }),
	manageRecreateComplete : ( props: { page: string, type: string, count: number, context?: string } ): AnalyticsEvent => memoize({ code: 'manage - recreate complete' , props }),
	manageRecreateError    : ( props: { page: string, type: string, count: number, error: string    } ): AnalyticsEvent => memoize({ code: 'manage - recreate ERROR'    , props }),

	// analytics internal
	embargoCleared : ( props: { type: string } ): AnalyticsEvent => memoize({ code: 'Embargo Cleared', props }),

	// keep me logged in
	keepMeLoggedInDisplay   : (): AnalyticsEvent => memoize({ code: 'Keep Me Logged In: Display'  }),
	keepMeLoggedInAttempt : (): AnalyticsEvent => memoize({ code: 'Keep Me Logged In: Attempt' }),
	keepMeLoggedInSuccess   : (): AnalyticsEvent => memoize({ code: 'Keep Me Logged In: Success'  }),

	// Server Side
	// assignmentComplete = 'Assignment Complete',
	// recognitionSend = 'recognitionSend',
	// recognitionsLiked = 'recognitionsLiked',
	// recognitionsUnliked = 'recognitionsUnliked',
}

export { AnalyticsEvents };
