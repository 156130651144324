import { forwardRef, useCallback, useRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';

import { HiOutlineCalendar, HiX } from 'react-icons/hi';
import { Box, Icon, Input, InputLeftElement, InputRightElement, isMobileDevice, SystemProps } from '@elemeno/ui';

import { DatePickerProps, DatePickerDefaultProps } from './DatePicker.types';

const DatePicker = <CustomModifierNames extends string = never, WithRange extends boolean | undefined = undefined>(
	{
		size,
		variant,
		label,
		subLabel,
		placeholder,
		hint,
		caption,
		error,
		isInvalid,
		isRequired,
		isDisabled,
		isReadOnly,
		isClearable,
		...props
	}: DatePickerProps<CustomModifierNames, WithRange>,
	ref: React.ForwardedRef<ReactDatePicker<CustomModifierNames, WithRange>>,
): JSX.Element => {
	const { onChange, selected, ...remainingProps } = props;
	// const { poppedProps, otherProps } = usePopProps(props, ['onChange', 'selected']);
	const date = selected;

	// const [date, setDate] = useState<Date | null | undefined>(poppedProps.selected);

	const mobileRef = useRef<HTMLInputElement & { showPicker: () => void }>(null);

	const setDate = useCallback(
		(_date: any) => {
			onChange?.(_date as any, undefined);
		},
		[onChange],
	);

	return (
		<>
			{!isMobileDevice() ? (
				<ReactDatePicker
					ref={ref}
					required={isRequired}
					disabled={isDisabled}
					readOnly={isReadOnly}
					placeholderText={placeholder}
					minTime={new Date(dayjs().startOf('day').valueOf())}
					maxTime={new Date(dayjs().endOf('day').valueOf())}
					disabledKeyboardNavigation
					popperContainer={({ children }) => <Box zIndex={2}>{children}</Box>}
					popperPlacement="bottom"
					selected={date}
					onChange={(date) => setDate(date as Date | null | undefined)}
					popperModifiers={[
						{
							name: 'offset',
							options: {
								offset: [0, hint || error ? -28 : 0],
							},
						},
					]}
					customInput={
						<Input
							{...{
								size,
								variant,
								label,
								subLabel,
								hint,
								caption,
								error,
								isInvalid,
								isRequired,
								isDisabled,
								isReadOnly,
							}}
							contentLeft={
								<InputLeftElement>
									<Icon as={HiOutlineCalendar} opacity={isDisabled ? 0.4 : 1} />
								</InputLeftElement>
							}
							contentRight={
								(props.selected || props.startDate || props.endDate) &&
								isClearable && (
									<InputRightElement>
										<Icon.Button
											size="sm"
											icon={<HiX />}
											aria-label="Clear"
											onClick={(event) => {
												event.preventDefault();
												if (props.onChange) {
													props.onChange(
														(props.selectsRange ? [null, null] : null) as WithRange extends
															| false
															| undefined
															? Date | null
															: [Date | null, Date | null],
														event,
													);
												}
											}}
										/>
									</InputRightElement>
								)
							}
						/>
					}
					{...remainingProps}
				/>
			) : (
				<Box
					sx={{
						input: { ...DatePickerMobileStyles, '-webkit-appearance': 'none' },
					}}
				>
					<input
						ref={mobileRef}
						type="date"
						value={date ? date.toISOString().split('T')[0] : undefined}
						onClick={() => mobileRef.current?.showPicker()}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setDate(new Date(event.target.value.replace(/-/g, '/')));
						}}
					/>
				</Box>
			)}
		</>
	);
};

const DatePickerMobileStyles: SystemProps = {
	width: '100%',
	height: '2.5rem',
	lineHeight: '2.25rem',
	border: '1px solid',
	borderColor: 'gray.300',
	borderRadius: 'md',
	padding: '0 1rem',
};

// forwardRef don't support generic type
const DatePickerWithRef = forwardRef(DatePicker as any) as <
	CustomModifierNames extends string = never,
	WithRange extends boolean | undefined = undefined,
>(
	props: DatePickerProps<CustomModifierNames, WithRange> & {
		ref?: React.ForwardedRef<ReactDatePicker<CustomModifierNames, WithRange>>;
	},
) => ReturnType<typeof DatePicker>;

// @ts-ignore
DatePickerWithRef.displayName = 'DatePicker';
// @ts-ignore
DatePickerWithRef.defaultProps = DatePickerDefaultProps;

export { DatePickerWithRef as DatePicker };
