import { forwardRef } from 'react';
import { Tooltip as ChakraTooltip } from '@chakra-ui/react';
import { SystemProps } from '@elemeno/ui';

import { TooltipProps, TooltipDefaultProps } from './Tooltip.types';

const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(({ ...props }, ref) => {
	return <ChakraTooltip ref={ref} {...props} />;
});

Tooltip.displayName = 'Tooltip';
Tooltip.defaultProps = TooltipDefaultProps;

export { Tooltip };
