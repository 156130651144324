import logger from './logger';

type ShareDataBetweenTabsEvents =
	| {
			type: 'request';
			senderId: string;
	  }
	| {
			type: 'response';
			senderId: string;
			data: any;
	  };

/**
 * Share data between tabs
 */
export class ShareDataBetweenTabs {
	private id: string;
	private bc: BroadcastChannel;
	private getter: () => any;
	private setter: (data: any) => void;

	constructor(key: string, getter: () => any, setter: (data: any) => void) {
		this.id = Math.random().toString(36).slice(2, 9);
		this.bc = new BroadcastChannel(key);
		this.getter = getter;
		this.setter = setter;

		// listen for events
		this.bc.addEventListener('message', this.handleEvent.bind(this));

		// request data from other tabs
		this.request();
	}

	/**
	 * Close broadcast channel
	 */
	public close() {
		try {
			this.bc.close();
		} catch (e) {
			logger.error('error closing broadcast channel', e);
		}
	}

	/**
	 * Handle event
	 */
	private handleEvent(event: MessageEvent<ShareDataBetweenTabsEvents>) {
		switch (event.data.type) {
			// receive an request for data
			case 'request': {
				this.send();
				break;
			}

			// receive data from other tab
			case 'response': {
				// if receive data from other tab
				if (event.data.senderId !== this.id) {
					this.setter(event.data.data);
				}
				break;
			}
		}
	}

	/**
	 * Request data from other tabs
	 */
	public request() {
		if (!this.getter()) {
			try {
				this.bc.postMessage({
					type: 'request',
					senderId: this.id,
				} as ShareDataBetweenTabsEvents);
			} catch (e) {
				logger.error('error closing posting request to broadcast channel', e);
			}
		}
	}

	/**
	 * Send data to other tabs
	 * */
	public send(force?: boolean) {
		const data = this.getter();
		if (!!data || force) {
			try {
				this.bc.postMessage({
					type: 'response',
					senderId: this.id,
					data,
				} as ShareDataBetweenTabsEvents);
			} catch (e) {
				logger.error('error posting response to broadcast channel', e);
			}
		}
	}
}
