import { BoxProps } from '@elemeno/ui';

export interface CardProps extends Omit<BoxProps, 'title'> {
	/** If present, a title and divider will appear up top */
	title?: string | JSX.Element;
	footer?: React.ReactNode;
	showBar?: boolean;
	barColor?: string;
	isResponsive?: boolean;
	noOverflowHidden?: boolean;
	noXPadding?: boolean;
}

export const CardDefaultProps: CardProps = {
	title: undefined,
	barColor: 'gray.500',
	showBar: false,
	isResponsive: false,
};
