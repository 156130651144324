import { ModalProps as ChakraModalProps } from '@chakra-ui/react';
import { ButtonProps, SystemProps } from '@elemeno/ui';

export interface ModalProps
	extends Omit<
		ChakraModalProps,
		| 'allowPinchZoom'
		| 'autoFocus'
		| 'blockScrollOnMount'
		| 'closeOnEsc'
		| 'closeOnOverlayClick'
		| 'preserveScrollBarGap'
		| 'returnFocusOnClose'
	> {
	size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | 'full';
	title?: string;
	titleStyles?: SystemProps;
	buttons?: ButtonProps[];
	isOpen: boolean;
	isCentered?: boolean;
	/** Handle zoom/pinch gestures on mobile devices when scroll locking is enabled. */
	canPinchZoom?: boolean;
	/** If `true`, the modal will close when the `Esc` key is pressed */
	canEscape?: boolean;
	/** If `true`, the modal will close when the overlay is clicked */
	canOverlayClose?: boolean;
	/** If `true`, the modal will autofocus the first enabled and interactive element within the `ModalContent` */
	startFocused?: boolean;
	/** If `true`, the modal will return focus to the element that triggered it when it closes. */
	isFocusReturned?: boolean;
	/** If `true`, scrolling will be disabled on the `body` when the modal opens. */
	isScrollDisabled?: boolean;
	/** If `true`, hide the close button. */
	isCloseDisabled?: boolean;
	/** If `true`, a `padding-right` will be applied to the body element that's equal to the
	 * width of the scrollbar. This can help prevent some unpleasant flickering effect and
	 * content adjustment when the modal opens
	 */
	hasScrollGap?: boolean;
	motionPreset?: ChakraModalProps['motionPreset'];
	initialFocusRef?: ChakraModalProps['initialFocusRef'];
	finalFocusRef?: ChakraModalProps['finalFocusRef'];
	onClose: () => void;
	onCloseComplete?: () => void;
	onEsc?: () => void;
	onOverlayClick?: () => void;
	alignButtons?: string;
}

export const ModalDefaultProps: ModalProps = {
	children: '',
	size: 'md',
	title: '',
	motionPreset: 'scale',
	isOpen: false,
	isCentered: false,
	canPinchZoom: false,
	canEscape: true,
	canOverlayClose: false,
	startFocused: true,
	isFocusReturned: true,
	isScrollDisabled: true,
	isCloseDisabled: false,
	hasScrollGap: false,
	onClose: () => null,
	alignButtons: 'flex-end',
};
