import { forwardRef } from 'react';

import { GroupBase, Select as ReactSelect, SelectInstance, ActionMeta, OnChangeValue } from 'chakra-react-select';
import { Box, FormControl, FormControlProps, SystemProps, popFormControlProps, isMobileDevice } from '@elemeno/ui';

import { SelectProps, SelectDefaultProps } from './Select.types';

// https://github.com/csandman/chakra-react-select
const Select = <
	Option extends { value: string; label?: string; key?: string; description?: string; text?: string },
	IsMulti extends boolean = boolean,
	Group extends GroupBase<Option> = GroupBase<Option>,
>(
	{ menuProps, optimizeForMobile = true, ...props }: SelectProps<Option, IsMulti, Group>,
	ref: React.ForwardedRef<SelectInstance<Option, IsMulti, Group>>,
): JSX.Element => {
	const {
		poppedProps,
		otherProps: { options, getOptionLabel: callerGetOptionLabel, ...otherProps },
	} = popFormControlProps(props);
	const getOptionLabel = callerGetOptionLabel || ((option) => option?.label || option?.text || '');

	const handleSingleMobileSelect = (event: any): void => {
		if (options) {
			otherProps.onChange?.(
				options[event.target.selectedIndex - 1] as OnChangeValue<Option, IsMulti>,
				'select-option' as unknown as ActionMeta<Option>,
			);
		}
	};

	const handleMultiMobileSelect = (event: any) => {
		const selectedList: number[] = [];
		for (let i = 0; i < event.target.options.length; i++) {
			if (event.target.options[i].selected) {
				selectedList.push(i);
			}
		}

		const selectedOptions: any[] = [];
		selectedList.forEach((index) => selectedOptions.push(options?.[index]));
		otherProps?.onChange?.(selectedOptions as any, 'select-option' as unknown as ActionMeta<Option>);
	};

	const getSelected = (value: string): boolean => {
		const initialValue = otherProps?.value as any;

		if (!initialValue) {
			return false;
		}

		if (otherProps?.isMulti) {
			for (let i = 0; i < initialValue.length; i++) {
				if (initialValue[i].value === value) {
					return true;
				}
			}
		}
		return initialValue.value === value;
	};

	return (
		<FormControl {...(poppedProps as FormControlProps)}>
			{!isMobileDevice() || !optimizeForMobile ? (
				<ReactSelect
					ref={ref}
					menuPortalTarget={document.body}
					// don't use tab to select value to work better in forms (enter to select, tab to advance)
					tabSelectsValue={false}
					chakraStyles={{
						control: (provided) => ({
							...provided,
							background: '#FFF',
							opacity: poppedProps.isReadOnly ? '0.9 !important' : undefined,
						}),
						indicatorsContainer: (provided) => ({
							...provided,
							minWidth: '2.5rem',
							maxWidth: '10%',
						}),
						dropdownIndicator: (provided) => ({
							...provided,
							minWidth: '2.5rem',
							opacity: poppedProps.isReadOnly ? '0.1 !important' : undefined,
						}),
						option: (provided, state) => ({
							...provided,
							borderTop: state.data.value === '--all--' ? '1px solid rgb(203,213,334,0.5)' : undefined, // hacking in last-option select all formatting (from FormSelect)
							lineHeight: state.data.value === '--all--' ? '2rem' : undefined, // add some spacing below the border
							marginBottom: state.data.value === '--all--' ? '-10px' : undefined, // remove the bottom padding from the dropdown
						}),
					}}
					styles={{
						menuPortal: (provided) => ({
							...provided,
							zIndex: 1400,
							...(menuProps as any),
						}),
					}}
					{...SelectStyles}
					{...otherProps}
					options={options}
					getOptionLabel={getOptionLabel}
				/>
			) : (
				<Box borderRadius="md" background="#FFF">
					<Box
						sx={{
							select: { ...SelectMobileStyles },
						}}
					>
						<select
							multiple={props.isMulti}
							onChange={props.isMulti ? handleMultiMobileSelect : handleSingleMobileSelect}
						>
							{!props.isMulti && (
								<option key="default" hidden>
									{otherProps.placeholder ? otherProps.placeholder : 'Select an option'}
								</option>
							)}
							{options?.map((optionOrGroup: Option | Group) =>
								'options' in optionOrGroup ? (
									optionOrGroup.options.map((option) => (
										<option key={option.value} selected={getSelected(option.value)}>
											{getOptionLabel(option)}
										</option>
									))
								) : (
									<option key={optionOrGroup.value} selected={getSelected(optionOrGroup.value)}>
										{getOptionLabel(optionOrGroup as Option)}
									</option>
								),
							)}
						</select>
					</Box>
				</Box>
			)}
		</FormControl>
	);
};

const SelectStyles: SystemProps = {
	borderColor: 'gray.300',
};

const SelectMobileStyles: SystemProps = {
	width: '100%',
	height: '2.5rem',
	border: '1px solid',
	borderColor: 'gray.300',
	borderRadius: 'md',
	padding: '0 1rem',
	appearance: 'none',
	backgroundColor: '#fff',
	background:
		'url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 100% 50%',
	backgroundPosition: '97% center',
};

// forwardRef don't support generic type
const SelectWithRef = forwardRef(Select as any) as <
	Option = unknown,
	IsMulti extends boolean = boolean,
	Group extends GroupBase<Option> = GroupBase<Option>,
>(
	props: SelectProps<Option, IsMulti, Group> & {
		ref?: React.ForwardedRef<SelectInstance<Option, IsMulti, Group>>;
	},
) => ReturnType<typeof Select>;

// @ts-ignore
SelectWithRef.displayName = 'Select';
// @ts-ignore
SelectWithRef.defaultProps = SelectDefaultProps;

export { SelectWithRef as Select };
