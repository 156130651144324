import { forwardRef } from 'react';
import { Skeleton as ChakraSkeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react';

import { SkeletonProps, SkeletonDefaultProps } from './Skeleton.types';

const Skeleton = forwardRef<HTMLDivElement, SkeletonProps>(({ ...props }, ref) => {
	return <ChakraSkeleton ref={ref} width="full" {...props} />;
});

Skeleton.displayName = 'Skeleton';
Skeleton.defaultProps = SkeletonDefaultProps;

const SkeletonNotation = Object.assign(Skeleton, {
	Circle: SkeletonCircle,
	Text: SkeletonText,
});
export { SkeletonNotation as Skeleton };
