// import { ChangeEventHandler, FocusEventHandler } from 'react';

import { InputProps } from '@chakra-ui/react';

export interface SearchOption {
	label: string;
	value: string;
}

export interface SearchProps extends Omit<InputProps, 'onSelect' | 'onSubmit'> {
	onSelect?: (value: string, item: SearchOption, term: string, values: string[]) => void;
	onSubmit?: (value: string) => void;
	search?: (query: string) => SearchOption[];
	render?: (item: SearchOption) => React.ReactNode;
}

export const SearchDefaultProps: SearchProps = {
	placeholder: '',
	isDisabled: false,
	isInvalid: false,
	variant: 'outline',
	size: 'md',
};
