import { forwardRef } from 'react';
import { HiExternalLink } from 'react-icons/hi';
import { LinkOverlay as ChakraLinkOverlay, LinkOverlayProps as ChakraLinkOverlayProps } from '@chakra-ui/react';
import { Icon } from '@elemeno/ui';

export interface LinkOverlayProps extends ChakraLinkOverlayProps {}

const LinkOverlay = forwardRef<HTMLAnchorElement, LinkOverlayProps>(({ children, href, isExternal, ...props }, ref) => {
	return (
		<ChakraLinkOverlay ref={ref} isExternal={isExternal} {...props}>
			{children}
			{isExternal && <Icon as={HiExternalLink} />}
		</ChakraLinkOverlay>
	);
});

LinkOverlay.displayName = 'LinkOverlay';
export { LinkOverlay };
