import { useEffect, useState, useRef } from 'react';

import { HiOutlineRefresh } from 'react-icons/hi';
import { Alert, Button, Divider, Flex, Heading, Link, Progress, Spinner, Text } from '@elemeno/ui';

import { useAnalytics } from 'analytics/useAnalytics';
import { AnalyticsEvents } from 'analytics/AnalyticsEvents';
import { resolveSiteName } from 'contexts/SiteContext';
import { errorToString } from 'utils/errorToString';
import { getFilestackClient } from 'utils/filestack';
import { useTitle } from 'hooks/useTitle';

import { AccessTestCard } from './AccessTestCard';
import { getTestData, TestDataItem, TestType, compareTestSeverity, compareTestType } from './AccessTest.utils';
import { captureException } from 'utils/ErrorLogger';
import { handlePromise, mapAllSettled } from 'utils/async';

const { WebSocket } = window;

// not running in site-context, but let's get the site name for logging
const RAW_SITE_NAME = resolveSiteName(); // may be id, but config will specify

// TODO: We may want to add a timeout to ensure that we record errors on hanging
// connections.
export const AccessTest: React.FC = () => {
	useTitle('Access Test');
	const testDataRef = useRef(getTestData());

	const [runningTests, setRunningTests] = useState<TestDataItem[]>([]);
	const [passingTests, setPassingTests] = useState<TestDataItem[]>([]);
	const [failingTests, setFailingTests] = useState<TestDataItem[]>([]);
	const [completedCount, setCompletedCount] = useState<number>(0);
	const [isDone, setIsDone] = useState<boolean>(false);
	const containerRef = useRef<HTMLDivElement>(null);
	const { track } = useAnalytics();

	function testMatch(t1: TestDataItem, t2: TestDataItem) {
		return !['url', 'data', 'type', 'source', 'purpose', 'severity'].some((k) => t1[k] !== t2[k]);
	}

	const markRunning = (test: TestDataItem, running: boolean): void => {
		setRunningTests((prev) => [
			...(running ? [test] : []), // add if running
			// should only ever add test once, but just in case also filter on running
			...prev.filter((t) => !testMatch(t, test)), // remove if exists
		]);
	};

	const markPassing = (test: TestDataItem): void => {
		markRunning(test, false);
		setPassingTests((prev) => [{ ...test, complete: true }, ...prev]);
		setCompletedCount((prev) => prev + 1);
	};

	const markFailing = (test: TestDataItem, error: Error): void => {
		markRunning(test, false);
		setFailingTests((prev) => [{ ...test, error: errorToString(error) }, ...prev]);
		setCompletedCount((prev) => prev + 1);
		track(
			AnalyticsEvents.testFailure({
				site: RAW_SITE_NAME,
				siteId: window.SITE_ID, // if defined
				error: errorToString(error),
			}),
		);
		// ErrorLogger is initialized in the root index.tsx, and errors seem to be transmitted,
		// but... Sentry is configured with a router instrumentation, so logging outside the router
		// seems like it could potentially be problematic.
		captureException(error, { url: test.url, page: 'test' });
	};

	// errors that indicate successful connection and error response
	const ALLOWED_HTTP_ERRORS = [400, 401, 404, 405];

	const handleHttpError = (response: Response): Response => {
		if (response.ok || ALLOWED_HTTP_ERRORS.includes(response.status)) {
			return response;
		}
		throw Error(response.statusText);
	};

	const handleGet = (test: TestDataItem): Promise<void> => {
		markRunning(test, true);
		return fetch(test.url, {
			method: 'GET',
		})
			.then(handleHttpError)
			.then(() => {
				markPassing(test);
			})
			.catch((error) => {
				markFailing(test, error);
			});
	};

	const handlePut = (test: TestDataItem): Promise<void> => {
		markRunning(test, true);
		return fetch(test.url, {
			method: 'PUT',
			body: test.data,
			headers: test.contentType ? { 'Content-Type': test.contentType } : undefined,
		})
			.then(handleHttpError)
			.then(() => {
				markPassing(test);
			})
			.catch((error) => {
				markFailing(test, error);
			});
	};

	const handlePost = (test: TestDataItem): Promise<void> => {
		markRunning(test, true);
		return fetch(test.url, {
			method: 'POST',
			body: test.data,
			headers: test.contentType ? { 'Content-Type': test.contentType } : undefined,
		})
			.then(handleHttpError)
			.then(() => {
				markPassing(test);
			})
			.catch((error) => {
				markFailing(test, error);
			});
	};

	const handleScript = (test: TestDataItem): void => {
		markRunning(test, true);
		const script = document.createElement('script');
		containerRef.current?.appendChild(script);

		script.addEventListener('load', () => {
			markPassing(test);
		});
		script.addEventListener('error', () => {
			markFailing(test, new Error('Error loading script tag'));
		});

		script.src = test.url;
	};

	const handleWebSocket = (test: TestDataItem): void => {
		markRunning(test, true);
		let completed = false;
		try {
			const ws = new WebSocket(test.url);
			ws.onopen = () => {
				markPassing(test);
				completed = true; // don't report any errors after it's been verified to open
				ws.close(1000, 'test completed');
			};
			ws.onerror = () => {
				if (!completed) {
					markFailing(test, new Error('WebSocket connection failed'));
				}
			};
		} catch (error: any) {
			markFailing(test, error as Error);
		}
	};

	const handleFileStack = (test: TestDataItem): void => {
		markRunning(test, true);
		const filestack = getFilestackClient();
		const textBlob = new Blob(['Test'], { type: 'text/plain' });
		const storeOptions = { filename: 'feel_free_to_delete_this.txt', path: 'access_test/' };

		filestack
			.upload(textBlob, {}, storeOptions)
			.then(() => {
				markPassing(test);
			})
			.catch((error) => {
				markFailing(test, error);
			});
	};

	const handleImage = (test: TestDataItem): void => {
		markRunning(test, true);
		const tester = new Image();
		tester.onload = () => markPassing(test);
		tester.onerror = () => markFailing(test, new Error('image failed to load'));
		tester.src = test.url;
	};

	const runTests = (): void => {
		setRunningTests([]);
		setPassingTests([]);
		setFailingTests([]);
		setCompletedCount(0);
		setIsDone(false);

		handlePromise(
			mapAllSettled(
				testDataRef.current,
				async (test) => {
					switch (test.type) {
						case TestType.XHRGet:
							await handleGet(test);
							break;
						case TestType.XHRPost:
							await handlePost(test);
							break;
						case TestType.XHRPut:
							await handlePut(test);
							break;
						case TestType.Script:
							handleScript(test);
							break;
						case TestType.WebSocket:
							handleWebSocket(test);
							break;
						case TestType.FileStack:
							handleFileStack(test);
							break;
						case TestType.Image:
							handleImage(test);
							break;
						default:
						// track('Unknown test type');
					}
				},
				{ concurrency: 8 },
			),
		);
	};

	useEffect(() => {
		track(
			AnalyticsEvents.testPage({
				site: RAW_SITE_NAME,
				siteId: window.SITE_ID, // if defined
			}),
		);
		runTests();

		// OLD TODOs:
		// static.fst.lmno.care [I see no sign that we use this.]
		// 'https://help.elemenohealth.com/en/' [manual check added; would be nice
		// to automate if possible]

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (completedCount === testDataRef.current.length) {
			track(
				AnalyticsEvents.testComplete({
					site: RAW_SITE_NAME,
					siteId: window.SITE_ID, // if defined
					successful: passingTests.length,
					errors: failingTests.length,
				}),
			);
			setIsDone(true);
		}
	}, [completedCount, failingTests.length, passingTests.length, track]);

	return (
		<Flex.V gap={4} px={4} pb={4}>
			<div ref={containerRef} />

			<Flex.H gap={4} alignItems="center" justifyContent="space-between">
				<Flex.H gap={4}>
					<a href="/">
						<img src="/favicon/favicon-192.png" alt="Elemeno Logo" width="36" height="36" />
					</a>
					<Heading size="lg">Elemeno Access Test</Heading>
				</Flex.H>

				<Button variant="outline" leftIcon={HiOutlineRefresh} isDisabled={!isDone} onClick={runTests}>
					Rerun
				</Button>
			</Flex.H>

			<Divider />

			<Progress
				value={completedCount}
				max={testDataRef.current.length}
				colorScheme={isDone ? (failingTests.length ? 'red' : 'green') : 'blue'}
				hasStripe={!isDone}
				isAnimated
			/>

			{!isDone ? (
				<Alert title="Just one moment">
					<Flex.H gap={4}>
						<Spinner />

						<Text>Testing in progress.</Text>
					</Flex.H>
				</Alert>
			) : (
				<>
					<Heading as="h4" size="md" textAlign="center">
						Tests completed with{' '}
						{failingTests.length
							? `${failingTests.length} error${failingTests.length === 1 ? '' : 's'}`
							: 'no errors'}
						.
					</Heading>

					{!failingTests.length && (
						<Alert title="Some manual testing is recommended">
							<Flex.V gap={2}>
								<Text>
									<Link href="https://help.elemenohealth.com" isExternal>
										help.elemenohealth.com
									</Link>{' '}
									- check if our help page loads.
								</Text>

								<Text>
									<Link href="https://intercom.com" isExternal>
										intercom.com
									</Link>{' '}
									- check if you can chat and get a response.
								</Text>
							</Flex.V>
						</Alert>
					)}
				</>
			)}

			{[...runningTests, ...failingTests.sort(compareTestSeverity), ...passingTests.sort(compareTestType)].map(
				(test) => (
					<AccessTestCard key={test.url} test={test} />
				),
			)}
		</Flex.V>
	);
};
