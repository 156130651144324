import {
	tomorrow, // light
	sunburst, // dark
	railscast, // warm
	github, // github
	hybrid, // vscode
} from 'react-code-blocks';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTheme = (theme: string): any => {
	switch (theme) {
		case 'light':
			return tomorrow;
		case 'dark':
			return sunburst;
		case 'warm':
			return railscast;
		case 'github':
			return github;
		case 'vscode':
			return hybrid;
		default:
			return railscast;
	}
};
