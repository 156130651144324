import { createContext, useCallback, useContext, useLayoutEffect, useMemo, useState } from 'react';
import { Filestack, PickerDisplayMode, type Client, type PickerInstance } from 'filestack-js';
import { Box, Portal, Spinner } from '@elemeno/ui';

import { STAGE, FILE_BUCKET, FILESTACK_API_KEY, isProd } from 'helpers/constants';
import { useSiteContext } from 'contexts/SiteContext';
import logger from 'utils/logger';
import { useLogFileUploads, UploadTypes } from 'gql/mutations/useLogFileUploads';

import { handlePromise } from './async';
import { toastError } from './Toasts';
import { PickerDropPane, PickerInline, PickerOverlay } from './filestack-react';

export { UploadTypes, PickerInstance };

/** ************************************
 * FileStack resource text uploader hook
 **************************************/

function generatePath(siteId: string, resourceId?: string): string {
	return 'content/' + siteId + '/' + (resourceId ? resourceId + '/' : '');
}

function getFileUrl(key: string): string {
	return `https://${FILE_BUCKET}/` + encodeURIComponent(key).replace(/%2f/gi, '/');
}

/**
 * File upload function for markdown images.
 * Uploads the file to s3 via filestack and returns a promise that resolves to the URL.
 *
 * @param {File} file The browser file object.
 * @param {Object} params
 * @param {String} params.resourceId The ID of the resource the image should be associated with.
 * @param {Function} params.onSuccess Callback when successful with url of the file.
 * @param {Function} params.onError Callback when failure with an error message.
 */
export function useUploadFile({
	type,
	resourceId,
}: {
	type: string;
	resourceId: string;
}): (file: File, onSuccess: (url: string) => void, onError: (error: string) => void) => void {
	const { siteId } = useSiteContext();

	const [logUploads] = useLogFileUploads({ type, siteId, resourceId });

	return useCallback(
		(file, onSuccess, onError) => {
			getFilestackClient()
				.upload(file, {}, { path: generatePath(siteId, resourceId) })
				.then((response) => {
					handlePromise(logUploads([response]));
					return getFileUrl(response.key);
				})
				.then(onSuccess)
				.catch((error) => {
					logger.error('Error uploading file', error);
					onError('Error uploading file.');
				});
		},
		[resourceId, siteId, logUploads],
	);
}

/** ************************************
 * FileStack button component
 **************************************/

/**
 * TODO: We've got makePath and generatePath, and both get used for quickrefs at times, but lead to different paths.  We
 * should consolidate if possible.
 * Convert basic path to a S3-appropriate, deployment-dependent path.
 * i.e. 'appicon' => 'offline-appicon/'
 * @param {String} path
 */
function makePath(path: string): string {
	const cleanPath = path.trim();
	const fullPath = cleanPath + (cleanPath.endsWith('/') ? '' : '/');
	return isProd ? fullPath : `${STAGE}-${fullPath}`;
}

let _filestackClient: Client;
export const getFilestackClient = (): Client => {
	if (!_filestackClient) {
		_filestackClient = Filestack(FILESTACK_API_KEY);
	}
	return _filestackClient;
};

// Unique ID for common filestack picker container, only one picker should be open at any time,
// so a common container should be fine, and cause the picker to overlay any modals.
const FILESTACK_CONTAINER_ID = 'filestack_container';

/**
 *
 * @param {Object} params
 * @param {ref} pickerRef Forwarded react ref, to allow onEffect to close modal when container is unloaded.
 * @param {Function} onUpload Callback with url on successful upload.
 * @param {Function} onError Callback with error object on failure.
 * @param {String} path s3 path to use for files (e.g. site/resourceId)
 */
function launchPicker({
	pickerRef,
	path,
}: {
	pickerRef: React.MutableRefObject<PickerInstance | undefined>;
	path: string;
}): Promise<Array<{ key: string }>> {
	return new Promise((resolve, reject) => {
		const container = document.getElementById(FILESTACK_CONTAINER_ID);

		if (!container) {
			const elem = document.createElement('div');
			elem.id = FILESTACK_CONTAINER_ID;
			document.body.appendChild(elem);
		}

		pickerRef.current = getFilestackClient().picker({
			container: `#${FILESTACK_CONTAINER_ID}`,
			onUploadDone: (result) => {
				if (result.filesUploaded.length) {
					// const url = getFileUrl(result.filesUploaded[0].key);
					// give filestack a moment to cleanup the picker
					setTimeout(() => resolve(result.filesUploaded as Array<{ key: string }>), 100);
				}
			},
			storeTo: { path: makePath(path) },
			displayMode: PickerDisplayMode.overlay,
		});
		pickerRef.current.open().catch(reject);
	});
}

export function useFilePicker({
	pickerRef,
	type,
	resourceId,
}: {
	pickerRef: React.MutableRefObject<PickerInstance | undefined>;
	type: string;
	resourceId: string;
}): () => Promise<string> {
	const { siteId } = useSiteContext();

	const [logUploads] = useLogFileUploads({ type, siteId, resourceId });

	return useCallback(
		() =>
			launchPicker({ pickerRef, path: generatePath(siteId, resourceId) }).then((files) => {
				handlePromise(logUploads(files)); // assume successful
				return getFileUrl(files[0].key);
			}),
		[pickerRef, resourceId, siteId, logUploads],
	);
}

type PickerResult = {
	filesFailed: any[];
	filesUploaded: {
		container: string; // "fs-deva.lmno.cloud"
		filename: string; // "5YQqMyihRV2YDGNPDeOv_continuous.pdf"
		handle: string; // "k4YlH10SWGxtFVcS6D2h"
		key: string; // "offline-content/localhost/announcements/kgLogSPTdqbOLZUOTfpx_5YQqMyihRV2YDGNPDeOv_continuous.pdf"
		mimetype: string; // "application/pdf"
		originalFile: { name: string; type: string; size: number }; // {name: '5YQqMyihRV2YDGNPDeOv_continuous.pdf', type: 'application/pdf', size: 3255}
		originalPath: string; // "5YQqMyihRV2YDGNPDeOv_continuous.pdf"
		size: number; // 3255
		source: string; // "local_file_system"
		status: string; // "Stored"
		uploadId: string; // "yc5IOFucuw1235xQ"
		url: string; // "https://cdn.filestackcontent.com/k4YlH10SWGxtFVcS6D2h"
	}[];
};

export type FileInfo = {
	url: string;
	filename: string;
};

function filestackUploadToFileInfo(files: PickerResult['filesUploaded']): FileInfo[] {
	return files.map((file) => ({
		url: `https://${file.container}/${encodeURI(file.key)}`,
		filename: file.filename,
	}));
	// if we want to use our alias to the filestack cdn
	// const url = file.url.replace('/cdn.filestackcontent.com/', '/cdn.fst.lmno.care/');
}

/**
 * Wrapping of the native FileStack PickerOverlay component to force
 * path specification (should include siteId), return a url for our
 * file store s3 domain, and log the upload.
 */
export function WrappedPdfPickerOverlay({
	onClose,
	onSuccess,
	path,
	type,
	siteId,
	resourceId,
}: {
	onClose: () => void;
	onSuccess: (url: string, title: string) => void;
	path: string;
	type: UploadTypes;
	siteId: string;
	resourceId?: string; // overloading with announcementId, if it exists
}) {
	const [logUploads] = useLogFileUploads({ type, siteId, resourceId });

	const pickerOptions = useMemo(
		() => ({
			onClose,
			accept: '.pdf',
			fromSources: ['local_file_system'],
			storeTo: { path: makePath(path) },
		}),
		[onClose, path],
	);

	const _onSuccess = useCallback(
		(result: PickerResult): void => {
			// console.log('result', result);
			if (!result?.filesUploaded?.length) return; // TODO: on Error?
			handlePromise(logUploads(result.filesUploaded));
			const { url, filename: title } = filestackUploadToFileInfo(result.filesUploaded)[0];
			onSuccess(url, title);
		},
		[logUploads, onSuccess],
	);

	return (
		<Portal>
			<PickerOverlay pickerOptions={pickerOptions} onSuccess={_onSuccess} apiKey={FILESTACK_API_KEY} />
		</Portal>
	);
}

/**
 * Wrapping of the native FileStack PickerOverlay component to force
 * path specification (should include siteId), return a url for our
 * file store s3 domain, and log the upload.
 */
export function WrappedMultiPickerOverlay({
	onClose,
	onSuccess,
	path,
	type,
	siteId,
	resourceId,
}: {
	onClose: () => void;
	onSuccess: (filesUploaded: FileInfo[]) => void;
	path: string;
	type: UploadTypes;
	siteId: string;
	resourceId?: string; // overloading with announcementId, if it exists
}) {
	const [logUploads] = useLogFileUploads({ type, siteId, resourceId });

	const pickerOptions = useMemo(
		() => ({
			onClose,
			// accept: '.pdf', // TODO: limit to certain file types??
			fromSources: ['local_file_system'],
			storeTo: { path: makePath(path) },
			maxFiles: 10,
		}),
		[onClose, path],
	);

	const _onSuccess = useCallback(
		(result: PickerResult): void => {
			try {
				// console.log('result', result);
				if (!result?.filesUploaded?.length) return; // TODO: on Error?
				handlePromise(logUploads(result.filesUploaded));
				// const url = file.url.replace('/cdn.filestackcontent.com/', '/cdn.fst.lmno.care/');
				onSuccess(filestackUploadToFileInfo(result.filesUploaded));
			} catch (e) {
				logger.error('error in WrappedMultiPickerOverlay', e);
				toastError('Error uploading files');
			}
		},
		[logUploads, onSuccess],
	);

	return (
		<Portal>
			<PickerOverlay pickerOptions={pickerOptions} onSuccess={_onSuccess} apiKey={FILESTACK_API_KEY} />
		</Portal>
	);
}

export function FileStackInlinePicker({ path = `content/upload` }) {
	const pickerOptions = useMemo(
		() => ({
			fromSources: ['local_file_system'],
			storeTo: { path: makePath(path) },
		}),
		[path],
	);
	return <PickerInline apiKey={FILESTACK_API_KEY} pickerOptions={pickerOptions} />;
}

function RenderDropPane({ display, pickerOptions, onSuccess }) {
	return display ? (
		<Box style={{ minHeight: 120, backgroundColor: '#eee' }}>
			<PickerDropPane apiKey={FILESTACK_API_KEY} pickerOptions={pickerOptions} onSuccess={onSuccess} />
		</Box>
	) : (
		<Box
			style={{
				minHeight: 120,
				backgroundColor: '#eee',
				border: '2px dashed #bdbdbd',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<Spinner />
		</Box>
	);
}

type FilesUploadStatusType = { count: number; increment: () => void; decrement: () => void };

const FileUploadStatusContext = createContext<FilesUploadStatusType>({
	count: 0,
	increment: () => {},
	decrement: () => {},
});

export function FileUploadStatusProvider({ children }) {
	const [count, setCount] = useState(0);
	const increment = useCallback(() => setCount((c) => c + 1), []);
	const decrement = useCallback(() => setCount((c) => c - 1), []);
	return <FileUploadStatusContext.Provider value={{ count, increment, decrement }} {...{ children }} />;
}
export const useFilesUploading = (): FilesUploadStatusType => useContext(FileUploadStatusContext);

export function FileStackDropPane({
	onSuccess,
	path = 'content/upload',
	type,
	siteId,
	resourceId,
	maxFiles = 1,
}: {
	onSuccess: (filesUploaded: FileInfo[]) => void;
	path: string;
	type: UploadTypes;
	siteId: string;
	resourceId?: string; // overloading with announcementId, if it exists
	maxFiles?: number;
}) {
	const { increment: incrementFilesUploading, decrement: decrementFilesUploading } = useFilesUploading();

	const pickerOptions = useMemo(
		() => ({
			fromSources: ['local_file_system'],
			storeTo: { path: makePath(path) },
			maxFiles,
			dropPane: { customText: `Drop ${maxFiles > 1 ? 'files' : 'a file'}, or click here to pick.` },
			onFileUploadStarted: incrementFilesUploading,
			onFileUploadFinished: decrementFilesUploading,
			onFileUploadFailed: decrementFilesUploading,
			onFileUploadCancel: decrementFilesUploading,
		}),
		[maxFiles, path, incrementFilesUploading, decrementFilesUploading],
	);

	const [logUploads] = useLogFileUploads({ type, siteId, resourceId });

	const onError = useCallback((e) => {
		logger.error('error in FileStackDropPane', e);
		toastError('Error uploading files');
	}, []);

	const _onSuccess = useCallback(
		// eslint-disable-next-line sonarjs/no-identical-functions
		(result: PickerResult): void => {
			if (result?.filesFailed?.length) {
				logger.error('error in FileStackDropPane', result);
				toastError(
					'Error uploading files: ' +
						result?.filesFailed?.map((f) => f.originalFile?.name || f.originalPath).join(', '),
				);
			}
			try {
				if (!result?.filesUploaded?.length) return; // TODO: on Error?
				handlePromise(logUploads(result.filesUploaded));
				onSuccess(filestackUploadToFileInfo(result.filesUploaded));
			} catch (e) {
				onError(e);
			}
		},
		[logUploads, onError, onSuccess],
	);

	// Delay the display of the drop pane during initial render.
	// Originally this was to avoid an observed double-render issue,
	// which has been resolved, but sometimes see a warning where filestack
	// is rendering prior to the containing element being mounted.
	const [display, setDisplay] = useState(false);
	useLayoutEffect(() => {
		setTimeout(() => setDisplay(true), 500);
	}, []);

	return <RenderDropPane {...{ display, pickerOptions, onSuccess: _onSuccess }} />;
}
