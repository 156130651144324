import { TagProps as ChakraTagProps } from '@chakra-ui/react';
import { ColorScheme } from '@elemeno/ui';

export interface TagProps extends ChakraTagProps {
	variant?: 'subtle' | 'solid' | 'outline';
	size?: 'sm' | 'md' | 'lg';
	colorScheme?: ColorScheme | ChakraTagProps['colorScheme'];
	/** If present, an icon will be shown before the button's label */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	leftIcon?: any;
	/** If present, an icon will be shown after the button's label */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	rightIcon?: any;
	/** Passing a function will make the close button appear; clicking the button will call
	 * the passed function */
	onClose?: (e: React.MouseEvent) => void;
}

export const TagDefaultProps: TagProps = {
	variant: 'subtle',
	size: 'md',
	colorScheme: 'elemenoMediumBlue',
};
