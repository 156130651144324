import { capitalizeIfLowerCase } from './capitalize';

export function combineNames(firstname, lastname) {
	const parts: string[] = [];
	if (firstname) {
		parts.push(capitalizeIfLowerCase(firstname));
	}
	if (lastname) {
		parts.push(capitalizeIfLowerCase(lastname));
	}
	return parts.join(' ');
}

export function toFullName({
	firstname,
	lastname,
	fullName,
}: { firstname?: string; lastname?: string; fullName?: string } = {}) {
	if (fullName) return fullName;

	if (!firstname && !lastname) {
		return '';
	}
	return combineNames(firstname, lastname);
}
