import { forwardRef } from 'react';

import { Input as ChakraInput, InputGroup } from '@chakra-ui/react';
import { FormControl, FormControlProps, SystemProps, popFormControlProps } from '@elemeno/ui';

import { InputProps, InputDefaultProps } from './Input.types';

const Input = forwardRef<HTMLInputElement, InputProps>(({ contentLeft, contentRight, ...props }, ref) => {
	const { poppedProps, otherProps } = popFormControlProps(props);

	return (
		<FormControl {...(poppedProps as FormControlProps)}>
			<InputGroup>
				{contentLeft}
				<ChakraInput ref={ref} {...InputStyles} {...otherProps} />
				{contentRight}
			</InputGroup>
		</FormControl>
	);
});

const InputStyles: SystemProps = {
	borderColor: 'gray.300',
	background: '#FFF',
};

Input.displayName = 'Input';
Input.defaultProps = InputDefaultProps;

export { Input };
