import { forwardRef, useMemo } from 'react';

import { PinInput as ChakraPinInput, PinInputField } from '@chakra-ui/react';
import { Flex, FormControl, FormControlProps, SystemProps, popFormControlProps } from '@elemeno/ui';

import { PinInputProps, PinInputDefaultProps } from './PinInput.types';

const PinInput = forwardRef<HTMLInputElement, PinInputProps>(({ isPrivate, startFocused, length, ...props }, ref) => {
	const { poppedProps, otherProps } = popFormControlProps(props);

	const inputs = useMemo(() => {
		if (length && length > 0) {
			const pins: JSX.Element[] = [];
			for (let i = 0; i < length; i++) {
				pins.push(<PinInputField key={i} />);
			}
			return pins;
		}
		return null;
	}, [length]);

	return (
		<FormControl ref={ref} {...(poppedProps as FormControlProps)}>
			<Flex.H gap="0.25rem">
				<ChakraPinInput
					// eslint-disable-next-line jsx-a11y/no-autofocus
					autoFocus={startFocused}
					mask={isPrivate}
					{...InputStyles}
					{...otherProps}
				>
					{inputs}
				</ChakraPinInput>
			</Flex.H>
		</FormControl>
	);
});

const InputStyles: SystemProps = {
	borderColor: 'gray.300',
};

PinInput.displayName = 'PinInput';
PinInput.defaultProps = PinInputDefaultProps;

export { PinInput };
