import { forwardRef } from 'react';
import { Image as ChakraImage } from '@chakra-ui/react';

import { ImageProps, ImageDefaultProps } from './Image.types';

const Image = forwardRef<HTMLImageElement, ImageProps>(({ isFallbackDisabled, crossOrigin, ...props }, ref) => {
	const origin = crossOrigin === 'none' ? '' : crossOrigin;

	return <ChakraImage ref={ref} ignoreFallback={isFallbackDisabled} crossOrigin={origin} {...props} />;
});

Image.displayName = 'Image';
Image.defaultProps = ImageDefaultProps;

export { Image };
