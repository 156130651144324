import { AlertProps as ChakraAlertProps } from '@chakra-ui/react';

export type Status = 'loading' | 'info' | 'success' | 'warning' | 'error';

export interface AlertProps extends ChakraAlertProps {
	variant?: 'subtle' | 'solid' | 'left-accent' | 'top-accent';
	status?: Status;
	/** Emphasized text which prefixes the message */
	title?: string;
	/** Passing a function will make the close button appear; clicking the button will call
	 * the passed function */
	onClose?: () => void;
	icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
}

export const AlertDefaultProps: AlertProps = {
	variant: 'subtle',
	status: 'info',
	title: '',
};
