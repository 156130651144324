import { PopoverProps as ChakraPopoverProps } from '@chakra-ui/react';

export interface PopoverProps
	extends Omit<
		ChakraPopoverProps,
		| 'trigger'
		| 'autoFocus'
		| 'autoSelect'
		| 'closeOnBlur'
		| 'closeOnSelect'
		| 'computePositionOnMount'
		| 'defaultIsOpen'
		| 'matchWidth'
		| 'preventOverflow'
	> {
	children?: JSX.Element;
	title?: string;
	trigger?: JSX.Element;
	footer?: JSX.Element;
	orientation?: 'horizontal' | 'vertical';
	boundary?: HTMLElement | 'clippingParents' | 'scrollParent';
	placement?: ChakraPopoverProps['placement'];
	strategy?: 'fixed' | 'absolute';
	offset?: [number, number];
	arrowSize?: number;
	arrowPadding?: number;
	arrowShadowColor?: string;
	openDelay?: number;
	closeDelay?: number;
	gutter?: number;
	isOpen?: boolean;
	isOnClick?: boolean;
	/** If `true`, the `Popover` will match the width of the reference at all times */
	isWidthMatched?: boolean;
	isLazy?: boolean;
	lazyBehavior?: ChakraPopoverProps['lazyBehavior'];
	isClosedOnBlur?: boolean;
	/** If `true`, the `Popover` will be positioned when it mounts (even if it's not open)
	 * Note 🚨: We don't recommend using this in a popover/menu intensive UI or page as it
	 * might affect scrolling performance. */
	isPositionEager?: boolean;
	/** If `true`, will prevent the popper from being cut off and ensure it's visible within
	 * the boundary area */
	isOverflowHidden?: boolean;
	/** If `true`, the `Popover` will be initially opened */
	startOpened?: boolean;
	/** If `true`, focus will be transferred to the first interactive element when the
	 * popover opens */
	startFocused?: boolean;
	/** If `true`, the popper will change its placement and flip when it's about to overflow
	 * it's boundary area */
	canPositionFlip?: boolean;
	initialFocusRef?: ChakraPopoverProps['initialFocusRef'];
	isDisabled?: boolean;
	onOpen?: () => void;
	onClose?: () => void;
}

export const PopoverDefaultProps: PopoverProps = {
	title: '',
	boundary: 'clippingParents',
	orientation: 'vertical',
	placement: 'bottom',
	strategy: 'absolute',
	arrowPadding: 8,
	openDelay: 200,
	closeDelay: 200,
	gutter: 8,
	isWidthMatched: false,
	isOnClick: false,
	isLazy: false,
	lazyBehavior: 'unmount',
	isClosedOnBlur: true,
	isPositionEager: false,
	isOverflowHidden: true,
	startOpened: false,
	startFocused: false,
	canPositionFlip: true,
};
