import { forwardRef } from 'react';
import { Kbd } from '@chakra-ui/react';

import { KeyProps, KeyDefaultProps } from './Key.types';

const Key = forwardRef<HTMLSpanElement, KeyProps>(({ ...props }, ref) => {
	return <Kbd ref={ref} {...props} />;
});

Key.displayName = 'Key';
Key.defaultProps = KeyDefaultProps;

export { Key };
