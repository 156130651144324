import { forwardRef } from 'react';

import { Box, Divider, Flex, Heading, popPaddingProps } from '@elemeno/ui';

import { CardProps, CardDefaultProps } from './Card.types';

const Card = forwardRef<HTMLDivElement, CardProps>(
	({ children, title, footer, barColor, showBar, isResponsive, noOverflowHidden, noXPadding, ...props }, ref) => {
		const { poppedProps, otherProps } = popPaddingProps(props);

		return (
			<Box
				ref={ref}
				w="full"
				bg="white"
				rounded={isResponsive ? { base: 'none', tablet: '2xl' } : '2xl'}
				{...(noOverflowHidden ? {} : { overflow: 'hidden' })}
				{...otherProps}
			>
				{showBar && <Box h={4} w="full" bg={barColor} />}

				{title && (
					<>
						<Box py={3} px={noXPadding ? undefined : { base: isResponsive ? 3 : 7, tablet: 7 }}>
							{typeof title === 'string' ? (
								<Heading size={{ base: isResponsive ? 'lg' : 'md', tablet: 'md' }}>{title}</Heading>
							) : (
								title
							)}
						</Box>

						<Divider />
					</>
				)}

				<Box py={6} px={noXPadding ? undefined : { base: isResponsive ? 3 : 7, tablet: 7 }} {...poppedProps}>
					{children}
				</Box>

				{footer && (
					<>
						<Divider />

						<Flex.H
							py={3}
							px={noXPadding ? undefined : { base: isResponsive ? 3 : 7, tablet: 7 }}
							gap={3}
							alignItems="center"
							justifyContent="flex-end"
						>
							{footer}
						</Flex.H>
					</>
				)}
			</Box>
		);
	},
);

Card.displayName = 'Card';
Card.defaultProps = CardDefaultProps;

export { Card };
