import { BoxProps } from '@chakra-ui/react';

export interface FormProps extends BoxProps {
	id?: string;
	onSubmit?: (event: React.FormEvent) => void;
	noValidate?: boolean;
}

export const FormDefaultProps: FormProps = {
	noValidate: true,
};
