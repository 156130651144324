import { ChangeEventHandler, FocusEventHandler } from 'react';
import { SwitchProps as ChakraSwitchProps } from '@chakra-ui/react';
import { ColorScheme, SystemProps } from '@elemeno/ui';

export interface SwitchProps extends Omit<ChakraSwitchProps, 'defaultChecked'> {
	label?: string;
	/** A sub label that appears below the component */
	hint?: string;
	/** If present, it will replace the hint and apply invalid styles */
	error?: string;
	size?: 'sm' | 'md' | 'lg';
	value?: string | number;
	colorScheme?: ColorScheme;
	isDisabled?: boolean;
	/** If `true`, the checkbox will be initially checked */
	startSelected?: boolean;
	spacing?: SystemProps['marginLeft'];
	onChange?: ChangeEventHandler<HTMLInputElement>;
	onFocus?: FocusEventHandler<HTMLInputElement>;
	onBlur?: FocusEventHandler<HTMLInputElement>;
}

export const SwitchDefaultProps: SwitchProps = {
	label: '',
	hint: '',
	error: '',
	size: 'md',
	colorScheme: 'elemenoMediumBlue',
	isDisabled: false,
	startSelected: false,
	spacing: '0.5rem',
};
