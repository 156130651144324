import { SpinnerProps as ChakraSpinnerProps } from '@chakra-ui/react';

export interface SpinnerProps extends ChakraSpinnerProps {
	size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
	thickness?: string;
	label?: string;
	emptyColor?: string;
	speed?: string;
}

export const SpinnerDefaultProps: SpinnerProps = {
	size: 'md',
	thickness: '2px',
	label: 'Loading...',
	emptyColor: 'gray.300',
	speed: '0.45s',
};
