import { MouseEventHandler } from 'react';
import { ButtonProps as ChakraButtonProps, SystemProps } from '@chakra-ui/react';
import { ColorScheme } from '@elemeno/ui';

export interface ButtonProps extends Omit<ChakraButtonProps, 'rightIcon' | 'leftIcon'> {
	variant?: 'ghost' | 'outline' | 'solid' | 'link' | 'unstyled';
	size?: 'xs' | 'sm' | 'md' | 'lg';
	colorScheme?: ColorScheme;
	loadingText?: string;
	isActive?: boolean;
	isDisabled?: boolean;
	isLoading?: boolean;
	/** If present, an icon will be shown before the button's label */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	leftIcon?: any;
	/** If present, an icon will be shown after the button's label */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	rightIcon?: any;
	iconSpacing?: SystemProps['marginRight'];
	spinner?: JSX.Element;
	spinnerPlacement?: 'end' | 'start';
	// support link buttons with "as" props with react-router-link
	to?: string;
	href?: string;
	onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const ButtonDefaultProps: ButtonProps = {
	variant: 'solid',
	size: 'md',
	colorScheme: 'elemenoBrightBlue',
	spinnerPlacement: 'start',
	isActive: false,
	isDisabled: false,
	isLoading: false,
	loadingText: '',
};
