import { WrapProps as ChakraWrapProps } from '@chakra-ui/react';
import { SystemProps } from '@elemeno/ui';

export interface WrapProps extends Omit<ChakraWrapProps, 'shouldWrapChildren'> {
	align?: SystemProps['alignItems'];
	direction?: SystemProps['flexDirection'];
	justify?: SystemProps['justifyContent'];
	spacing?: SystemProps['margin'];
	spacingX?: SystemProps['margin'];
	spacingY?: SystemProps['margin'];
}

export const WrapDefaultProps: WrapProps = {
	align: 'normal',
	direction: 'row',
	justify: 'normal',
	spacing: '1.25rem',
};
