import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat.js';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

export const MINUTES_TO_MS = 1000 * 60;
export const HOURS_TO_MS = 60 * MINUTES_TO_MS;

// Do a rough check to see if a value looks like seconds.
// Will take until 2970 for a date value in seconds to overlap a ms value in 1971
const EARLY_DATE_MS = new Date('1971-01-01').getTime();
export const likelySeconds = (time: number): boolean => time < EARLY_DATE_MS;
// We've had bugs that have accidentally scaled ms by 1000.
const LATE_DATE_MS = new Date('2100-01-01').getTime();
export const likelyOverScaled = (time: number): boolean => time > LATE_DATE_MS * 100;

/**
 * Check if a time value is likely to be in seconds (not ms) and convert to ms.
 * For backwards compatibility with values that were stored as seconds.
 * @param {number} value A millisecond or second time value
 */
export function fixTime(value: number): number {
	if (!value) {
		return value;
	}

	if (likelySeconds(value)) {
		return value * 1000;
	} else if (likelyOverScaled(value)) {
		return value / 1000;
	} else {
		return value;
	}
}

export function howLongAgo(timestamp: number): string {
	if (!timestamp) {
		return 'never';
	} else {
		const lastLogin = dayjs(fixTime(timestamp));
		const now = dayjs();
		if (lastLogin.isSame(now, 'day')) {
			return 'today';
		} else {
			const days = lastLogin.diff(now, 'day');
			return dayjs.duration(days, 'days').humanize(true);
		}
	}
}

export function timestampAsDateString(timestamp: number): string {
	return new Date(fixTime(timestamp)).toLocaleDateString();
}

export function formatDateOnly(value: number): string {
	if (!value) {
		return '';
	}
	return dayjs(fixTime(value)).format('YYYY-MM-DD');
}

export function formatShortDate(value?: number): string {
	return (value ? dayjs(fixTime(value)) : dayjs()).format('YYYYMMDD');
}

export function formatAsTime(value): string {
	if (!value) {
		return '';
	}
	return dayjs(fixTime(value)).format('h:mm A');
}

export function formatDateWithComma(value: number): string {
	if (!value) {
		return '';
	}
	return dayjs(fixTime(value)).format('MMM DD, YYYY');
}

const ALT_DATE_REGEX = /([0-9]{1,2})[./-]([0-9]{1,2})[./-]([0-9]{2,4})/;
const ALT_MONTH_REGEX = /([0-9]{1,2})[./-]([0-9]{2,4})/;

function titleCase(str): string {
	return str
		.toLowerCase()
		.split(' ')
		.map(function (word) {
			return word.replace(word[0], word[0].toUpperCase());
		})
		.join(' ');
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function parseStringDate(str: string): Dayjs | undefined {
	if (!str) {
		return undefined;
	} // if falsey, return (assuming 0 is not a valid date)

	// Pre-parse for problematic and canonical cases we see in the database.
	// For some reason, dayjs parses #/# as 'm/d' and adds the year as 2001;
	// it might be fixed in a newer dayjs version.
	// All but one of the below [IIRC it was M/YY or M/YYYY] are in the quickref table in the updated field.
	let day = dayjs(
		str,
		[
			'MM/YYYY',
			'MM/YY',
			'M/YYYY',
			'M/YY',
			'MMDDYY',
			'MMDDYYYY',
			'MM/DD/YY',
			'MM/DDYYYY',
			'MMDD/YYYY',
			'MMDD/YY',
			'MM/DD/YYYY',
			'YYYY-MM-DD',
		],
		true,
	);
	if (day.isValid()) {
		return day;
	} else if (typeof str !== 'string') {
		return undefined; // if not valid and not string, give up
	}

	// else, see if we can suss out a date from alternate formats

	// trim
	str = str.trim();
	if (str.length === 0) {
		return undefined;
	} // on the off chance it's a whitespace string, return

	// one-off to handle "Feb 2020" type dates
	day = dayjs(titleCase(str), ['MMM YYYY', 'MMMM YYYY']);
	if (day.isValid()) {
		return day;
	}

	// strip out any non-numeric / non-delimiter characters
	const cleanStr = str.replace(/[^\d./-]/g, '');

	// look for 'mm?dd?yyyy'
	const altDateMatch = ALT_DATE_REGEX.exec(cleanStr);
	if (altDateMatch) {
		const [, m, d, y] = altDateMatch;
		day = dayjs(`${(y.length === 2 ? '20' : '') + y}-${m}-${d}`);
		if (day.isValid()) {
			return day;
		}
	}

	// look for 'mm?yyyy'
	const altMonthMatch = ALT_MONTH_REGEX.exec(cleanStr);
	if (altMonthMatch) {
		const [, m, y] = altMonthMatch;
		day = dayjs(`${(y.length === 2 ? '20' : '') + y}-${m}-01`);
		if (day.isValid()) {
			return day;
		}
	}

	return undefined; // not able to parse a date from the value
}

export function normalizeNumberDate(value: number | undefined, format = 'MM/DD/YYYY'): string {
	if (!value) return '';
	const day = dayjs(fixTime(value));
	if (!day.isValid()) return '';
	return day.format(format);

	// return (value && dayjs(f)?.format(format)) || '';
}

// Jan 1, 2000
export function formatArizmendiDate(value: number | undefined): string {
	return normalizeNumberDate(value, 'MMM D, YYYY');
}
