import { forwardRef } from 'react';

import { FormLabel, Radio as ChakraRadio, RadioGroup } from '@chakra-ui/react';
import { Box, Flex, FormControl, FormControlProps, getChakraColorScheme, popFormControlProps } from '@elemeno/ui';

import { RadioProps, RadioDefaultProps } from './Radio.types';

const Radio = forwardRef<HTMLInputElement, RadioProps>(
	({ hint, subHint, colorScheme, startSelected, ...props }, ref) => {
		const { poppedProps, otherProps } = popFormControlProps(props);

		return (
			<FormControl {...(poppedProps as FormControlProps)}>
				<Flex.H alignItems="start" gap="2">
					<ChakraRadio
						ref={ref}
						defaultChecked={startSelected}
						colorScheme={getChakraColorScheme(colorScheme)}
						borderColor="gray.300"
						mt="0.25em"
						isDisabled={props.isDisabled}
						{...otherProps}
					/>
					{hint ? (
						<FormLabel htmlFor={props.id} m="0">
							{hint}
							{subHint ? (
								<Box fontStyle="italic" fontSize="0.95em" lineHeight="1.2em" mt={1} mb={2}>
									{subHint}
								</Box>
							) : undefined}
						</FormLabel>
					) : null}
				</Flex.H>
			</FormControl>
		);
	},
);

Radio.displayName = 'Radio';
Radio.defaultProps = RadioDefaultProps;

const RadioNotation = Object.assign(Radio, { Group: RadioGroup });

export { RadioNotation as Radio };
