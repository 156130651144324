import { GridProps as ChakraGridProps } from '@chakra-ui/react';
import { SystemProps } from '@elemeno/ui';

export interface GridProps extends ChakraGridProps {
	autoColumns?: SystemProps['gridAutoColumns'];
	autoFlow?: SystemProps['gridAutoFlow'];
	autoRows?: SystemProps['gridAutoRows'];
	column?: SystemProps['gridColumn'];
	row?: SystemProps['gridRow'];
	templateAreas?: SystemProps['gridTemplateAreas'];
	templateColumns?: SystemProps['gridTemplateColumns'];
	templateRows?: SystemProps['gridTemplateRows'];
}

export const GridDefaultProps: GridProps = {
	autoColumns: 'auto',
	autoFlow: 'row',
	autoRows: 'auto',
	column: 'auto / auto',
	row: 'auto / auto',
	templateAreas: 'none',
	templateColumns: 'none',
	templateRows: 'none',
};
