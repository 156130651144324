import { forwardRef, useRef, useMemo, Ref } from 'react';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
} from '@chakra-ui/react';
import { FocusableElement } from '@chakra-ui/utils';
import { Button } from '@elemeno/ui';

import { ConfirmationModalProps, ConfirmationModalDefaultProps } from './ConfirmationModal.types';

const ConfirmationModal = forwardRef<HTMLDivElement, ConfirmationModalProps>(
	(
		{
			children,
			title,
			cancelText,
			buttons,
			onClose,
			startFocused,
			isFocusReturned,
			canPinchZoom,
			canEscape,
			canOverlayClose,
			isScrollDisabled,
			hasScrollGap,
			...props
		},
		ref,
	) => {
		const cancelRef = useRef<FocusableElement>(null);

		const actionButtons = useMemo(
			() => buttons?.map(({ ...props }, index) => <Button key={index} {...props} />) || null,
			[buttons],
		);

		return (
			<AlertDialog
				// Focus the least 'dangerous' option
				leastDestructiveRef={cancelRef}
				// eslint-disable-next-line jsx-a11y/no-autofocus
				autoFocus={startFocused}
				returnFocusOnClose={isFocusReturned}
				allowPinchZoom={canPinchZoom}
				closeOnEsc={canEscape}
				closeOnOverlayClick={canOverlayClose}
				blockScrollOnMount={isScrollDisabled}
				preserveScrollBarGap={hasScrollGap}
				onClose={onClose}
				{...props}
			>
				<AlertDialogOverlay ref={ref}>
					<AlertDialogContent>
						{title && <AlertDialogHeader {...HeaderStyles}>{title}</AlertDialogHeader>}

						<AlertDialogBody>{children}</AlertDialogBody>

						<AlertDialogFooter>
							<Button.Group {...ButtonGroupStyles}>
								<Button
									ref={cancelRef as Ref<HTMLButtonElement>}
									variant="ghost"
									colorScheme="gray"
									onClick={onClose}
								>
									{cancelText}
								</Button>
								{actionButtons}
							</Button.Group>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		);
	},
);

const HeaderStyles = {
	fontSize: 'lg',
	fontWeight: 'bold',
};

const ButtonGroupStyles = {
	spacing: '0.75rem',
};

ConfirmationModal.displayName = 'ConfirmationModal';
ConfirmationModal.defaultProps = ConfirmationModalDefaultProps;

export { ConfirmationModal };
