import { makeVar } from '@apollo/client';
import { RESOURCE_TYPE } from 'types/Resources';

export interface ResourceFiltersProps {
	teamFilters: string[];
	resourceTypeFilters: RESOURCE_TYPE[];
}

export const ResourceFilters = makeVar<ResourceFiltersProps>({
	teamFilters: [],
	resourceTypeFilters: [],
});
