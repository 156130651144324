import { forwardRef } from 'react';
import { Spinner as ChakraSpinner } from '@chakra-ui/react';

import { SpinnerProps, SpinnerDefaultProps } from './Spinner.types';

const Spinner = forwardRef<HTMLDivElement, SpinnerProps>(({ ...props }, ref) => {
	return <ChakraSpinner ref={ref} color="blue.600" {...props} />;
});

Spinner.displayName = 'Spinner';
Spinner.defaultProps = SpinnerDefaultProps;

export { Spinner };
