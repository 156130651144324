import { gql } from '@apollo/client';
import { useSiteContext } from 'contexts/SiteContext';
import { SimpleUseQuery, useSimpleQuery } from 'gql/useSimpleQuery';
import { stubbedGroupId, stubbedGroupName, TEST_SITE } from 'helpers/test/testConstants';

export type MinimalGroupInfo = {
	id: string;
	name: string;
};

type GroupsData = MinimalGroupInfo[];

interface GroupsQueryVariables {
	siteId: string;
}

const QUERY_GET_GROUPS_BY_SITE = gql`
	query getGroupsBySite($siteId: String!) {
		getGroupsBySite(site: $siteId) {
			id
			name
		}
	}
`;

/**
 * @returns List minimal list of groups for current site, without auth (for request access flow).
 */
export const useGroupsPublicQuery: SimpleUseQuery<GroupsData> = () => {
	// automatically pass site id in case not authorized
	const { siteId } = useSiteContext();

	const { data, loading, error } = useSimpleQuery<GroupsData, GroupsQueryVariables>(
		QUERY_GET_GROUPS_BY_SITE,
		'getGroupsBySite',
		{ siteId },
	);

	return { data, loading, error };
};

export const mockedGroupsPublicQuery = [
	{
		request: {
			query: QUERY_GET_GROUPS_BY_SITE,
			variables: { siteId: TEST_SITE },
		},
		result: {
			data: {
				getGroupsBySite: [
					{
						__typename: 'SiteGroupType', // required to match fragment
						id: stubbedGroupId,
						name: stubbedGroupName,
					},
				],
			},
		},
	},
];
