import { forwardRef } from 'react';
import { CircularProgress as ChakraCircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import { Box, popMarginProps } from '@elemeno/ui';

import { CircularProgressProps, CircularProgressDefaultProps } from './CircularProgress.types';

const CircularProgress = forwardRef<HTMLDivElement, CircularProgressProps>(({ label, hasRoundCap, ...props }, ref) => {
	const { poppedProps, otherProps } = popMarginProps(props);

	return (
		<Box ref={ref} {...poppedProps}>
			<ChakraCircularProgress capIsRound={hasRoundCap} {...otherProps}>
				{label && <CircularProgressLabel>{label}</CircularProgressLabel>}
			</ChakraCircularProgress>
		</Box>
	);
});

CircularProgress.displayName = 'CircularProgress';
CircularProgress.defaultProps = CircularProgressDefaultProps;

export { CircularProgress };
