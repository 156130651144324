import { ReactElement, ReactEventHandler } from 'react';
import { ImageProps as ChakraImageProps } from '@chakra-ui/react';
import { SystemProps } from '@elemeno/ui';

export interface ImageProps extends Omit<ChakraImageProps, 'ignoreFallback' | 'crossOrigin'> {
	fit?: SystemProps['objectFit'];
	align?: SystemProps['objectPosition'];
	src?: string;
	srcSet?: string;
	loading?: 'eager' | 'lazy';
	crossOrigin?: 'none' | 'anonymous' | 'use-credentials';
	fallBack?: ReactElement;
	fallbackSrc?: string;
	fallbackStrategy?: ChakraImageProps['fallbackStrategy'];
	/** If `true`, opt out of the fallback logic and render the `img` at all times */
	isFallbackDisabled?: boolean;
	onLoad?: ReactEventHandler<HTMLImageElement>;
	onError?: ReactEventHandler<HTMLImageElement>;
}

export const ImageDefaultProps: ImageProps = {
	fit: 'none',
	align: 'center',
	src: '',
	srcSet: '',
	loading: 'eager',
	crossOrigin: 'none',
	fallbackSrc: '',
	fallbackStrategy: 'beforeLoadOrError',
	isFallbackDisabled: false,
};
