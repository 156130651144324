import { FC } from 'react';
import { Fade, ScaleFade, Slide, SlideFade } from '@chakra-ui/react';

const Transitions: FC = () => null;

const TransitionsNotation = Object.assign(Transitions, {
	Fade,
	ScaleFade,
	Slide,
	SlideFade,
});
export { TransitionsNotation as Transitions };
