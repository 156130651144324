import { useState } from 'react';

import { Card, Flex, Link, Text } from '@elemeno/ui';

import { PATH_HOME } from 'helpers/constants';
import logger from 'utils/logger';
import { toArray } from 'utils/array';
import LocalStore from 'utils/LocalStore';
import { useTitle } from 'hooks/useTitle';
import { useSiteContext } from 'contexts/SiteContext';

import { ErrorDisplayProps, ErrorProps } from './ErrorMessage.interfaces';

type ErrorMessageProps = ErrorProps;

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ error }) => {
	if (error) {
		logger.error('Error on page', error);
	}

	const isNotFoundError = error?.message === 'Not Found!';

	const [tempErrorMessage] = useState(LocalStore.TempError.pop() || LocalStore.TempSsoError.pop());

	useTitle(tempErrorMessage || error?.message || 'Error');

	return (
		<Flex justify="center" mx="auto" pt="6.25rem" w="26.25rem">
			{isNotFoundError ? (
				<ErrorDisplay
					title="Not Found!"
					messages={[
						"Sorry, we can't find the page you're looking for. It may have been moved or deleted.",
						<>
							You can try retyping the URL, or return to your{' '}
							<Link to={PATH_HOME}>Elemeno Home page</Link> where you can search or view your team card
							for suggested resources.
						</>,
					]}
				/>
			) : (
				<ErrorDisplay messages={[tempErrorMessage || error?.message]} />
			)}
		</Flex>
	);
};

export const ErrorDisplay: React.FC<ErrorDisplayProps> = ({ title, messages }) => {
	const errorTitle = title || 'Something went wrong...';
	const errorMessages = messages ? toArray(messages) : undefined;
	const errorMessage = errorMessages?.[0] || 'No viable error message';

	const { siteId } = useSiteContext();

	return (
		<Card my={4} title={errorTitle}>
			{errorMessages?.filter(Boolean).map((message, idx) => (
				<Text key={'err_' + idx} isParagraph mb={2}>
					{message}
				</Text>
			))}

			<Text isParagraph>
				Please{' '}
				<Link href={`mailto:support@elemenohealth.com?subject=Error on ${siteId}&body=${errorMessage}`}>
					contact support
				</Link>{' '}
				for additional assistance.
			</Text>
		</Card>
	);
};
