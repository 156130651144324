import { forwardRef } from 'react';
import { Center as ChakraCenter, Square, Circle } from '@chakra-ui/react';

import { CenterProps, CenterDefaultProps } from './Center.types';

// TODO: Add isHorizontal for horizontal only centering
// TODO: Add isVertical for vertical only centering
const Center = forwardRef<HTMLDivElement, CenterProps>(({ ...props }, ref) => {
	return <ChakraCenter ref={ref} {...props} />;
});

Center.displayName = 'Center';
Center.defaultProps = CenterDefaultProps;

const CenterNotation = Object.assign(Center, { Square, Circle });
export { CenterNotation as Center };
