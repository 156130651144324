// TODO: more error processing to create human-readable message?
//       check for known error messages?
// eslint-disable-next-line sonarjs/cognitive-complexity
export function errorToString(e: unknown): string {
	if (e) {
		if (typeof e === 'string') {
			return e;
		} else if (e instanceof Error) {
			return e.message;
		} else if (typeof e === 'object' && typeof e.toString === 'function' && e.toString()) {
			return e.toString();
		} else {
			const anyE = e as any;
			if (typeof anyE.message === 'string') {
				return anyE.message + (anyE.code ? ` (${anyE.code})` : '');
			} else {
				return String(e);
			}
		}
	}
	return 'Unexpected error.';
}
