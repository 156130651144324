import { ChangeEventHandler, FocusEventHandler } from 'react';
import { RadioProps as ChakraRadioProps } from '@chakra-ui/react';
import { ColorScheme, SystemProps } from '@elemeno/ui';

export interface RadioProps extends Omit<ChakraRadioProps, 'defaultChecked'> {
	id?: string;
	label?: string;
	/** A sub label that appears below the component */
	hint?: string;
	subHint?: string; // a smaller italic line under the hint
	/** If present, it will replace the hint and apply invalid styles */
	error?: string;
	isRequired?: boolean;
	isInvalid?: boolean;
	isDisabled?: boolean;
	isReadOnly?: boolean;
	isFocusable?: boolean;
	/** If `true`, the radio will be initially checked */
	startSelected?: boolean;
	size?: 'sm' | 'md' | 'lg';
	value?: string;
	colorScheme?: ColorScheme;
	spacing?: SystemProps['marginLeft'];
	inputProps?: any;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	onFocus?: FocusEventHandler<HTMLInputElement>;
	onBlur?: FocusEventHandler<HTMLInputElement>;
}

export const RadioDefaultProps: RadioProps = {
	label: '',
	hint: '',
	subHint: '',
	error: '',
	isRequired: false,
	isDisabled: false,
	isInvalid: false,
	isReadOnly: false,
	isFocusable: true,
	startSelected: false,
	size: 'md',
	colorScheme: 'elemenoMediumBlue',
	spacing: '0.5rem',
};
