import { forwardRef } from 'react';

import { FormControl as ChakraFormControl, FormLabel, FormErrorMessage, FormHelperText } from '@chakra-ui/react';
import { Flex } from '@elemeno/ui';

import { FormControlProps, FormControlDefaultProps } from './FormControl.types';

const FormControl = forwardRef<HTMLDivElement, FormControlProps>(
	// eslint-disable-next-line sonarjs/cognitive-complexity
	({ children, label, labelComponent, subLabel, labelProps, hint, error, caption, ...props }, ref) => {
		return (
			<ChakraFormControl ref={ref} isInvalid={Boolean(error)} {...props}>
				{(label || labelComponent) && (
					<>
						<FormLabel
							mb={subLabel ? 0 : undefined}
							lineHeight="1.2em" // limit line height for input labels
							{...labelProps}
							style={props.isReadOnly ? { opacity: 0.9 } : undefined}
						>
							{label || labelComponent}
						</FormLabel>
						{subLabel ? (
							<FormLabel
								fontStyle="italic"
								fontSize="0.95em"
								lineHeight="1.2em"
								mt={1}
								mb={2}
								{...labelProps}
								style={props.isReadOnly ? { opacity: 0.9 } : undefined}
								requiredIndicator={<></>} // avoid required indicator on sublabel
							>
								{subLabel}
							</FormLabel>
						) : null}
					</>
				)}

				{children}
				<Flex.H justifyContent={error || hint ? 'space-between' : 'flex-end'}>
					{error ? (
						<FormErrorMessage>{error}</FormErrorMessage>
					) : hint ? (
						<FormHelperText>{hint}</FormHelperText>
					) : null}
					{caption ? <FormHelperText>{caption}</FormHelperText> : null}
				</Flex.H>
			</ChakraFormControl>
		);
	},
);

FormControl.displayName = 'FormControl';
FormControl.defaultProps = FormControlDefaultProps;

export { FormControl };
