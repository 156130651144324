import { forwardRef } from 'react';

import { Textarea as ChakraTextarea } from '@chakra-ui/react';
import { FormControl, FormControlProps, SystemProps, popFormControlProps } from '@elemeno/ui';

import { TextareaProps, TextareaDefaultProps } from './Textarea.types';

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(({ caption, ...props }, ref) => {
	const { poppedProps, otherProps } = popFormControlProps(props);

	return (
		<FormControl
			caption={caption || props.maxLength ? `${(props.value as string)?.length}/${props.maxLength}` : undefined}
			{...(poppedProps as FormControlProps)}
		>
			<ChakraTextarea ref={ref} {...TextareaStyles} {...otherProps} />
		</FormControl>
	);
});

const TextareaStyles: SystemProps = {
	borderColor: 'gray.300',
	background: '#FFF',
};

Textarea.displayName = 'Textarea';
Textarea.defaultProps = TextareaDefaultProps;

export { Textarea };
