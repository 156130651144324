import { forwardRef } from 'react';
import { Badge as ChakraBadge } from '@chakra-ui/react';
import { getChakraColorScheme } from '@elemeno/ui';

import { BadgeProps, BadgeDefaultProps } from './Badge.types';

const Badge = forwardRef<HTMLSpanElement, BadgeProps>(({ colorScheme, ...props }, ref) => {
	return <ChakraBadge ref={ref} colorScheme={getChakraColorScheme(colorScheme)} {...props} />;
});

Badge.displayName = 'Badge';
Badge.defaultProps = BadgeDefaultProps;

export { Badge };
