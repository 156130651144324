import { themeColors } from '../themes';

export type ElemenoColorScheme = keyof typeof themeColors;
export type BaseColorScheme =
	| 'white'
	| 'black'
	| 'gray'
	| 'linkedin'
	| 'facebook'
	| 'messenger'
	| 'whatsapp'
	| 'twitter'
	| 'telegram';

export type ColorScheme = ElemenoColorScheme | BaseColorScheme;

export const getChakraColorScheme = (colorScheme?: string): string => {
	if (colorScheme) {
		if (['white', 'black'].includes(colorScheme)) {
			return colorScheme + 'Alpha';
		}
		return colorScheme;
	}
	return 'red';
};
