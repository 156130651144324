import { isOffline, isTest, STAGE } from 'helpers/constants';
import { MINUTES_TO_MS } from 'helpers/dates';
import logger from './logger';
import { ShareDataBetweenTabs } from './ShareDataBetweenTabs';

const doEncoding = !(isOffline || isTest);

// ***************************************************
// ** Check for localStorage, and use memory if not available
// ***************************************************

function isLocalStorageAvailable() {
	if (typeof localStorage !== 'undefined') {
		const TEST_KEY = 'ls_enabled_test';
		const TEST_VALUE = 'is_enabled';
		try {
			localStorage.setItem(TEST_KEY, TEST_VALUE);
			if (localStorage.getItem(TEST_KEY) === TEST_VALUE) {
				localStorage.removeItem(TEST_KEY);
				// localStorage is enabled
				return true;
			} // else localStorage is disabled
		} catch (e) {
			// localStorage is disabled
		}
	} // else  localStorage is not available

	// TODO: log error in Sentry? or Segment?

	return false;
}

// if no localStorage, use this localStorage-compliant object to stick in memory
class MockLocalStorage implements Storage {
	_lookup: Record<string, any> = {};
	length = 0;

	key(i: number): any {
		return Object.keys(this._lookup)[i];
	}

	getItem(key: string): any {
		return this._lookup[key];
	}

	setItem(key: string, value: any): void {
		this._lookup[key] = value;
		this.length = Object.keys(this._lookup).length;
	}

	removeItem(key: string): void {
		delete this._lookup[key];
		this.length = Object.keys(this._lookup).length;
	}

	clear(): void {
		for (const key in this._lookup) {
			delete this._lookup[key];
		}
		this.length = 0;
	}
}

const myLocalStorage = isLocalStorageAvailable() ? localStorage : new MockLocalStorage();
const mySessionStorage = isLocalStorageAvailable() ? sessionStorage : new MockLocalStorage();

/**
 * encode a string to obfuscate contents
 * @param str JSON string to encode
 * @returns string
 */
const encodeValue = (str) => {
	return window.btoa(encodeURIComponent(str));
};

/**
 * decode an obfuscated string
 * @param str JSON string to encode
 * @returns string
 */
const decodeValue = (encodedValue) => {
	try {
		return decodeURIComponent(window.atob(encodedValue));
	} catch (ex) {
		logger.error('unable to decode uri-based localstore value', ex, encodedValue);
	}

	// if the first step failed, likely a pre-uri-encoding value that fails URI decoding
	try {
		return window.atob(encodedValue);
	} catch (ex) {
		logger.error('unable to decode localstore value', ex);
	}
};

interface LocalStoreItemProps {
	durationMinutes?: number;
	persist?: boolean;
	session?: boolean;
	readableKey?: string;
}

export interface LocalStoreItem {
	mode: 'local' | 'session'; // local or session storage
	durationMinutes?: number; // number of minutes to retain data for
	persist?: boolean; // flag to persist value when clearing local data (i.e. non-user specific)

	setStorageMode: (mode: LocalStoreItem['mode']) => void; // set storage mode
	// props to be automatically applied to config
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	get: () => any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	pop: () => any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	set: (value: any) => void;
	inc: () => void;
	clear: () => void;
	_setKey: (key: string) => void; // used for setting generated key
	encodedKey: string;
	readableKey: string;
}

/**
 * Compose a "local storage" item with appropriate configuration values
 * @param durationMinutes Limit storage to a specified time duration (e.g. they recently redirected to SSO, so don't auto-redirect for a little bit)
 * @param persist Maintain value across sessions (e.g. remember they recently tried SSO even if they log out)
 * @param session Use session storage, note: takes precedent over persist (store a filter so they can return to the page and see same filter, but reset to default for next time)
 * @param encodedKey Programmatically generated storage key (passed to constructor for custom storage)
 */
class LSItem implements LocalStoreItem {
	mode: LocalStoreItem['mode'] = 'local';
	persist = false;
	durationMinutes = 0;
	readableKey = ''; // readable key for logging
	encodedKey = ''; // encoded key used for storage
	storage = new MockLocalStorage() as Storage; // for the typescript
	shareDataBetweenTabs?: ShareDataBetweenTabs;

	constructor({
		durationMinutes = undefined,
		persist = false,
		session = false,
		readableKey = undefined,
	}: LocalStoreItemProps = {}) {
		this.persist = persist;

		if (durationMinutes) {
			this.durationMinutes = durationMinutes;
		}
		if (readableKey) {
			this.encodedKey = encodeKey(readableKey);
		}

		this.setStorageMode(session ? 'session' : 'local');
	}

	private checkSessionSharedData() {
		this.shareDataBetweenTabs?.close();
		if (this.mode === 'session' && this.encodedKey) {
			this.shareDataBetweenTabs = new ShareDataBetweenTabs(
				this.encodedKey,
				() => this.get(),
				(data) => this._set(data),
			);
		}
	}

	setStorageMode(mode: LocalStoreItem['mode']) {
		this.mode = mode;
		this.storage = this.mode === 'session' ? mySessionStorage : myLocalStorage;
		this.checkSessionSharedData();
	}

	_setKey(readableKey) {
		this.readableKey = readableKey;
		this.encodedKey = encodeKey(readableKey);
		this.checkSessionSharedData();
	}

	/**
	 * Internal LocalStore Setter, puts value into localStorage (or deletes, if empty), internal use only.
	 * @param {*} value The value to be (JSON.stringified) and stored.
	 */
	_set(value) {
		if (value === undefined || value === null) {
			// clear both to be safe
			mySessionStorage.removeItem(this.encodedKey);
			myLocalStorage.removeItem(this.encodedKey);
		} else {
			const data = { value, expiresOn: 0, _objType: true };
			if (this.durationMinutes) {
				data.expiresOn = Date.now() + this.durationMinutes * MINUTES_TO_MS;
			}
			const jsonString = JSON.stringify(data);
			this.storage.setItem(this.encodedKey, doEncoding ? encodeValue(jsonString) : jsonString);
			// to be safe, let's make sure we're also clearing out the other storage, just in case
			(this.mode === 'session' ? myLocalStorage : mySessionStorage).removeItem(this.encodedKey);
		}
	}

	set(value) {
		this._set(value);
		if (this.mode === 'session') {
			this.shareDataBetweenTabs?.send(true);
		}
	}

	clear() {
		this.set(null);
	}

	/**
	 * Internal LocalStore getter, internal use only.
	 */
	get() {
		try {
			const jsonValue =
				// check current storage mode first
				this.storage.getItem(this.encodedKey) ||
				// backup with alternate storage mode (in case of switching modes)
				(this.mode === 'session' ? myLocalStorage : mySessionStorage).getItem(this.encodedKey);

			if (!jsonValue) {
				return undefined;
			}

			const actualJson = doEncoding ? decodeValue(jsonValue) : jsonValue;
			if (!actualJson) {
				return undefined;
			}

			const data = JSON.parse(actualJson as string);
			if (data._objType) {
				const { value, expiresOn } = data;
				if (expiresOn && expiresOn < Date.now) {
					this.clear();
					return;
				}
				return value;
			} else {
				// if old style (can probably remove this)
				return data;
			}
		} catch (ex) {
			this.clear();
			logger.error(`LocalStore: JSON parsing error (${this.readableKey})`, ex);
		}
		return undefined;
	}

	pop() {
		const value = this.get();
		this.clear();
		return value;
	}

	inc() {
		const value = this.get();
		if (!value) {
			this.set(1);
		} else if (isNaN(value)) {
			logger.warn(
				`attempting to increment a non-number, resetting to 1  (${this.readableKey})`,
				JSON.stringify(value),
			);
			this.set(1);
		} else {
			this.set(value + 1);
		}
	}
}

// ***************************************************
// ** Construct LocalStore dictionary with utility methods
// ***************************************************

/**
 * LocalStore Key Schema
 * By using a dictionary of keys, we avoid any potential name collisions.
 * TODO: session vs. user-persisted vs. cross-user-persisted ?
 * TODO: value types/typing?
 */
// prettier-ignore
const LocalStore : Record<string, LocalStoreItem> = {
  Token                : new LSItem({                                        }), // API (magic link) token
  TokenSSO             : new LSItem({                                        }), // SSO (cognito) token
  TokenCredentials     : new LSItem({                                        }), // Credentials (cognito) token
  CachedPath           : new LSItem({ durationMinutes: 5,                    }), // Cache path during login
  TempSsoError         : new LSItem({ durationMinutes: 1,                    }), // Temporarily store error when redirecting for SSO-display (when router state not available)
  TempError            : new LSItem({ durationMinutes: 1,                    }), // Temporarily store error when redirecting (when router state not available)
  TempEmail            : new LSItem({ durationMinutes: 1,                    }), // Temporarily store email when redirecting (when router state not available)
  SsoKeepMeLoggedIn    : new LSItem({                                        }), // Store "keep me logged in" when redirecting to sso
  SsoRecentAttempt     : new LSItem({ durationMinutes: 1                     }),
  SsoRecentLogins      : new LSItem({ durationMinutes: 2,      persist: true }), // TODO: what for?
  SsoAdminRecentLogin  : new LSItem({ durationMinutes: 60 * 8, persist: true }), // TODO: longer??
  ManageTableSort      : new LSItem({                          session: true }), // Manage Table Sort
  ManageSearch         : new LSItem({                          session: true }), // Manage Search
  SuggestedResourceTeam: new LSItem({ durationMinutes: 60 * 8,               }),
  FilterTeam           : new LSItem({ durationMinutes: 60 * 8,               }),
  FilterTime           : new LSItem({ durationMinutes: 60 * 8,               }),
  FilterTimeRange      : new LSItem({ durationMinutes: 60 * 8,               }),
  RecentVersionRefresh : new LSItem({ durationMinutes: 60 * 1, persist: true }), // don't refresh within an hour window to avoid a looping refresh bug
  // SsoPauseRedirect     : new LSItem({ durationMinutes: 1, persist: true      }),
  // SsoDestination       : new LSItem({ durationMinutes: 2, persist: true      }), // TODO: replaced by cachedPath?
  // SsoStartSessionError : new LSItem({ durationMinutes: 1                     }), // TODO: replaced by tempError?
};

// Extract key names for keys that should live through logout (for use later).
const PERSISTED_KEYS = Object.keys(LocalStore).filter((readableKey: string) => !!LocalStore[readableKey].persist);

const _baseLookups: {
	Keys: Record<string, string>;
	EncodedKeys: Record<string, string>;
} = {
	Keys: {},
	EncodedKeys: {},
};

function encodeKey(readableKey: string) {
	const keyString = 'el.' + STAGE + '.' + readableKey;
	return doEncoding ? window.btoa(encodeURIComponent(keyString)) : keyString;
}

/**
 * Builds key maps / lists to use in handling validation, setting, getting.
 * usage:
 * LocalStore.Key.get()
 * LocalStore[Keys.Key].get()
 * LocalStore[EncodedKeys.EncodedKey].get()
 */
export const { Keys, EncodedKeys } = Object.entries(LocalStore).reduce((acc, [readableKey, keyConfig]) => {
	// TODO: user specific?
	keyConfig._setKey(readableKey);
	acc.Keys[readableKey] = keyConfig.encodedKey;
	acc.EncodedKeys[keyConfig.encodedKey] = readableKey;
	return acc;
}, _baseLookups);

// Added mappings for encoded keys.
// i.e. for calling as LocalStore[Keys.SsoToken].get() or similar
Object.entries(EncodedKeys).forEach(([encodedKey, readableKey]) => {
	LocalStore[encodedKey] = LocalStore[readableKey];
});

/**
 * Determine if key (either readable or encoded) passed in is registered.
 * @param {*} key
 */
export function validKey(key: string): boolean {
	return !!LocalStore[key];
}

/**
 * Loop through keys and delete all.
 * Note: will not clear out keys that have been removed from list.
 */
export function flushLocalStore() {
	logger.debug('flushing local store');
	const toRestore = PERSISTED_KEYS.map((readableKey) => ({ readableKey, value: LocalStore[readableKey].get() }));
	myLocalStorage.clear(); // Delete all
	mySessionStorage.clear(); // Delete all (no restore of session values)
	toRestore.forEach(({ readableKey, value }) => {
		if (value !== undefined) {
			LocalStore[readableKey].set(value);
		}
	});
}

// ***************************************************
// ** Dangerous accessors - for non-enumerated keys
// ** This gives access to localstorage for dynamic keys,
// ** but this is dangerous as it doesn't protect against
// ** key collisions
// ***************************************************

/**
 * Wrapper method for creating a local store accessor object.
 * Will be cleared on logout
 * @param key Carefully constructed local cache key
 * @param session True for session store, false for persistent local store
 * @returns {get, set, pop, clear}
 */
export function CustomLocalStore(key: string, session = false) {
	return new LSItem({ readableKey: (session ? 'SESSION_' : 'CUSTOM_') + key, session });
}

/**
 * dangerousSet: Function to set custom item with custom key to local storage.
 * @param key string
 * @param value any
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const dangerousSet = (key: string, value: any, session = false): void => {
	CustomLocalStore(key, session).set(value);
};

/**
 * dangerousGet: Function to get (and remove) item with custom key from local storage.
 * @param key string
 * @returns value, if any
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dangerousGet = (key: string, session = false): any => {
	return CustomLocalStore(key, session).get();
};

/**
 * dangerousPop: Function to get (and remove) item with custom key from local storage.
 * @param key string
 * @returns value, if any
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dangerousPop = (key: string, session = false): any => {
	return CustomLocalStore(key, session).pop();
};

/**
 * dangerousClear: Function to get (and remove) item with custom key from local storage.
 * @param key string
 * @returns value, if any
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dangerousClear = (key: string, session = false): any => {
	CustomLocalStore(key, session).clear();
};

export default LocalStore;
