import { BoxProps } from '@elemeno/ui';

export interface CodeProps extends BoxProps {
	/** The code on which syntax highlighting will be applied */
	code: string;
	language?: 'typescript' | 'javascript' | 'jsx' | 'html' | 'text';
	theme?: 'light' | 'dark' | 'warm' | 'github' | 'vscode';
	onCopy?: () => void;
}

export const CodeDefaultProps: CodeProps = {
	language: 'jsx',
	theme: 'warm',
	code: '',
};
