import { HideProps as ChakraHideProps } from '@chakra-ui/react';
import { Breakpoints } from '@elemeno/ui';

export interface HideProps extends Omit<ChakraHideProps, 'above' | 'below'> {
	/** Will hide children from this breakpoint and above */
	above?: Breakpoints;
	/** Will hide children from this breakpoint and below */
	below?: Breakpoints;
	/** Use this if you need to hide on a custom breakpoint */
	breakpoint?: string;
}

export const HideDefaultProps: HideProps = {};
