import { isTest } from 'helpers/constants';

interface LoggerInterface {
	getLogLevel: () => void;
	setLogLevel: (num?: number) => void;
	resetLogLevel: () => void;
}

declare global {
	interface Window {
		LOGGER: LoggerInterface;
	}
}

const isDeveloping = !import.meta.env.VITE_PORTAL_STAGE;

// If not logging to console, add a breadcrumb for the error logger
const BreadcrumbLoggers = {};

let breadcrumb;

export function setBreadcrumb(b) {
	breadcrumb = b;
}

function makeBreadcrumbLogger(level) {
	if (!BreadcrumbLoggers[level]) {
		BreadcrumbLoggers[level] = function (...args) {
			const first = args[0];
			breadcrumb?.({
				message: first && args.length === 1 && typeof first === 'string' ? first : JSON.stringify(args),
				level,
			});
		};
	}

	return BreadcrumbLoggers[level];
}

// ********************************************
// ** Simple console logging wrapper
// ** - accomodate logging levels
// ** - hides cruft when outside dev environment (while able to show if needed)
// **
// ** To use:
// **   import logger from 'lib/logger';
// **   logger.warn('hi mom');
// ** To control log level:
// **    window.LOGGER.setLogLevel(0);
// ********************************************

// prettier-ignore
export const LOG_LEVELS = {
	ALL    : 0,
	VERBOSE: 1,
	TRACE  : 2,
	DEBUG  : 3,
	INFO   : 4,
	WARN   : 5,
	ERROR  : 6,
	FATAL  : 7,
	OFF    : 8,
};

// prettier-ignore
const LOG_METHODS = [
	{ method: 'log'    , level: LOG_LEVELS.VERBOSE, log: true                     }, // uses console.log
	{ method: 'verbose', level: LOG_LEVELS.VERBOSE, log: true                     }, // uses console.log
	{ method: 'trace'  , level: LOG_LEVELS.TRACE                                  },
	{ method: 'debug'  , level: LOG_LEVELS.DEBUG  , log: true                     }, // uses console.log
	{ method: 'info'   , level: LOG_LEVELS.INFO   , breadcrumbLogLevel: 'info'    }, // send breadcrumb to Sentry if hidden
	{ method: 'warn'   , level: LOG_LEVELS.WARN   , breadcrumbLogLevel: 'warning' }, // send breadcrumb to Sentry if hidden
	{ method: 'error'  , level: LOG_LEVELS.ERROR  , breadcrumbLogLevel: 'error'   }, // send breadcrumb to Sentry if hidden
	{ method: 'time'   , level: LOG_LEVELS.TRACE                                  },
	{ method: 'timeEnd', level: LOG_LEVELS.TRACE                                  },
];

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

// ********************************************
// **  cache log level locally, but also store in localStorage
// ********************************************

let currentLogLevel: number = isTest ? LOG_LEVELS.OFF : isDeveloping ? LOG_LEVELS.VERBOSE : LOG_LEVELS.ERROR; // may be overwritten by cached setting

const LOG_LEVEL_KEY = 'el.logger.log_level';

function restoreLogLevel() {
	try {
		const storedLevel = window.localStorage.getItem(LOG_LEVEL_KEY);
		if (storedLevel) {
			const parsedLevel = JSON.parse(storedLevel);
			if (!isNaN(parsedLevel) && Object.values(LOG_LEVELS).includes(parsedLevel)) {
				currentLogLevel = parsedLevel;
				return currentLogLevel;
			}
		}
	} catch (ex) {
		// fail silently
	}
	return currentLogLevel;
}

function setLogLevel(num: number = LOG_LEVELS.ERROR) {
	currentLogLevel = num;
	window.localStorage.setItem(LOG_LEVEL_KEY, JSON.stringify(num || 0));
	return num;
}

// get current log level from store or based on where we're running
const resolveLogLevel = () => {
	restoreLogLevel();
	if (currentLogLevel === undefined || currentLogLevel === null) {
		if (window.location.href.indexOf('localhost') > -1 || import.meta.env.NODE_ENV === 'test') {
			currentLogLevel = LOG_LEVELS.VERBOSE;
		} else {
			currentLogLevel = LOG_LEVELS.ERROR;
		}
	}
};

const getCurrentLevelName = () => {
	// allow for num = string matching
	// eslint-disable-next-line eqeqeq
	return Object.keys(LOG_LEVELS).find((key) => LOG_LEVELS[key] == currentLogLevel);
};

// ********************************************
// **  setup logging methods
// ********************************************

// adapted from https://www.npmjs.com/package/loglevel
function bindToConsole(methodName) {
	if (!console) {
		return noop;
	}
	// eslint-disable-next-line
	const method = console[methodName];
	if (typeof method.bind === 'function') {
		return method.bind(console);
	} else {
		try {
			return Function.prototype.bind.call(method, console);
		} catch (e) {
			// Missing bind shim or IE8 + Modernizr, fallback to wrapping
			return function (...args: any) {
				// NOTE: 'arguments' replaced with '...args' due to typescript complaining. is this correct way of doing it?  @smundro
				return Function.prototype.apply.apply(method, [console, args]);
			};
		}
	}
}

// based on current log level, set up logging methods
const resolveLogMethods = (logger) => {
	for (let i = 0; i < LOG_METHODS.length; i++) {
		const methodName = LOG_METHODS[i].method;
		if (LOG_METHODS[i].level < currentLogLevel) {
			// if we're not logging to console (which Sentry would capture), do log to Sentry
			const { breadcrumbLogLevel } = LOG_METHODS[i];
			logger[methodName] =
				currentLogLevel !== LOG_LEVELS.OFF && breadcrumbLogLevel
					? makeBreadcrumbLogger(breadcrumbLogLevel)
					: noop;
		} else {
			logger[methodName] = bindToConsole(LOG_METHODS[i].log ? 'log' : methodName);
		}
	}
};

// ********************************************
// **  setup logging methods
// ********************************************
interface ILogger {
	log: (...args) => void;
	verbose: (...args) => void;
	trace: (...args) => void;
	debug: (...args) => void;
	info: (...args) => void;
	warn: (...args) => void;
	error: (...args) => void;
	time: (...args) => void;
	timeEnd: (...args) => void;
	json: (...args) => void;
	color: (...args) => void;
	blue: (...args) => void;
	red: (...args) => void;
}

const logger = (() => {
	resolveLogLevel();

	// avoid if possible: default call is non-ideal as we can't bind to console and then change later
	const _logger: ILogger = {
		log: noop,
		verbose: noop,
		trace: noop,
		debug: noop,
		info: noop,
		warn: noop,
		error: noop,
		time: noop,
		timeEnd: noop,
		json: noop,
		color: noop,
		blue: noop,
		red: noop,
	};

	resolveLogMethods(_logger);

	if (!isTest) {
		_logger.info(`Elemeno Logger Initialized, Log Level: ${getCurrentLevelName()} (${currentLogLevel})`);
	}

	// console accessible methods for exposing log level setting
	window.LOGGER = {
		getLogLevel: () => currentLogLevel,
		setLogLevel: (num) => {
			setLogLevel(num);
			resolveLogMethods(_logger);
		},
		resetLogLevel: () => setLogLevel(),
	};

	return _logger;
})();

export default logger;

// ********************************************
// ** a couple convenience dev logging methods, but on console to ensure that linter will complain
// ********************************************

declare global {
	interface Console {
		json: (...args) => void;
		color: (...args) => void;
		blue: (...args) => void;
		red: (...args) => void;
	}
}

/* eslint-disable no-console */
logger.json = (...args) => logger.info(...args.map((arg) => (typeof arg === 'object' ? JSON.stringify(arg) : arg)));

// convenience for coloring log statements
const highlight = (color, str, ...args) => ['%c' + str, `background: ${color || 'yellow'}`, ...args];

logger.color = (str, ...args) => logger.info(...highlight.apply(null, ['lightgreen', str, ...args]));
logger.blue = (str, ...args) => logger.info(...highlight.apply(null, ['lightblue', str, ...args]));
logger.red = (str, ...args) => logger.info(...highlight.apply(null, ['pink', str, ...args]));
/* eslint-enable no-console */
