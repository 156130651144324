import { FlexProps as ChakraFlexProps } from '@chakra-ui/react';
import { SystemProps } from '@elemeno/ui';

export interface FlexProps extends ChakraFlexProps {
	align?: SystemProps['alignItems'];
	basis?: SystemProps['flexBasis'];
	direction?: SystemProps['flexDirection'];
	grow?: SystemProps['flexGrow'];
	justify?: SystemProps['justifyContent'];
	shrink?: SystemProps['flexShrink'];
	wrap?: SystemProps['flexWrap'];
}

export const FlexDefaultProps: FlexProps = {
	align: 'normal',
	basis: 'auto',
	direction: 'column',
	grow: 0,
	justify: 'normal',
	shrink: 1,
	wrap: 'nowrap',
};
