import { forwardRef } from 'react';
import { Avatar as ChakraAvatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react';

import { AvatarProps, AvatarDefaultProps } from './Avatar.types';

const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
	({ children, badge, isFallbackDisabled, hasBorder, ...props }, ref) => {
		return (
			<ChakraAvatar ref={ref} ignoreFallback={isFallbackDisabled} showBorder={hasBorder} {...props}>
				{children}
				{badge && <AvatarBadge {...badge} />}
			</ChakraAvatar>
		);
	},
);

Avatar.displayName = 'Avatar';
Avatar.defaultProps = AvatarDefaultProps;

const AvatarNotation = Object.assign(Avatar, { Group: AvatarGroup });
export { AvatarNotation as Avatar };
