import { StyleProps, TableProps as ChakraTableProps } from '@chakra-ui/react';
import { ColumnDef, PaginationState } from '@tanstack/react-table';

type Props = StyleProps & {
	_last?: StyleProps;
	_first?: StyleProps;
};

interface TableStyles {
	table?: Props;
	thead?: Props & {
		tr?: Props;
		th?: Props;
	};
	tbody?: Props & {
		tr?: Props;
		td?: Props;
	};
}

export interface TableProps extends ChakraTableProps, Partial<PaginationState> {
	data: object[];
	columns: ColumnDef<object>[];
	tableStyles?: TableStyles;
	page?: number;
	onPageChange?: (page: number) => void;
}

export const TableDefaultProps: TableProps = {
	size: 'sm',
	variant: 'simple',
	data: [],
	columns: [],
	pageSize: 10,
};
