import { forwardRef } from 'react';
import { Text as ChakraText, Highlight, Tooltip } from '@chakra-ui/react';

import { TextProps, TextDefaultProps } from './Text.types';

// TODO: Replace highlight prop with query + highlightVariant
const Text = forwardRef<HTMLParagraphElement | HTMLDivElement, TextProps>(
	({ children, highlight, tooltip, isTooltipDisabled, isParagraph, lineClamp = null, as, ...props }, ref) => {
		const text = (
			<ChakraText
				ref={ref}
				as={as || isParagraph ? 'p' : 'div'}
				{...props}
				style={{
					...(lineClamp
						? {
								display: '-webkit-box',
								WebkitLineClamp: lineClamp,
								WebkitBoxOrient: 'vertical',
								overflow: 'hidden',
						  }
						: {}),
				}}
			>
				{highlight ? <Highlight {...highlight}>{`${children}`}</Highlight> : children}
			</ChakraText>
		);

		if (tooltip) {
			return (
				<Tooltip label={tooltip} isDisabled={isTooltipDisabled}>
					{text}
				</Tooltip>
			);
		}

		return text;
	},
);

Text.displayName = 'Text';
Text.defaultProps = TextDefaultProps;

export { Text };
