import { forwardRef, useMemo, Children } from 'react';
import { Tabs as ChakraTabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { getChakraColorScheme } from '@elemeno/ui';

import { TabsProps, TabsDefaultProps } from './Tabs.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function composeScheme(selectedColor?: string, designScheme?: string): Record<string, any> {
	if (designScheme === 'manage') {
		return {
			_selected: { color: selectedColor, borderColor: selectedColor },
		};
	} else {
		return {
			fontSize: 'md',
			fontWeight: 'bold',
			border: '1px solid',
			borderColor: 'gray.300',
			roundedTop: 'xl',
			_hover: {
				backgroundColor: 'gray.100',
			},
			_selected: {
				color: 'white',
				borderColor: selectedColor,
				backgroundColor: selectedColor,
			},
		};
	}
}

const Tabs = forwardRef<HTMLDivElement, TabsProps>(
	({ children, tabs, onChange, colorScheme, selectedColor, designScheme, ...props }, ref) => {
		const tabList = useMemo(
			() =>
				tabs?.map((tab) => (
					<Tab key={tab} {...composeScheme(selectedColor, designScheme)}>
						{tab}
					</Tab>
				)) || null,
			[tabs, selectedColor, designScheme],
		);

		const panels = useMemo(
			() =>
				Children.toArray(children).map((child, index) => (
					<TabPanel key={index}>{child as React.ReactNode}</TabPanel>
				)) || null,
			[children],
		);

		return (
			<ChakraTabs
				ref={ref}
				width="full"
				colorScheme={getChakraColorScheme(colorScheme)}
				onChange={(newIndex) => onChange && onChange(tabs[newIndex], newIndex)}
				{...props}
			>
				<TabList>{tabList}</TabList>
				{children && <TabPanels>{panels}</TabPanels>}
			</ChakraTabs>
		);
	},
);

Tabs.displayName = 'Tabs';
Tabs.defaultProps = TabsDefaultProps;

export { Tabs };
