import React, { useCallback, useEffect, useRef } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import {
	createBrowserRouter,
	useLocation,
	useNavigationType,
	createRoutesFromChildren,
	matchRoutes,
	Link,
	useRouteError,
	isRouteErrorResponse,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { SeverityLevel } from '@sentry/react';
// import { ReportDialogOptions, SeverityLevel } from '@sentry/react';
// DEPRECATED
// import { addExtensionMethods } from '@sentry/tracing';

// import { HiArrowLeft } from 'react-icons/hi';
import { Card, Flex } from '@elemeno/ui';

import { isOffline, STAGE, SENTRY_DSN, APP_VERSION, isProd, PATH_HOME } from 'helpers/constants';
import { UserProfileType } from 'store/reactiveVars';
import logger, { setBreadcrumb } from 'utils/logger';
import { ErrorDisplay } from 'components/ErrorMessage';
import { toFullName } from './names';

const OFFLINE_SANITY_TESTING = false;
const SENTRY_DISABLED = isOffline && !OFFLINE_SANITY_TESTING;

let lastFeedbackRequest = 0;
const feedbackThrottle = 1000 * 60 * 10; // 10 minutes
let currentFeedbackUser: { name?: string; email?: string } | undefined;

export function init(): void {
	logger.debug('ErrorLogger.init() called - ads');
	setBreadcrumb(breadcrumb);

	// was supposedly necessary so Sentry doesn't crash on error (because of WEbpack tree-shaking)?
	// addExtensionMethods();

	if (SENTRY_DISABLED) {
		logger.info('ErrorLogger', 'init offline');
	} else {
		const browserTracing = new Sentry.BrowserTracing({
			routingInstrumentation: Sentry.reactRouterV6Instrumentation(
				React.useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			),
		});

		const browserOptions = {
			dsn: SENTRY_DSN,
			integrations: [browserTracing],
			tracesSampleRate: 1.0,
			release: APP_VERSION.release,
			environment: STAGE,
			// allowUrls: [/.*\.elemenohealth\.com/, /.*\.lmno\.care/, /.*\.lmno\.cloud/],
			beforeSend: (event) => {
				logger.info('beforeSend', event);
				if (event.message?.includes('invalid token') || event.message?.includes('Session timed out')) {
					logger.info('beforeSend', 'invalid token error blocked!');

					// don't send invalid token errors to Sentry
					return null;
				} else if (event.exception) {
					// if we haven't prompted for error feedback recently...
					const now = Date.now();
					if (now > lastFeedbackRequest + feedbackThrottle) {
						lastFeedbackRequest = now;
						logger.info('beforeSend', 'prompting for error feedback', currentFeedbackUser);
						// Sentry.showReportDialog({
						// 	eventId: event.event_id,
						// 	user: currentFeedbackUser, // either undefined or email/name
						// } as ReportDialogOptions);
					}
				}
				return event;
			},
		} as Sentry.BrowserOptions;

		logger.info('Sentry.init() called', browserOptions);

		Sentry.init(browserOptions);
	}
}

export const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export function setErrorUser(user: UserProfileType): void {
	if (SENTRY_DISABLED) {
		logger.info('ErrorLogger', 'setUser', user);
	} else {
		currentFeedbackUser = { name: toFullName(user), email: user.email };
		Sentry.setUser({ username: user.username, email: user.email });
	}
}

export function clearErrorUser(): void {
	if (SENTRY_DISABLED) {
		logger.info('ErrorLogger', 'clearUser');
	} else {
		// no equivalent user clear method for logouts
		currentFeedbackUser = undefined;
	}
}

export function breadcrumb({ message, level }: { message?: string; level?: SeverityLevel }): void {
	if (SENTRY_DISABLED) {
		logger.info('ErrorLogger', 'breadcrumb', message, level);
	} else {
		Sentry.addBreadcrumb({
			message,
			level,
		});
	}
}

// <pre> is a block element that shouldn't be in a <p> (as wrapped by ErrorDisplay)
const preStyle = {
	display: 'block',
	unicodeBidi: 'embed',
	fontFamily: 'monospace',
	whiteSpace: 'pre-wrap',
} as React.CSSProperties;

export const ErrorFallback: React.FC<any> = ({ error, componentStack, resetError, resetErrorBoundary }) => {
	if (error) {
		logger.error('Error on page', error);
	}

	const errorMessage: string = error?.message || 'no viable error message';

	const onResetClick = useCallback(() => {
		if (resetError) {
			resetError(); // Sentry
		} else if (resetErrorBoundary) {
			resetErrorBoundary(); // react-error-boundary
		} else {
			logger.warn('ErrorBoundary: No reset error method.');
		}
	}, [resetError, resetErrorBoundary]);

	const errorLocation = useRef({
		hash: window.location.hash,
		search: window.location.search,
		pathname: window.location.pathname,
	});

	useEffect(() => {
		const handler = (): void => {
			// auto-reset on nav change, so that clicking back will clear the error state
			if (
				errorLocation.current.hash !== window.location.hash ||
				errorLocation.current.search !== window.location.search ||
				errorLocation.current.pathname !== window.location.pathname
			) {
				onResetClick();
			}
		};

		window.addEventListener('hashchange', handler);

		return () => {
			window.removeEventListener('hashchange', handler);
		};
	}, [onResetClick]);

	return (
		<Card boxShadow="lg" m={12} w="auto">
			<Flex.V gap={4}>
				{/* {errorMessage ? (
					<Flex.H as="header" mb="6.25rem">
						<button onClick={() => onResetClick()}>
							<HiArrowLeft fill="#fff" />
						</button>

						<Text>Made it to the Fallback Error</Text>
					</Flex.H>
				) : null} */}

				<ErrorDisplay
					messages={
						isProd
							? [
									'Unfortunately we have encountered an unexpected error.',
									'Elemeno has been alerted to the issue.',
									<>
										{/* note: adding spans because even a string in curly brackets was collapsing whitespace */}
										<span>Please try reloading or returning to the </span>
										<Link to={PATH_HOME} onClick={onResetClick}>
											home page
										</Link>
										<span> to continue.</span>
									</>,
							  ]
							: [
									errorMessage,
									componentStack && (
										<span style={preStyle} key="stack">
											{componentStack}
										</span>
									),
									<button key="reset" onClick={onResetClick}>
										Click to Reset
									</button>,
							  ]
					}
				/>
			</Flex.V>
		</Card>
	);
};

export const ErrorBoundary: React.FC<React.PropsWithChildren> = ({ children }) => {
	if (SENTRY_DISABLED) {
		return (
			<ReactErrorBoundary
				FallbackComponent={ErrorFallback}
				onError={(error, componentStack) => {
					logger.error('ReactErrorBoundary', error, componentStack);
				}}
				onReset={() => {
					// reset the state of your app so the error doesn't happen again
					logger.info('ReactErrorBoundary: attempt to reset state');
				}}
			>
				{children}
			</ReactErrorBoundary>
		);
	}

	return (
		<Sentry.ErrorBoundary
			fallback={<ErrorFallback />}
			// showDialog - this is triggered in beforeSend
			onError={(error) => {
				logger.error('Sentry.ErrorBoundary: ', error);
			}}
			onReset={() => {
				// reset the state of your app so the error doesn't happen again
				logger.info('Sentry.ErrorBoundary: attempt to reset state');
			}}
		>
			{children}
		</Sentry.ErrorBoundary>
	);
};

export function captureException(
	err: Error,
	{
		url, // these "tags" appear to be fully custom?
		page,
		where,
	}: { url?: string; page?: string; where?: string } = {},
): void {
	logger.error('captureException: ', err);
	const error = err instanceof Error ? err : new Error(JSON.stringify(err));
	let context;
	if (where || url || page) {
		context = { tags: {} };
		if (where) context.tags.where = where;
		if (url) context.tags.url = url;
		if (page) context.tags.page = page;
	}
	Sentry.captureException(error, context);
}

export function captureMessage(msg: string): void {
	logger.warn('captureMessage: ', msg);
	Sentry.captureMessage(msg);
}

// an error boundary for use at the react root
export function RootRouteErrorBoundary(): JSX.Element | null {
	const error = useRouteError() as Error;

	useEffect(() => {
		if (error) {
			logger.info('Error in RouteErrorBoundary', error);
			// not seeing an error sent in prod, but multiple sent in localhost dev??
			Sentry.captureException(error);
		}
	}, [error]);

	if (!error) return null;

	let errorTitle;
	let errorMessage;

	if (isRouteErrorResponse(error)) {
		errorTitle = error.statusText;
		switch (error.status) {
			case 404:
				errorMessage = `This page does't exist!</div>`;
				break;
			case 401:
				errorMessage = `You aren't authorized to see this`;
				break;
			case 503:
				errorMessage = `Looks like our API is down</div>`;
				break;
			case 418:
				errorMessage = `🫖`;
				break;
			default:
				errorMessage = error.data?.message || 'Something went wrong';
		}
	} else {
		errorTitle = error.message;
		errorMessage = error.stack;
	}

	return (
		<Card boxShadow="lg" m={12} w="auto">
			<Flex.V gap={4}>
				<ErrorDisplay
					messages={
						isProd
							? [
									'Unfortunately we have encountered an unexpected error.',
									'Elemeno has been alerted to the issue.',
									<>
										Please try{' '}
										<button key="reset" onClick={() => location.reload()}>
											reloading
										</button>
										, or returning to the <Link to={PATH_HOME}>home page</Link>
										to continue.
									</>,
							  ]
							: [
									errorTitle,
									errorMessage && (
										<span style={{ ...preStyle, fontSize: '0.8em' }} key="stack">
											{errorMessage}
										</span>
									),
									<button key="reset" onClick={() => location.reload()}>
										Click to Reload
									</button>,
							  ]
					}
				/>
			</Flex.V>
		</Card>
	);
}
