import { forwardRef } from 'react';

import { Box } from '@elemeno/ui';

import { FormProps, FormDefaultProps } from './Form.types';

const Form = forwardRef<HTMLFormElement, FormProps>(({ children, ...props }, ref) => {
	return (
		// the element will be a form
		// @ts-ignore
		<Box ref={ref} as="form" {...props}>
			{children}
		</Box>
	);
});

Form.displayName = 'Form';
Form.defaultProps = FormDefaultProps;

export { Form };
