import { makeVar } from '@apollo/client';

import { SimpleUserProps } from 'types/common';

export interface SentRecognitionModalInfoProps {
	recipients: SimpleUserProps[];
}

export const SentRecognitionModalInfo = makeVar<SentRecognitionModalInfoProps>({
	recipients: [],
});
