import { AccordionProps as ChakraAccordionProps, UseAccordionProps } from '@chakra-ui/react';

type ExpandedIndex = UseAccordionProps['index'];

export interface AccordionItemProps {
	key?: string;
	title: string;
	content: string | JSX.Element;
}

export interface AccordionProps extends Omit<ChakraAccordionProps, 'reduceMotion' | 'allowMultiple' | 'allowToggle'> {
	items?: AccordionItemProps[];
	/** If `true`, multiple accordion items can be expanded at once */
	canOpenMultiple?: boolean;
	/** If `true`, any expanded accordion item can be collapsed again */
	canToggle?: boolean;
	/** If `true`, height animation and transitions will be disabled */
	isMotionless?: boolean;
	index?: ExpandedIndex;
	defaultIndex?: ExpandedIndex;
	onChange?: (expandedIndex: ExpandedIndex) => void;
}

export const AccordionDefaultProps: AccordionProps = {
	canOpenMultiple: false,
	canToggle: true,
	isMotionless: false,
};
