import { ChangeEventHandler, FocusEventHandler } from 'react';
import { TextareaProps as ChakraTextareaProps } from '@chakra-ui/react';

export interface TextareaProps extends ChakraTextareaProps {
	id?: string;
	label?: string;
	subLabel?: string; // a smaller italic line under the label
	placeholder?: string;
	/** A sub label that appears below the component */
	hint?: string;
	caption?: string;
	/** If present, it will replace the hint and apply invalid styles */
	error?: string;
	isRequired?: boolean;
	isInvalid?: boolean;
	isDisabled?: boolean;
	isReadOnly?: boolean;
	variant?: 'outline' | 'filled' | 'flushed' | 'unstyled';
	size?: 'xs' | 'sm' | 'md' | 'lg';
	onChange?: ChangeEventHandler<HTMLTextAreaElement>;
	onFocus?: FocusEventHandler<HTMLTextAreaElement>;
	onBlur?: FocusEventHandler<HTMLTextAreaElement>;
}
export const TextareaDefaultProps: TextareaProps = {
	label: '',
	subLabel: '',
	hint: '',
	caption: '',
	error: '',
	placeholder: '',
	isRequired: false,
	isDisabled: false,
	isInvalid: false,
	isReadOnly: false,
	variant: 'outline',
	size: 'md',
};
