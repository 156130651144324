import logger from './logger';

const logError = (err) => logger.error('Error in promise', err);

/**
 * Helper function to await a promise and log any errors (and return void).
 * Mainly to avoid unhandled promise warnings, the promise should
 * be highly unlikely to throw an error, i.e. functions where async was used
 * to make it more readable, but error handling is handled in state updates .
 * @param promise promise to be handled
 * @param callback optional callback to be run on promise resolution
 */
export function handlePromise(promise?: Promise<any | void>): void {
	promise?.catch(logError);
}

/**
 * Helper function to await a promise and return a void promise (and log any errors).
 * Mainly to adjust return signature to work with event handlers expecting <void>.
 * @param promise promise to be handled
 * @param callback optional callback to be run on promise resolution
 */
export async function awaitVoid(promise?: Promise<any>, callback?: () => void): Promise<void> {
	await promise?.catch(logError).finally(() => {
		if (callback) callback();
	});
}

/**
 * useForm returns a handler function that returns a promise
 * wrap the handler function with this function to catch any errors
 * and also return void for onSubmit handlers
 * @param handler
 * @returns
 */
export function handleSubmitPromise(handler) {
	return (...args) => handler(...args).catch(logError);
}

function delay(ms = 100): Promise<void> {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

type SettledResult<R = any> = { status: 'fulfilled' | 'rejected'; value?: R; reason?: Error };

/**
 * Promise helper that maps all values to callback that returns a promise,
 * and returns all settled results (i.e. doesn't fail if any one fails).
 * @param values array of values V
 * @param callback (value: V, index: number) => Promise<R>
 * @param options { concurrency: 1 } number of concurrent promises
 * @returns
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export function mapAllSettled<V = any, R = any>(
	values: V[],
	callback: (value: V, index: number) => Promise<R>,
	{ concurrency = 1 }: { concurrency?: number } = {},
): Promise<SettledResult<R>[]> {
	if (!values || !Array.isArray(values) || !values?.length) {
		return Promise.resolve([]);
	}
	if (!callback || typeof callback !== 'function') {
		return Promise.reject(new Error('Callback is required'));
	}
	return new Promise((resolve) => {
		let index = 0;
		const results: SettledResult<R>[] = [];

		// fetch next value, run callback, and then pass to next runner
		async function run(): Promise<void> {
			// get current index and increment
			const currentIndex = index++;

			// if current index is in the range of values, run callback
			if (currentIndex < values.length) {
				try {
					const value = await callback(values[currentIndex], currentIndex);
					// if success add value to results and run next
					results[currentIndex] = { status: 'fulfilled', value };
					return run();
				} catch (ex: any) {
					results[currentIndex] = { status: 'rejected', reason: ex };
					return run();
				}
			}

			// if no more values, return resolved promise to end thread
			return Promise.resolve();
		}

		// create initial concurrent promises
		Promise.all(
			Array.from(
				{ length: concurrency }, // create N concurrent promise chains
				(_, index) => delay(index * 100).then(() => run()), // and populated with running promises after progressive delay
			),
		)
			.then(() => {
				// return results to original caller
				resolve(results);
			})
			.catch(() => {
				// not reachable as run should capture any errors, only to make typescript happy
			});
	});
}
