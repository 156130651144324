import { DrawerProps as ChakraDrawerProps } from '@chakra-ui/react';
import { ButtonProps } from '@elemeno/ui';

export interface DrawerProps
	extends Omit<
		ChakraDrawerProps,
		| 'allowPinchZoom'
		| 'autoFocus'
		| 'blockScrollOnMount'
		| 'closeOnEsc'
		| 'closeOnOverlayClick'
		| 'preserveScrollBarGap'
		| 'returnFocusOnClose'
	> {
	size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
	title?: string;
	buttons?: ButtonProps[];
	isOpen: boolean;
	/** Handle zoom/pinch gestures on mobile devices when scroll locking is enabled */
	canPinchZoom?: boolean;
	/** If `true`, the modal will close when the `Esc` key is pressed */
	canEscape?: boolean;
	/** If `true`, the modal will close when the overlay is clicked */
	canOverlayClose?: boolean;
	/** If `true`, the modal will autofocus the first enabled and interactive element within the `ModalContent` */
	startFocused?: boolean;
	/** If `true`, the modal will return focus to the element that triggered it when it closes */
	isFocusReturned?: boolean;
	/** If `true`, scrolling will be disabled on the `body` when the modal opens */
	isScrollDisabled?: boolean;
	/** If `true`, a `padding-right` will be applied to the body element that's equal to the
	 * width of the scrollbar. This can help prevent some unpleasant flickering effect and
	 * content adjustment when the modal opens
	 */
	hasScrollGap?: boolean;
	isFullHeight?: boolean;
	initialFocusRef?: ChakraDrawerProps['initialFocusRef'];
	finalFocusRef?: ChakraDrawerProps['finalFocusRef'];
	placement?: ChakraDrawerProps['placement'];
	onClose: () => void;
	onCloseComplete?: () => void;
	onEsc?: () => void;
	onOverlayClick?: () => void;
}

export const DrawerDefaultProps: DrawerProps = {
	children: '',
	size: 'sm',
	placement: 'right',
	title: '',
	isOpen: false,
	canPinchZoom: false,
	canEscape: true,
	canOverlayClose: true,
	startFocused: false,
	isFocusReturned: true,
	isScrollDisabled: true,
	isFullHeight: true,
	hasScrollGap: false,
	onClose: () => null,
};
