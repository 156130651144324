import { forwardRef } from 'react';
import { Wrap as ChakraWrap } from '@chakra-ui/react';

import { WrapProps, WrapDefaultProps } from './Wrap.types';

const Wrap = forwardRef<HTMLDivElement, WrapProps>(({ ...props }, ref) => {
	return <ChakraWrap ref={ref} shouldWrapChildren {...props} />;
});

Wrap.displayName = 'Wrap';
Wrap.defaultProps = WrapDefaultProps;

export { Wrap };
