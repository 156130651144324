import { forwardRef } from 'react';
import { Progress as ChakraProgress } from '@chakra-ui/react';
import { Box } from '@elemeno/ui';
import { popMarginProps } from '../../.elemeno/utils/popProps';
import { getChakraColorScheme } from '../../.elemeno/utils/getChakraColorScheme';

import { ProgressProps, ProgressDefaultProps } from './Progress.types';

const Progress = forwardRef<HTMLDivElement, ProgressProps>(({ colorScheme = 'blue', ...props }, ref) => {
	const { poppedProps, otherProps } = popMarginProps(props);

	return (
		<Box ref={ref} width="full" {...poppedProps}>
			<ChakraProgress colorScheme={getChakraColorScheme(colorScheme)} rounded="full" {...otherProps} />
		</Box>
	);
});

Progress.displayName = 'Progress';
Progress.defaultProps = ProgressDefaultProps;

export { Progress };
