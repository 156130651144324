import { GroupBase, Props, SelectInstance } from 'chakra-react-select';
import { SystemProps } from '@elemeno/ui';

import { FormControlProps } from '../FormControl';

export interface SelectProps<
	Option = unknown,
	IsMulti extends boolean = false,
	Group extends GroupBase<Option> = GroupBase<Option>,
> extends Props<Option, IsMulti, Group>,
		React.RefAttributes<SelectInstance<Option, IsMulti, Group>>,
		Omit<
			FormControlProps,
			'placeholder' | 'colorScheme' | 'defaultValue' | 'onFocus' | 'onBlur' | 'onChange' | 'size' | 'variant'
		> {
	menuProps?: SystemProps;

	label?: string;
	/** A sub label that appears below the component */
	hint?: string;
	caption?: string;
	/** If present, it will replace the hint and apply invalid styles */
	error?: string;
	optimizeForMobile?: boolean;
	filterOption?: (candidate: { label: string; value: string; data: any }, input: string) => boolean;
}

export const SelectDefaultProps: SelectProps = {
	label: '',
	hint: '',
	caption: '',
	error: '',
	placeholder: '',
	isMulti: false,
	isLoading: false,
	isClearable: false,
	isSearchable: false,
	isReadOnly: false,
	isRequired: false,
	isDisabled: false,
	variant: 'outline',
	size: 'md',
};
