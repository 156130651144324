import rht from 'react-hot-toast';

import { HiOutlineRefresh } from 'react-icons/hi';
import { Box, Button, Flex, Heading, Icon, Text, themeColors } from '@elemeno/ui';

const position = 'top-center';

export function toastNewVersion() {
	return rht(
		<Flex.V gap="1">
			<Heading size="sm">New Version</Heading>
			<Text>
				Your client is out of date, please{' '}
				<Button variant="link" colorScheme="white" color="white" onClick={() => window.location.reload()}>
					Reload
				</Button>
			</Text>
		</Flex.V>,
		{
			id: 'new-version',
			duration: Infinity,
			position,
			ariaProps: {
				role: 'alert',
				'aria-live': 'assertive',
			},
			icon: (
				<Box px="2">
					<Icon as={HiOutlineRefresh} boxSize={6} color="white" />
				</Box>
			),
			style: {
				color: 'white',
				backgroundColor: themeColors.elemenoGray[800],
			},
		},
	);
}

export function toast(title: string, description?: string) {
	return rht(title + (description ? '\n' + description : ''), { position });
}

export function toastError(title: string, description?: string) {
	return rht.error(title + (description ? '\n' + description : ''), { position });
}

export function toastSuccess(title: string, description?: string) {
	return rht.success(title + (description ? '\n' + description : ''), { position });
}

export function removeToast(toastId?: string) {
	return rht.remove(toastId);
}

export function openAndToast({
	url,
	message = 'If the link has not yet loaded',
	target = '_blank',
}: {
	url: string;
	target?: '_blank' | '_self';
	message?: string;
}) {
	window.open(url, target);
	rht.loading(
		<div>
			{message}{' '}
			<a rel="noreferrer" target="_blank" href={url} style={{ fontWeight: 600, color: '#4183c4' }}>
				click here
			</a>
		</div>,
		{ duration: 5000, position },
	);
}
