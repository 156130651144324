import { ChangeEvent, FocusEvent } from 'react';
import { CheckboxProps as ChakraCheckboxProps, SystemProps } from '@chakra-ui/react';

export interface CheckboxProps extends ChakraCheckboxProps {
	id?: string;
	label?: string;
	/** A sub label that appears below the component */
	hint?: string;
	subHint?: string; // a smaller italic line under the hint
	/** If present, it will replace the hint and apply invalid styles */
	error?: string;
	isRequired?: boolean;
	isDisabled?: boolean;
	isInvalid?: boolean;
	isReadOnly?: boolean;
	isIndeterminate?: boolean;
	isFocusable?: boolean;
	size?: 'sm' | 'md' | 'lg';
	value?: string | number;
	spacing?: SystemProps['marginLeft'];
	icon?: JSX.Element;
	iconColor?: string;
	iconSize?: string | number;
	inputProps?: any;
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	onFocus?: (event: FocusEvent<HTMLInputElement, Element>) => void;
	onBlur?: (event: FocusEvent<HTMLInputElement, Element>) => void;
}

export const CheckboxDefaultProps: CheckboxProps = {
	label: '',
	hint: '',
	subHint: '',
	error: '',
	isRequired: false,
	isDisabled: false,
	isInvalid: false,
	isReadOnly: false,
	isIndeterminate: false,
	isFocusable: true,
	size: 'md',
	spacing: '0.5rem',
};
