import { DocumentNode, WatchQueryFetchPolicy } from '@apollo/client';
import { print } from 'graphql';
import { MINUTES_TO_MS } from 'helpers/dates';
import { useCallback, useMemo } from 'react';

const LAST_RUN: Record<
	string,
	{
		key: string; // non-variable query key (to allow clearing all queries of a type)
		date: number; // when this query was run/cached
	}
> = {};

const MAX_AGE = 2 * MINUTES_TO_MS;

function queryToKey(QUERY: DocumentNode, variables?: Record<string, any>): string {
	return (
		print(QUERY).replace(/[\r\n]/g, ' ') +
		(variables && Object.keys(variables).length ? '.' + JSON.stringify(variables) : '')
	);
}

function setLast(cacheKey, queryKey): void {
	LAST_RUN[cacheKey] = { key: queryKey, date: Date.now() };
}

function clearLast(cacheKey): void {
	delete LAST_RUN[cacheKey];
}

function clearAll(queryKey): void {
	Object.keys(LAST_RUN).forEach((key) => {
		if (LAST_RUN[key].key === queryKey) {
			delete LAST_RUN[key];
		}
	});
}

const isExpired = (queryKey, expiration = MAX_AGE): boolean => {
	const last = LAST_RUN[queryKey];
	return !last || Date.now() - last.date > expiration;
};

/**
 * Per-query cache expiration, based on a TTL (time-to-live) expiration
 * @param QUERY gql query document
 * @param variables variables passed to the query
 * @param expiration how long before the query cache expires (and needs to be re-fetched) defaults to 2 minutes
 * @returns
 */
export const useFetchPolicyTTL = (
	QUERY: DocumentNode,
	variables?: Record<string, any>,
	expiration?: number,
): { getFetchPolicy: () => WatchQueryFetchPolicy; setLastUpdate: () => void; clearLastUpdate: () => void } => {
	// query-only key to be able to clear by query
	const queryKey = useMemo(() => queryToKey(QUERY), [QUERY]);
	// cache key with vars to be able to check for expiration of a specific query (may be same as queryKey)
	const cacheKey = useMemo(() => queryToKey(QUERY, variables), [QUERY, variables]);

	const getFetchPolicy = useCallback(() => {
		if (isExpired(cacheKey, expiration)) return 'cache-and-network'; // network-only?
		return 'cache-first';
	}, [expiration, cacheKey]);

	const setLastUpdate = useCallback(() => {
		setLast(cacheKey, queryKey);
	}, [cacheKey, queryKey]);

	const clearLastUpdate = useCallback(() => {
		clearLast(cacheKey);
	}, [cacheKey]);

	return { getFetchPolicy, setLastUpdate, clearLastUpdate };
};

/**
 * Manually expire a query to force a refetch on next call.
 * Note: currently doesn't support queries with variables
 * @param QUERY gql query document
 */
export function expireQuery(QUERY: DocumentNode): void {
	clearAll(queryToKey(QUERY));
}
