import { forwardRef } from 'react';

import { Checkbox as ChakraCheckbox, CheckboxGroup, FormLabel } from '@chakra-ui/react';
import { Box, Flex, FormControl, FormControlProps, popFormControlProps } from '@elemeno/ui';

import { CheckboxProps, CheckboxDefaultProps } from './Checkbox.types';

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({ hint, subHint, ...props }, ref) => {
	const { poppedProps, otherProps } = popFormControlProps(props);

	return (
		<FormControl {...(poppedProps as FormControlProps)}>
			<Flex.H alignItems="start" gap="2">
				<ChakraCheckbox ref={ref} borderColor="gray.300" {...otherProps} mt="0.25em" />
				{hint ? (
					<FormLabel htmlFor={props.id} m="0">
						{hint}
						{subHint ? (
							<Box fontStyle="italic" fontSize="0.95em" lineHeight="1.2em" mt={1} mb={2}>
								{subHint}
							</Box>
						) : undefined}
					</FormLabel>
				) : null}
			</Flex.H>
		</FormControl>
	);
});

Checkbox.displayName = 'Checkbox';
Checkbox.defaultProps = CheckboxDefaultProps;

const CheckboxNotation = Object.assign(Checkbox, { Group: CheckboxGroup });

export { CheckboxNotation as Checkbox };
