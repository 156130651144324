import { forwardRef } from 'react';
import { Tag as ChakraTag, TagLabel, TagRightIcon, TagLeftIcon, TagCloseButton } from '@chakra-ui/react';
import { getChakraColorScheme } from '@elemeno/ui';

import { TagProps, TagDefaultProps } from './Tag.types';

const Tag = forwardRef<HTMLSpanElement, TagProps>(
	({ children, colorScheme, rightIcon, leftIcon, onClose, ...props }, ref) => {
		return (
			<ChakraTag ref={ref} colorScheme={getChakraColorScheme(colorScheme)} {...props}>
				{leftIcon && <TagLeftIcon as={leftIcon} />}
				<TagLabel>{children}</TagLabel>
				{rightIcon && <TagRightIcon as={rightIcon} />}
				{onClose && <TagCloseButton onClick={onClose} />}
			</ChakraTag>
		);
	},
);

Tag.displayName = 'Tag';
Tag.defaultProps = TagDefaultProps;

export { Tag };
