import { FC } from 'react';
import { ChakraProvider, ChakraProviderProps } from '@chakra-ui/react';

import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/400-italic.css'; // used in body text
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/500-italic.css'; // needed?
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/600-italic.css'; // needed?
import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans/700-italic.css'; // used in search dropdown

import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/800.css';
import '../Media/Video/jwplayer.css';

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import React, { PropsWithChildren, ReactElement } from 'react';

type EmotionCacheProviderProps = PropsWithChildren<unknown> & {
	nonce: string;
};

export const EmotionCacheProvider = ({ nonce, children }: EmotionCacheProviderProps): ReactElement => {
	const cache = createCache({ key: 'ehec', nonce });
	return <CacheProvider value={cache}>{children}</CacheProvider>;
};

interface ElemenoProviderProps extends ChakraProviderProps {
	nonce?: string;
}

export const ElemenoProvider: FC<ElemenoProviderProps> = ({ children, nonce = 'eh-testing', ...props }) => {
	return (
		<EmotionCacheProvider nonce={nonce}>
			<ChakraProvider {...props}>
				{/* TODO: add global components with context */}
				{children}
			</ChakraProvider>
		</EmotionCacheProvider>
	);
};
