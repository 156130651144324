import { FetchResult, useMutation } from '@apollo/client'; // BaseQueryOptions, QueryLazyOptions
import { useMemo } from 'react';
import { DocumentNode } from 'graphql';

import { AnalyticsEventOrEvents, useAnalytics } from 'analytics/useAnalytics';

import { errorToString } from 'utils/errorToString';

import { SimpleUseQueryResult } from './SimpleGqlHookTypes';
import logger from 'utils/logger';

/**
 * useMutation types
 *
 * @example
 * type Data { item }
 * type MutationData { queryName: QueryData }
 * type MutationVars { id }
 *
 * const useItemQuery : SimpleMutation<Data, MutationVars> = () => {
 *   const [runMutation, {data, error, loading}] =
 *      useLazyQuery<MutationData, MutationVars>(GQL);
 *   return [
 *     variables: MutationVars => runMutation({variables}),
 *     { data: data?.queryName, error, loading }
 *   ]
 * }
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SimpleMutationFunction<TData, TVars = undefined | any> = [TVars] extends [undefined]
	? {
			(variables: undefined, options: { noAwait: true }): void;
			(variables: undefined, options?: { noAwait?: false }): Promise<TData>;
	  }
	: {
			(variables: TVars, options: { noAwait: true }): void;
			(variables: TVars, options?: { noAwait?: false }): Promise<TData>;
	  };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SimpleMutationResult<TData = any, TVars = undefined | any> = [
	SimpleMutationFunction<TData, TVars>,
	SimpleUseQueryResult<TData>,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SimpleMutation<TData = any, TVars = undefined | any, TContext = undefined | any> = {
	(context?: TContext): SimpleMutationResult<TData, TVars>;
};

/**
 * Simplified useMutation hook that takes a simple gql mutation and the mutation name,
 * and returns a mutation method and state tuple.
 * Note that the method returned should be reentrant (multi-instance), but the data/loading/error
 * are not, and will likely represent the result of the last mutation call.
 *
 * @param {qql} MUTATION gql
 * @param {string} MUTATION_NAME string
 * @param {object} options
 * @param {array} options.refetchQueries array of queries to refetch.
 * @param {function} options.successEvent If specified, track an analytics event on success.
 * @param {function} options.errorEvent If specified, track an analytics event on error.
 * @returns [runMutation, {data, loading:boolean, error:Error}]
 *
 * @example
 * const MY_MUTATION = gql`mutation wrapper($id: number!) { myMutation(id:$id) { success } }`
 * type MyData = { success: boolean }
 * type MyVars = { id: number }
 * const myVars = { id: 1 }
 * const [runMutation, {data:MyData, error, loading}] = useMutation<MyData,MyVars>(MY_MUTATION, 'myMutation')
 * runMutation(myVars)
 * // data = { success: true }
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useSimpleMutation<Data, Vars = undefined | any>(
	MUTATION: DocumentNode,
	MUTATION_NAME: string,
	{
		refetchQueries,
		startEvent,
		successEvent,
		errorEvent,
	}: {
		refetchQueries?: Array<{ query: DocumentNode; variables?: Record<string, unknown> }>;
		startEvent?: Vars extends undefined // force ts to wrap
			? () => AnalyticsEventOrEvents
			: (vars: Vars) => AnalyticsEventOrEvents;
		successEvent?: [Vars] extends [undefined]
			? (data: Data) => AnalyticsEventOrEvents
			: (data: Data, vars: Vars) => AnalyticsEventOrEvents;
		errorEvent?: [Vars] extends [undefined]
			? (error: string) => AnalyticsEventOrEvents
			: (error: string, vars: Vars) => AnalyticsEventOrEvents;
	} = {},
): SimpleMutationResult<Data, Vars> {
	const { track } = useAnalytics();

	const [mutationFunction, { data, error, loading }] = useMutation(MUTATION, { refetchQueries });

	// eslint-disable-next-line sonarjs/cognitive-complexity
	const simpleMutationFunction = useMemo(() => {
		function _mutationFn(variables: Vars, options: { noAwait: true }): void;
		function _mutationFn(variables: Vars, options?: { noAwait?: false }): Promise<Data>;
		function _mutationFn(variables: undefined, options: { noAwait: true }): void;
		function _mutationFn(variables: undefined, options?: { noAwait?: false }): Promise<Data>;
		function _mutationFn(variables?: Vars, options: { noAwait?: boolean } = {}) {
			if (startEvent) {
				track(startEvent(variables || ({} as Vars))); // not sure why TS won't allow undefined instead of vars.
			}

			const promise = new Promise<Data>((resolve, reject) => {
				mutationFunction(variables ? { variables } : undefined)
					.then((result: FetchResult) => {
						if (successEvent) {
							track(successEvent(result?.data?.[MUTATION_NAME], variables || ({} as Vars)));
						}
						resolve(result?.data?.[MUTATION_NAME]);
					})
					.catch((err) => {
						if (errorEvent) {
							track(errorEvent(errorToString(err), variables || ({} as Vars)));
						}
						if (!options.noAwait) {
							reject(err);
						} else {
							logger.error(`SimpleMutation Error in ${MUTATION_NAME}`, err);
						}
					});
			});

			if (!options.noAwait) {
				return promise;
			}
		}

		return _mutationFn;
	}, [startEvent, track, mutationFunction, successEvent, MUTATION_NAME, errorEvent]);

	return [simpleMutationFunction, { data: data?.[MUTATION_NAME], error, loading }];
}
