import { forwardRef } from 'react';

import { FormLabel, Switch as ChakraSwitch } from '@chakra-ui/react';
import { Flex, FormControl, FormControlProps, getChakraColorScheme, popFormControlProps } from '@elemeno/ui';

import { SwitchProps, SwitchDefaultProps } from './Switch.types';

const Switch = forwardRef<HTMLInputElement, SwitchProps>(({ hint, colorScheme, startSelected, ...props }, ref) => {
	const { poppedProps, otherProps } = popFormControlProps(props);

	return (
		<FormControl {...(poppedProps as FormControlProps)}>
			<Flex.H alignItems="center" gap="2">
				<ChakraSwitch
					ref={ref}
					defaultChecked={startSelected}
					colorScheme={getChakraColorScheme(colorScheme)}
					{...otherProps}
				/>
				{hint ? (
					<FormLabel htmlFor={props.id} m="0">
						{hint}
					</FormLabel>
				) : null}
			</Flex.H>
		</FormControl>
	);
});

Switch.displayName = 'Switch';
Switch.defaultProps = SwitchDefaultProps;

export { Switch };
