import { BadgeProps as ChakraBadgeProps } from '@chakra-ui/react';
import { ColorScheme } from '@elemeno/ui';

export interface BadgeProps extends ChakraBadgeProps {
	variant?: 'solid' | 'subtle' | 'outline';
	colorScheme?: ColorScheme;
}

export const BadgeDefaultProps: BadgeProps = {
	variant: 'subtle',
	colorScheme: 'elemenoMediumBlue',
};
