import { useEffect } from 'react';

const DEFAULT_TITLE = 'Elemeno Health';

export const useTitle = (...segments: Array<string | undefined>) => {
	useEffect(() => {
		const title = [...segments.map((segment) => segment?.split(' - ').reverse().join(' - ')), DEFAULT_TITLE]
			.filter(Boolean)
			.join(' - ');
		if (document.title !== title) {
			document.title = title;
		}
	}, [segments]);
};
