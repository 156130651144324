import { isOffline, isTest, STAGE } from 'helpers/constants';

const siteIndicatorBarStyle = {
	position: 'fixed',
	top: 0,
	left: 0,
	right: 0,
	height: '0.25rem',
	backgroundColor: 'yellow',
	zIndex: 1000000,
	textAlign: 'center',
	pointerEvents: 'none',
} as React.CSSProperties;

const siteIndicatorBoxStyle = {
	position: 'relative',
	top: '-0.125rem',
	display: 'inline-block',
	backgroundColor: 'yellow',
	padding: '0 0.938rem 0.125rem',
	borderBottomLeftRadius: '8px',
	borderBottomRightRadius: '8px',
	fontSize: '0.75rem',
	zIndex: 1000001,
} as React.CSSProperties;

export function SiteIndicator(): JSX.Element | null {
	if (isTest) return null;

	const stage = isOffline ? 'offline' : STAGE;
	return (
		<div style={siteIndicatorBarStyle}>
			<div style={siteIndicatorBoxStyle}>
				{stage}: {window.SITE_ID}
			</div>
		</div>
	);
}
