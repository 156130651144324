import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';

import { ElemenoProvider, ELEMENO_THEME } from '@elemeno/ui';

import { PreLoading, SiteContext } from 'contexts/SiteContext';
import { linkRewriting } from 'helpers/linkRewriting';
import { ErrorBoundary, init as initSentry } from 'utils/ErrorLogger';
import { AccessTest } from 'componentPages/AccessTest';

import * as serviceWorker from './serviceWorker';
import logger from 'utils/logger';

const App = lazy(() => import('app/App'));

initSentry();

linkRewriting();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const suspenseApp = (): JSX.Element => (
	<Suspense fallback={<PreLoading message="Loading data..." />}>
		<App />
	</Suspense>
);

const render = (): void => {
	if (window.location.hash === '#/test' || window.location.pathname === '/test') {
		root.render(
			<ElemenoProvider theme={ELEMENO_THEME}>
				<AccessTest />
			</ElemenoProvider>,
		);
	} else {
		root.render(
			<ErrorBoundary>
				<SiteContext render={suspenseApp} />
			</ErrorBoundary>,
		);
	}
};

if (import.meta.env.VITE_ENABLE_MOCKS === 'true') {
	root.render(<PreLoading message="Setting up mocks..." />);
	import('./mocks/browser')
		.then(({ worker }) => {
			worker
				.start({
					onUnhandledRequest(req, print) {
						const { pathname } = req.url;
						if (
							pathname.startsWith('/src/') ||
							pathname.startsWith('/@fs/') ||
							pathname.startsWith('/node_modules/') ||
							pathname === '/elemeno-color.svg'
						) {
							return;
						}

						print.warning();
					},
				})
				.catch((err) => logger.error('Error starting mock service worker', err));
			render();
		})
		.catch((err) => logger.error('Error starting mock service worker', err));
} else {
	serviceWorker.unregister();
	render();
}
