import { forwardRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';

import { HiX } from 'react-icons/hi';
import { Box, Icon, Input, InputRightElement } from '@elemeno/ui';

import { DateRangePickerProps, DateRangePickerDefaultProps } from './DateRangePicker.types';

/**
 * TODO : This is a copy of DatePicker that is non-mobile to support "selectsRange" on DatePicker.
 *        DatePicker still includes some range affordances along with some code that expects a single date.
 *        Options:
 *        1. Update DatePicker to remove range affordances and have a separate range component, and update DateRangePicker to be range-specific, or
 *        2. Update DatePicker to support ranges with standard date inputs to improve range selection on mobile devices (and a more clear/consistent component interface).
 */

const DateRangePicker = <CustomModifierNames extends string = never, WithRange extends boolean | undefined = undefined>(
	{
		size,
		variant,
		label,
		placeholder,
		hint,
		caption,
		error,
		isInvalid,
		isRequired,
		isDisabled,
		isReadOnly,
		isClearable,
		...props
	}: DateRangePickerProps<CustomModifierNames, WithRange>,
	ref: React.ForwardedRef<ReactDatePicker<CustomModifierNames, WithRange>>,
): JSX.Element => {
	return (
		<ReactDatePicker
			ref={ref}
			required={isRequired}
			disabled={isDisabled}
			readOnly={isReadOnly}
			placeholderText={placeholder}
			minTime={new Date(dayjs().startOf('day').valueOf())}
			maxTime={new Date(dayjs().endOf('day').valueOf())}
			customInput={
				<Input
					size={size}
					variant={variant}
					label={label}
					hint={hint}
					caption={caption}
					error={error}
					isInvalid={isInvalid}
					isRequired={isRequired}
					isDisabled={isDisabled}
					isReadOnly={isReadOnly}
					contentRight={
						(props.selected || props.startDate || props.endDate) &&
						isClearable && (
							<InputRightElement>
								<Icon.Button
									size="sm"
									icon={<HiX />}
									aria-label="Clear"
									onClick={(event) => {
										event.preventDefault();
										if (props.onChange) {
											props.onChange(
												(props.selectsRange ? [null, null] : null) as WithRange extends
													| false
													| undefined
													? Date | null
													: [Date | null, Date | null],
												event,
											);
										}
									}}
								/>
							</InputRightElement>
						)
					}
				/>
			}
			disabledKeyboardNavigation
			popperContainer={({ children }) => <Box zIndex={2}>{children}</Box>}
			popperPlacement="bottom"
			popperModifiers={[
				{
					name: 'offset',
					options: {
						offset: [0, hint || error ? -28 : 0],
					},
				},
			]}
			{...props}
		/>
	);
};

// forwardRef don't support generic type
const DateRangePickerWithRef = forwardRef(DateRangePicker as any) as <
	CustomModifierNames extends string = never,
	WithRange extends boolean | undefined = undefined,
>(
	props: DateRangePickerProps<CustomModifierNames, WithRange> & {
		ref?: React.ForwardedRef<ReactDatePicker<CustomModifierNames, WithRange>>;
	},
) => ReturnType<typeof DateRangePicker>;

// @ts-ignore
DateRangePickerWithRef.displayName = 'DateRangePicker';
// @ts-ignore
DateRangePickerWithRef.defaultProps = DateRangePickerDefaultProps;

export { DateRangePickerWithRef as DateRangePicker };
