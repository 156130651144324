import { LinkProps as ChakraLinkProps } from '@chakra-ui/react';

export interface LinkProps extends ChakraLinkProps {
	to?: string;
	href?: string;
	state?: string;
	unstyled?: boolean;
	suppressUnmodifiedClicks?: boolean;
}

export const LinkDefaultProps: LinkProps = {
	to: '',
	href: '',
	state: '',
	isExternal: false,
};
