const elemenoRegex = /(-elemenohealth-com|-lmno-care)/;

/**
 * Check if user is elemeno personnel (elemeno email or flag on user)
 * @param User
 * @returns boolean
 */
export function isElemenoUser({ isElemenoUser = false, username = '' }): boolean {
	if (isElemenoUser) {
		return true;
	}
	return elemenoRegex.test(username);
}

export enum UserPrivileges {
	Super = 'SUPER',
	CustomerSuccess = 'CUSTOMER_SUCCESS',
	Admin = 'ADMIN',
	Manager = 'MANAGE_USERS',
	Approver = 'APPROVER',
	Contributor = 'CONTRIBUTOR',
	// valid "privileges" (i.e. roles), but not used
	Student = 'STUDENT',
	Faculty = 'FACULTY',
}

const SUPER_PRIVILEGES = [UserPrivileges.Super];
const CUSTOMER_SUCCESS_PRIVILEGES = [...SUPER_PRIVILEGES, UserPrivileges.CustomerSuccess];
const ADMIN_PRIVILEGES = [...CUSTOMER_SUCCESS_PRIVILEGES, UserPrivileges.Admin];
const MANAGER_PRIVILEGES = [...ADMIN_PRIVILEGES, UserPrivileges.Manager];
const APPROVER_PRIVILEGES = [...MANAGER_PRIVILEGES, UserPrivileges.Approver];
const CONTRIBUTOR_PRIVILEGES = [...APPROVER_PRIVILEGES, UserPrivileges.Contributor];

// TODO UNUSED: UserProfile doesn't contain privileges, so these generally won't work.
export function userHasPrivileges(user, privileges: UserPrivileges[]) {
	return !!user?.privileges?.length && privileges.some((privilege) => user.privileges.includes(privilege));
}

export function userHasSuperPrivileges(user) {
	return userHasPrivileges(user, SUPER_PRIVILEGES);
}

export function userHasCustomerSuccessPrivileges(user) {
	return userHasPrivileges(user, CUSTOMER_SUCCESS_PRIVILEGES);
}

export function userHasAdminPrivileges(user) {
	return userHasPrivileges(user, ADMIN_PRIVILEGES);
}

export function userHasManagerPrivileges(user) {
	return userHasPrivileges(user, MANAGER_PRIVILEGES);
}

export function userHasApproverPrivileges(user) {
	return userHasPrivileges(user, APPROVER_PRIVILEGES);
}

export function userHasContributorPrivileges(user) {
	return userHasPrivileges(user, CONTRIBUTOR_PRIVILEGES);
}
