import { forwardRef } from 'react';
import { Code } from '@chakra-ui/react';
import { getChakraColorScheme } from '@elemeno/ui';

import { CodeLightProps, CodeLightDefaultProps } from './CodeLight.types';

const CodeLight = forwardRef<HTMLElement, CodeLightProps>(({ colorScheme, ...props }, ref) => {
	return <Code ref={ref} colorScheme={getChakraColorScheme(colorScheme)} {...props} />;
});

CodeLight.displayName = 'CodeLight';
CodeLight.defaultProps = CodeLightDefaultProps;

export { CodeLight };
