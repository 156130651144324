import { forwardRef, useMemo } from 'react';
import {
	Drawer as ChakraDrawer,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
} from '@chakra-ui/react';
import { Button } from '@elemeno/ui';

import { DrawerProps, DrawerDefaultProps } from './Drawer.types';

const Drawer = forwardRef<HTMLDivElement, DrawerProps>(
	(
		{
			children,
			title,
			buttons,
			startFocused,
			isFocusReturned,
			canPinchZoom,
			canEscape,
			canOverlayClose,
			isScrollDisabled,
			hasScrollGap,
			...props
		},
		ref,
	) => {
		const actionButtons = useMemo(
			() => buttons?.map(({ ...props }, index) => <Button key={index} {...props} />) || null,
			[buttons],
		);

		return (
			<ChakraDrawer
				// eslint-disable-next-line jsx-a11y/no-autofocus
				autoFocus={startFocused}
				returnFocusOnClose={isFocusReturned}
				allowPinchZoom={canPinchZoom}
				closeOnEsc={canEscape}
				closeOnOverlayClick={canOverlayClose}
				blockScrollOnMount={isScrollDisabled}
				preserveScrollBarGap={hasScrollGap}
				{...props}
			>
				<DrawerOverlay />
				<DrawerContent ref={ref}>
					<DrawerCloseButton />
					<DrawerHeader {...HeaderStyles}>{title}</DrawerHeader>

					<DrawerBody>{children}</DrawerBody>

					{buttons?.length && (
						<DrawerFooter>
							<Button.Group {...ButtonGroupStyles}>{actionButtons}</Button.Group>
						</DrawerFooter>
					)}
				</DrawerContent>
			</ChakraDrawer>
		);
	},
);

const HeaderStyles = {
	fontSize: 'lg',
	fontWeight: 'bold',
};

const ButtonGroupStyles = {
	spacing: '0.75rem',
};

Drawer.displayName = 'Drawer';
Drawer.defaultProps = DrawerDefaultProps;

export { Drawer };
