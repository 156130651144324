import { forwardRef } from 'react';
import { Stat as ChakraStat, StatLabel, StatNumber, StatHelpText, StatArrow, StatGroup } from '@chakra-ui/react';

import { StatProps, StatDefaultProps } from './Stat.types';

const Stat = forwardRef<HTMLDivElement, StatProps>(({ children, label, subLabel, variant, ...props }, ref) => {
	return (
		<ChakraStat ref={ref} {...props}>
			<StatNumber fontSize="1em" fontWeight="bold">
				{children}
			</StatNumber>
			{label && (
				<StatLabel fontSize="0.35em" fontWeight="bold">
					{label}
				</StatLabel>
			)}
			{subLabel && (
				<StatHelpText>
					{variant !== 'normal' && <StatArrow type={variant} />}
					{subLabel}
				</StatHelpText>
			)}
		</ChakraStat>
	);
});

Stat.displayName = 'Stat';
Stat.defaultProps = StatDefaultProps;

const StatNotation = Object.assign(Stat, { Group: StatGroup });
export { StatNotation as Stat };
