import { useCallback, useEffect, useState } from 'react';

import { API_URL } from 'helpers/constants';

/**
 * This is a custom query using standard browser tools to do a lookup before
 * downloading and initializing the remainder of the app.
 */

interface SsoConfigData {
	provider_name: string;
	client_id: string;
	redirect: boolean;
	singleLogout: boolean;
	singleLogoutUrl?: string;
	only: boolean;
	hidden: boolean;
	display_name: string;
	button_text: string;
	cognito_url: string;
}

interface AssignmentsConfigData {
	path: string;
	menu: string;
	title: string;
}

interface RecognitionsConfigData {
	url: string;
}

interface CustomConfigData {
	assignments?: AssignmentsConfigData;
	recognitions?: RecognitionsConfigData;
}

export enum CLIENT_FEATURE_FLAGS {
	ENABLE_ANNOUNCEMENTS = 'ENABLE_ANNOUNCEMENTS',
	ENABLE_CONTENT_REQUEST = 'ENABLE_CONTENT_REQUEST',
	ENABLE_NEWSLETTERS = 'ENABLE_NEWSLETTERS',
	ENABLE_PHONE_NUMBERS = 'ENABLE_PHONE_NUMBERS',
	ENABLE_RESOURCE_NETWORK = 'ENABLE_RESOURCE_NETWORK',
	ENABLE_SIMPLE_CONTENT_REQUEST = 'ENABLE_SIMPLE_CONTENT_REQUEST',
	KEEP_ME_LOGGED_IN_OPTION = 'KEEP_ME_LOGGED_IN_OPTION',
	ENABLE_RESOURCE_REVIEW = 'ENABLE_RESOURCE_REVIEW',
	USE_AUTH_SESSION_TOKENS = 'USE_AUTH_SESSION_TOKENS',
	ENABLE_ANNOUNCEMENT_NOTIFICATIONS = 'ENABLE_ANNOUNCEMENT_NOTIFICATIONS',
}

export interface SiteConfigInterface {
	siteId: string;
	alternateSiteName?: string;
	type: string;
	displayName?: string;
	siteLogo?: string;
	appicon?: string;
	sessionDuration?: number;
	screenRecording?: boolean;
	requestAccessOnly?: boolean;
	restrictRegistration?: string;
	showElemeno?: boolean;
	sso?: SsoConfigData;
	custom?: CustomConfigData;
	isSystemSite?: boolean;
	simpleSystemRedirects?: boolean;
	childSites?: string[];
	siblingSites?: string[];
	parentSite?: string;
	noPasswordNeeded?: boolean;
	clientFeatureFlags?: string[];
}

export type SiteConfigQueryResult = {
	data?: SiteConfigInterface | undefined;
	loading: boolean;
	error: Error | undefined;
};

const SITE_CONFIG_COMPACT_QUERY = `
	query getSiteConfigCompact($siteId: String!) {
		getSiteConfigCompact(siteId: $siteId) {
			siteId
			siteRedirect
			type
			displayName
			siteLogo
			appicon
			sessionDuration
			sso {
				provider_name
				client_id
				cognito_url
				redirect
				singleLogout
				singleLogoutUrl
				only
				hidden
				display_name
				button_text
			}
			screenRecording
			showElemeno
			requestAccessOnly
			restrictRegistration
			custom {
				assignments {
					menu
					title
				}
				recognitions {
					url
				}
			}
			isSystemSite
			simpleSystemRedirects
			childSites
			siblingSites
			parentSite
			noPasswordNeeded
			clientFeatureFlags
		}
	}
`;

export function useSiteConfigQuery(siteId: string): SiteConfigQueryResult {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState<SiteConfigInterface>();
	const [error, setError] = useState<Error>();

	function handleError(err: Error) {
		setLoading(false);
		setError(err);
	}

	const onLoad = useCallback(
		// eslint-disable-next-line sonarjs/cognitive-complexity
		(request: XMLHttpRequest) => {
			if (request.status === 200) {
				// Success!
				let json: any;
				try {
					json = JSON.parse(request.response);
				} catch (ex) {
					handleError(new Error('invalid json'));
					return;
				}

				if (json?.errors?.length) {
					handleError(new Error(json.errors[0].message || 'unknown error'));
				} else {
					const config = json?.data?.getSiteConfigCompact;
					if (config?.siteRedirect) {
						window.location.href = window.location.href.replace(
							'://' + siteId,
							'://' + config.siteRedirect,
						);
					} else {
						// make sure sso config is valid
						const hasSso = config?.sso?.client_id;
						setData(hasSso ? config : { ...config, sso: undefined });
						setLoading(false);
					}
				}
			} else {
				// We reached our target server, but it returned an error
				handleError(new Error('server error:' + request.statusText));
			}
		},
		[siteId],
	);

	const onError = useCallback((request: XMLHttpRequest) => {
		// There was a connection error of some sort
		// TODO: auto-retry mechanism??
		handleError(new Error('unable to connect to server' + (request.statusText ? `(${request.statusText})` : '')));
	}, []);

	useEffect(() => {
		if (!siteId) {
			return;
		}

		const request = new XMLHttpRequest();
		request.open('POST', API_URL, true);
		request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');

		request.send(
			JSON.stringify({
				operationName: 'getSiteConfigCompact',
				query: SITE_CONFIG_COMPACT_QUERY,
				variables: { siteId },
			}),
		);

		request.onload = () => {
			onLoad(request);
		};

		request.onerror = () => {
			onError(request);
		};
	}, [onError, onLoad, siteId]);

	return { data, error, loading };
}
