import { useMemo } from 'react';

interface PopPropsReturnData<T> {
	poppedProps: Partial<T>;
	otherProps: Partial<T>;
}

export const popProps = <T>(props: T, query: Array<keyof T>): PopPropsReturnData<T> => {
	// Components have different props, doesn't make sense to type this
	const entries = Object.entries(props as any) as Array<[keyof T, any]>;
	return entries.reduce<PopPropsReturnData<T>>(
		(acc, [prop, value]) => {
			if (query.includes(prop)) {
				acc.poppedProps[prop] = value;
			} else {
				acc.otherProps[prop] = value;
			}
			return acc;
		},
		{ poppedProps: {}, otherProps: {} },
	);
};

export const usePopProps = <T extends {}>(props: T, query: Array<keyof T>): PopPropsReturnData<T> => {
	return useMemo(() => popProps<T>(props, query), [...Object.values(props), ...query]);
};

export const popMarginProps = <T>(props: T): PopPropsReturnData<T> => {
	return popProps(props, [
		'margin',
		'marginTop',
		'marginRight',
		'marginBottom',
		'marginLeft',
		'marginStart',
		'marginEnd',
		'marginX',
		'marginY',
		'm',
		'mt',
		'mr',
		'mb',
		'ml',
		'mx',
		'my',
	] as Array<keyof T>);
};

export const popPaddingProps = <T>(props: T): PopPropsReturnData<T> => {
	return popProps(props, [
		'padding',
		'paddingTop',
		'paddingRight',
		'paddingBottom',
		'paddingLeft',
		'paddingStart',
		'paddingEnd',
		'paddingX',
		'paddingY',
		'p',
		'pt',
		'pr',
		'pb',
		'pl',
		'px',
		'py',
	] as Array<keyof T>);
};

export const popFormControlProps = <T>(props: T, query: Array<keyof T> = []): PopPropsReturnData<T> => {
	const { poppedProps, otherProps } = popProps(props, [
		'label',
		'labelComponent',
		'subLabel',
		'hint',
		'error',
		'caption',
		'isInvalid',
		'isDisabled',
		'isReadOnly',
		'isRequired',
		'margin',
		'marginTop',
		'marginRight',
		'marginBottom',
		'marginLeft',
		'marginStart',
		'marginEnd',
		'marginX',
		'marginY',
		...query,
	] as Array<keyof T>);

	return {
		poppedProps: {
			...poppedProps,
			labelProps: {
				// @ts-ignore
				htmlFor: otherProps.id,
			},
		},
		otherProps,
	};
};
