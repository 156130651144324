import { Box, Divider, Flex, Tag, Text, useDisclosure } from '@elemeno/ui';

import { TestDataItem } from './AccessTest.utils';

export const AccessTestCard: React.FC<{
	test: TestDataItem;
}> = ({ test }) => {
	const { isOpen, onToggle } = useDisclosure();

	const { type, source, purpose, severity, url, error, complete } = test;

	return (
		<Flex.V
			p={2}
			gap={2}
			border="1px"
			borderRadius="md"
			cursor="pointer"
			shadow="md"
			borderColor={error ? 'red.400' : complete ? 'green.400' : 'blue.400'}
			onClick={onToggle}
		>
			<Flex.H gap={4} alignItems="center">
				<Flex
					flex={1}
					gap={2}
					direction={{ base: 'column', tablet: 'row' }}
					alignItems={{ base: 'flex-start', tablet: 'center' }}
				>
					<Box w={28} minW={28}>
						<Tag colorScheme={error ? 'red.400' : complete ? 'green.400' : 'blue.400'}>{type}</Tag>
					</Box>

					<Flex.H gap={2} w={36} minW={36}>
						<Text fontSize="sm" fontWeight="semibold">
							Source:
						</Text>

						<Text fontSize="sm">{source}</Text>
					</Flex.H>

					<Flex.H gap={2} w={56} minW={56}>
						<Text fontSize="sm" fontWeight="semibold">
							Purpose:
						</Text>

						<Text fontSize="sm">{purpose}</Text>
					</Flex.H>
				</Flex>

				{error ? (
					<Text
						fontSize="sm"
						fontWeight="medium"
						color="red.400"
						textAlign="end"
					>{`${severity} severity`}</Text>
				) : complete ? (
					<Text>Passed</Text>
				) : (
					<Text>Running...</Text>
				)}
			</Flex.H>

			{isOpen && (
				<>
					<Divider />
					<Text wordBreak="break-all">{url}</Text>
					{error ? <Text color="red.400">{error}</Text> : null}
				</>
			)}
		</Flex.V>
	);
};
