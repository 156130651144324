import { CircularProgressProps as ChakraCircularProgressProps } from '@chakra-ui/react';

export interface CircularProgressProps extends Omit<ChakraCircularProgressProps, 'capIsRound'> {
	label?: string;
	value?: number;
	max?: number;
	min?: number;
	/** If `true`, the cap of the progress indicator will be rounded */
	hasRoundCap?: boolean;
	isIndeterminate?: boolean;
	thickness?: string | number;
	trackColor?: string;
}

export const CircularProgressDefaultProps: CircularProgressProps = {
	label: '',
	value: 0,
	max: 100,
	min: 0,
	hasRoundCap: true,
	isIndeterminate: false,
	thickness: '10px',
};
