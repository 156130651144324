import { forwardRef } from 'react';
import {
	Popover as ChakraPopover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	PopoverFooter,
	PopoverArrow,
} from '@chakra-ui/react';

import { PopoverProps, PopoverDefaultProps } from './Popover.types';

const Popover = forwardRef<HTMLDivElement, PopoverProps>(
	(
		{
			children,
			title,
			trigger,
			footer,
			isOnClick,
			startFocused,
			isClosedOnBlur,
			isPositionEager,
			startOpened,
			canPositionFlip,
			isWidthMatched,
			isOverflowHidden,
			isDisabled,
			...props
		},
		ref,
	) => {
		return (
			<ChakraPopover
				// eslint-disable-next-line jsx-a11y/no-autofocus
				autoFocus={startFocused}
				trigger={isOnClick ? 'click' : 'hover'}
				closeOnBlur={isClosedOnBlur}
				computePositionOnMount={isPositionEager}
				defaultIsOpen={startOpened}
				flip={canPositionFlip}
				matchWidth={isWidthMatched}
				preventOverflow={isOverflowHidden}
				{...props}
			>
				<PopoverTrigger>{trigger}</PopoverTrigger>
				{!isDisabled && (
					<PopoverContent ref={ref} boxShadow="0 5px 18px 0px rgb(0 0 0 / 30%)" width="fit-content">
						<PopoverArrow />
						{title && <PopoverHeader>{title}</PopoverHeader>}
						<PopoverBody>{children}</PopoverBody>
						{footer && <PopoverFooter>{footer}</PopoverFooter>}
					</PopoverContent>
				)}
			</ChakraPopover>
		);
	},
);

Popover.displayName = 'Popover';
Popover.defaultProps = PopoverDefaultProps;

export { Popover };
