import { FocusEventHandler } from 'react';
import { PinInputProps as ChakraPinInputProps } from '@chakra-ui/react';

export interface PinInputProps extends Omit<ChakraPinInputProps, 'mask' | 'autoFocus' | 'children'> {
	id?: string;
	label?: string;
	/** A sub label that appears below the component */
	hint?: string;
	caption?: string;
	/** If present, it will replace the hint and apply invalid styles */
	error?: string;
	isRequired?: boolean;
	isInvalid?: boolean;
	isDisabled?: boolean;
	isReadOnly?: boolean;
	/** If `true`, the input's value will be masked just like `type=password` */
	isPrivate?: boolean;
	variant?: 'outline' | 'filled' | 'flushed' | 'unstyled';
	size?: 'xs' | 'sm' | 'md' | 'lg';
	length?: number;
	/** If `true`, the pin input receives focus on mount */
	startFocused?: boolean;
	defaultValue?: string;
	placeholder?: string;
	onChange?: (value: string) => void;
	onComplete?: (value: string) => void;
	onFocus?: FocusEventHandler<HTMLInputElement>;
	onBlur?: FocusEventHandler<HTMLInputElement>;
}

export const PinInputDefaultProps: PinInputProps = {
	label: '',
	hint: '',
	caption: '',
	error: '',
	defaultValue: '',
	isRequired: false,
	isDisabled: false,
	isInvalid: false,
	isReadOnly: false,
	isPrivate: false,
	startFocused: false,
	variant: 'outline',
	size: 'md',
	length: 4,
};
