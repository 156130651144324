import { forwardRef } from 'react';
import { AspectRatio as ChakraAspectRatio } from '@chakra-ui/react';

import { AspectRatioProps, AspectRatioDefaultProps } from './AspectRatio.types';

const AspectRatio = forwardRef<HTMLDivElement, AspectRatioProps>(({ ...props }, ref) => {
	return <ChakraAspectRatio ref={ref} width="full" {...props} />;
});

AspectRatio.displayName = 'AspectRatio';
AspectRatio.defaultProps = AspectRatioDefaultProps;

export { AspectRatio };
