import { TooltipProps as ChakraTooltipProps } from '@chakra-ui/react';

export interface TooltipProps extends ChakraTooltipProps {}

export const TooltipDefaultProps: Omit<TooltipProps, 'children'> = {
	arrowPadding: 8,
	arrowSize: 10,
	closeDelay: 0,
	openDelay: 0,
	closeOnClick: true,
	closeOnEsc: true,
	closeOnPointerDown: true,
	closeOnScroll: false,
	defaultIsOpen: false,
	direction: 'ltr',
	gutter: 8,
	hasArrow: false,
	isDisabled: false,
	shouldWrapChildren: false,
	placement: 'bottom',
};
