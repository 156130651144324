import { forwardRef } from 'react';
import { Box as ChakraBox } from '@chakra-ui/react';

import { BoxProps, BoxDefaultProps } from './Box.types';

const Box = forwardRef<HTMLDivElement, BoxProps>(({ ...props }, ref) => {
	return <ChakraBox ref={ref} {...props} />;
});

Box.displayName = 'Box';
Box.defaultProps = BoxDefaultProps;

export { Box };
