import { forwardRef } from 'react';
import { Hide as ChakraHide } from '@chakra-ui/react';
import { Box } from '@elemeno/ui';

import { HideProps, HideDefaultProps } from './Hide.types';

const Hide = forwardRef<HTMLDivElement, HideProps>(({ children, ...props }, ref) => {
	return (
		<ChakraHide {...props}>
			<Box ref={ref}>{children}</Box>
		</ChakraHide>
	);
});

Hide.displayName = 'Hide';
Hide.defaultProps = HideDefaultProps;

export { Hide };
