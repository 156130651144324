import { makeVar } from '@apollo/client';
import { PhoneNumberSet } from 'models/user';
import { UserIdentityFragment } from 'models/userInfo';

export type UserProfileType = UserIdentityFragment & {
	// from UserIdentityFragment
	// username: string;
	// firstname?: string;
	// lastname?: string;
	// fullName?: string;
	// email?: string;
	// groups?: string[];
	// isManager?: boolean;

	newUserEmail?: string; // used as a temp state during signup process
	newUserCreated?: boolean; // used as a temp state during signup process
	// cognitoUsername?: string
	// fullName?: string
	// site?: string
	createdAt?: number;
	// lastLoginAt?: number
	// lastAssignmentsView?: number;
	lastRecognitionView?: number;
	// lastContestView?: number
	// regState?: string
	noPassword?: boolean;
	// viewedItemsStartMS?: number;
	// email2?: string
	// deactivated?: boolean
	// deleted?: boolean
	noUpdateEmails?: boolean;
	// externalUserId?: string
	// invitedBy?: string
	preferredSite?: string;
	viewedItemLookup?: Record<string, number>;
	isSuper?: boolean;
	isAdmin?: boolean;
	isElemenoUser?: boolean;
	intercomHash?: string;
	favorites?: string[];
	catalogFavorites?: string[];
	phoneNumbers?: PhoneNumberSet[];
};

export const UserProfile = makeVar<UserProfileType>({
	email: '',
	firstname: '',
	fullName: '',
	groups: [],
	lastname: '',
	lastRecognitionView: 0,
	// newUserEmail: '',
	phoneNumbers: [],
	teamIdForView: '',
	// token: '',
	username: '',
});
