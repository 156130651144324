import { extendTheme } from '@chakra-ui/react';
import { progressSizes } from '../../Feedback/Progress';

import { THEME_CONFIG, BREAKPOINT_VALUES, APP_BG_COLOR } from '../CONSTANTS';

export const themeColors = {
	elemenoOrange: {
		200: '#FFD199',
		300: '#FFB966',
		400: '#FFA233',
		500: '#FF8B00',

		// original
		100: '#FFEFDB',
		// 200: '#FFDDB3',
		// 300: '#FFC885',
		// 400: '#FFAF4D',
		// 500: '#FF8B00',
		600: '#E67E00',
		700: '#CC7000',
		800: '#A85D00',
		900: '#7A4300',
	},
	elemenoGreen: {
		200: '#8CDEBC',
		300: '#59CF9E',
		400: '#27C180',
		500: '#00B669',

		// original
		100: '#BDFFEA',
		// 200: '#14FFB5',
		// 300: '#00EBA0',
		// 400: '#00D692',
		// 500: '#00BE81',
		600: '#00AD76',
		700: '#009969',
		800: '#007A54',
		900: '#005C3F',
	},
	elemenoPink: {
		200: '#F9CFE6',
		300: '#FBBBDD',
		400: '#FBA4D3',
		500: '#FF78C1',

		// original
		100: '#FEF6FA',
		// 200: '#FDE7F3',
		// 300: '#FCD9EC',
		// 400: '#FBCBE5',
		// 500: '#FABEDE',
		600: '#F8A0CF',
		700: '#F57ABC',
		800: '#F0429F',
		900: '#C20F6F',
	},
	elemenoMutedPink: {
		100: '#66493C',
		200: '#7E514C',
		300: '#965C62',
		400: '#AE6C82',
		500: '#C67DA4',
		600: '#D396C7',
		700: '#DBAFDF',
		800: '#DEC9EA',
		900: '#EAE4F5',
	},
	elemenoDarkPurple: {
		100: '#F1EDFC',
		200: '#DDD3F8',
		300: '#CBBCF5',
		400: '#B39DF1',
		500: '#9577EB',
		600: '#8763E8',
		700: '#764DE5',
		800: '#6132E1',
		900: '#431AB2',
	},
	elemenoMediumBlue: {
		100: '#EBF2FF',
		200: '#D7E5FF',
		300: '#C2D7FE',
		400: '#A9C7FE',
		500: '#8FB5FE',
		600: '#71A3FE',
		700: '#538FFD',
		800: '#1C6AFD',
		900: '#0248CA',
	},
	elemenoYellow: {
		200: '#FFEAB6',
		300: '#FFE092',
		400: '#FFD66C',
		500: '#FFC738',

		// original
		100: '#FEF8E1',
		// 200: '#FDF1C4',
		// 300: '#FCEAA6',
		// 400: '#FBE183',
		// 500: '#FAD859',
		600: '#F3C007',
		700: '#D5A806',
		800: '#B28D05',
		900: '#816604',
	},
	elemenoLightBlue: {
		100: '#F5F8FF',
		200: '#ECF2FE',
		300: '#DDE8FD',
		400: '#D3E1FD',
		500: '#C4D7FC',
		600: '#A8C3FB',
		700: '#81A9F9',
		800: '#558BF6',
		900: '#0C57EE',
	},
	elemenoBlueGray: {
		100: '#EBEDEF',
		200: '#DADDE1',
		300: '#C1C5CD',
		400: '#A7ADB9',
		500: '#858D9D',
		600: '#747E90',
		700: '#666F7F',
		800: '#565D6B',
		900: '#3B404A',
	},
	elemenoPurple: {
		200: '#BCAAEF',
		300: '#A78EEE',
		400: '#8B69EC',
		500: '#7E57ED',

		// original
		100: '#F8F6FE',
		// 200: '#F1ECFD',
		// 300: '#E6DEFC',
		// 400: '#DED5FB',
		// 500: '#D4C7FA',
		600: '#C2AFF8',
		700: '#AC93F5',
		800: '#9373F2',
		900: '#673AED',
	},
	elemenoBrightBlue: {
		50: '#f2f5ff',
		100: '#E5EBFF',
		200: '#C7D2FF',
		300: '#A3B6FF',
		400: '#7591FF',
		500: '#325AFF',
		600: '#1A47FF',
		700: '#0032FA',
		800: '#0029CC',
		900: '#001E94',
	},
	elemenoBlue: {
		200: '#92CFFB',
		300: '#62BAFA',
		400: '#2DA4FB',
		500: '#0091FB',

		// generated by https://www.tints.dev/blue/0091FB
		100: '#CCEAFF',
		600: '#0074C7',
		700: '#005999',
		800: '#003C66',
		900: '#001E33',
	},
	elemenoNavy: {
		200: '#6598C7',
		300: '#407DB5',
		400: '#1D66AA',
		500: '#0658A4',

		// generated by https://www.tints.dev/navy/0658A4
		100: '#BFDFFD',
		600: '#054785',
		700: '#043562',
		800: '#022240',
		900: '#011222',
	},
	elemenoGray: {
		200: '#A5B7C3',
		300: '#92A8B8',
		400: '#829AAB',
		500: '#768E9F',
		600: '#4D5D6A',

		// generated by https://www.tints.dev/gray/768E9F
		100: '#E4E9EC',
		700: '#455763',
		800: '#2E3A42',
		900: '#171D21',
	},
	elemenoRed: {
		500: '#FF0C0C',

		// generated by https://www.tints.dev/red/FF0C0C
		100: '#FFCCCC',
		200: '#FF9E9E',
		300: '#FF6B6B',
		400: '#FF3D3D',
		600: '#D60000',
		700: '#9E0000',
		800: '#6B0000',
		900: '#330000',
	},
};

// TODO: This is pretty basic right now

const fontFamily = {
	heading: `'Poppins', sans-serif`,
	body: `'Open Sans', sans-serif`,
};

export const ELEMENO_THEME = extendTheme({
	config: THEME_CONFIG,
	breakpoints: BREAKPOINT_VALUES,
	// colors generated with https://www.tints.dev/elemenoGreen/FF8B00
	colors: themeColors,
	components: {
		Button: {
			baseStyle: {
				fontFamily: fontFamily.heading,
				fontWeight: 'semibold',
			},
		},
		Progress: {
			sizes: progressSizes,
		},
	},
	fonts: {
		heading: fontFamily.heading,
		body: fontFamily.body,
	},
	fontSizes: {
		xs: '0.75rem',
		sm: '0.875rem',
		md: '1rem',
		lg: '1.125rem',
		xl: '1.25rem',
		'2xl': '1.5rem',
		'3xl': '1.875rem',
		'4xl': '2.25rem',
		'5xl': '3rem',
		'6xl': '4rem',
	},
	sizes: {
		container: {
			sm: '640px',
			md: '768px',
			lg: '1024px',
			xl: '1440px',
		},
	},
	styles: {
		global: {
			html: {
				height: '100%',
				width: '100%',
			},
			body: {
				height: '100%',
				width: '100%',
				fontWeight: 'medium',
				// fontSize: '0.9375rem', // 15px
				backgroundColor: APP_BG_COLOR,
			},
			'.how-to-resource-section ul, .how-to-resource-section ol': {
				paddingLeft: '1rem',
			},
			// // responsive font sizing will allow all elements sized with `em` units
			// // to scale up or down depending on screen size
			// [`@media screen and (min-width: ${BREAKPOINT_VALUES.tablet})`]: {
			// 	body: {
			// 		fontSize: '1rem', // 16px
			// 	},
			// },
			// [`@media screen and (min-width: ${BREAKPOINT_VALUES.desktop})`]: {
			// 	body: {
			// 		fontSize: '1.0625rem', // 17px
			// 	},
			// },
			// [`@media screen and (min-width: ${BREAKPOINT_VALUES.desktopMd})`]: {
			// 	body: {
			// 		fontSize: '1.125rem', // 18px
			// 	},
			// },
			// '*': {
			//   border: '1px solid #aaa',
			// },
		},
	},
});
