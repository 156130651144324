import { forwardRef } from 'react';
import { CopyBlock } from 'react-code-blocks';
import { Box, BoxProps } from '@elemeno/ui';

import { getTheme } from './Code.utils';
import { CodeProps, CodeDefaultProps } from './Code.types';

const Code = forwardRef<HTMLDivElement, CodeProps>(({ code, language, theme, onCopy, ...props }, ref) => {
	return (
		<Box ref={ref} {...CodeStyles} {...props}>
			<CopyBlock
				codeBlock
				text={code}
				theme={getTheme(theme || '')}
				showLineNumbers={false}
				{...{ language, onCopy }}
			/>
		</Box>
	);
});

const CodeStyles: BoxProps = {
	width: 'full',
	sx: {
		code: { paddingLeft: '0.75rem !important' },
		button: { marginTop: '0.375rem' },
	},
};

Code.displayName = 'Code';
Code.defaultProps = CodeDefaultProps;

export { Code };
