import { FocusEventHandler } from 'react';
import { NumberInputProps as ChakraNumberInputProps } from '@chakra-ui/react';

export interface NumberInputProps extends Omit<ChakraNumberInputProps, 'allowMouseWheel' | 'getAriaValueText'> {
	id?: string;
	label?: string;
	/** A sub label that appears below the component */
	hint?: string;
	caption?: string;
	/** If present, this will replace the hint and apply invalid styles */
	error?: string;
	isRequired?: boolean;
	isInvalid?: boolean;
	isDisabled?: boolean;
	isReadOnly?: boolean;
	variant?: 'outline' | 'filled' | 'flushed' | 'unstyled';
	size?: 'xs' | 'sm' | 'md' | 'lg';
	inputMode?: 'numeric' | 'decimal';
	defaultValue?: string | number;
	value?: string | number;
	max?: number;
	min?: number;
	step?: number;
	/** If `true`, the input's value will change based on mouse wheel */
	canScroll?: boolean;
	format?: (value: string | number) => string | number;
	/** This is used to format the value so that screen readers can speak out a more
	 * human-friendly value. It is used to set the `aria-valuetext` property of the input */
	formatAria?: (value: string | number) => string;
	onChange?: (valueAsString: string, valueAsNumber: number) => void;
	onFocus?: FocusEventHandler<HTMLInputElement>;
	onBlur?: FocusEventHandler<HTMLInputElement>;
	onInvalid?: ChakraNumberInputProps['onInvalid'];
}

export const NumberInputDefaultProps: NumberInputProps = {
	label: '',
	hint: '',
	caption: '',
	error: '',
	isRequired: false,
	isDisabled: false,
	isInvalid: false,
	isReadOnly: false,
	canScroll: false,
	variant: 'outline',
	size: 'md',
	inputMode: 'numeric',
	max: Number.MAX_SAFE_INTEGER,
	min: Number.MIN_SAFE_INTEGER,
	step: 1,
};
