import { forwardRef, MouseEventHandler } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Link as ChakraLink, LinkBox } from '@chakra-ui/react';
import { SystemProps } from '@elemeno/ui';

import { LinkOverlay } from './LinkOverlay';
import { LinkProps, LinkDefaultProps } from './Link.types';

/**
 * Helper to block unmodified clicks on links, if requested.
 * This allows for links to look and work like links for "right-click to open in new tab,
 * while suppressing the primary click event, which is often used for navigation within the app,
 * or for opening a popup, etc.
 * @param event click event
 */
export const onClickStopUnmodifiedPropagation: MouseEventHandler<HTMLAnchorElement> = (event) => {
	const modified = event.ctrlKey || event.shiftKey || event.altKey;

	if (!modified) {
		event.preventDefault();
		event.stopPropagation();
	}
};

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
	({ children, unstyled = false, suppressUnmodifiedClicks = false, ...props }, ref) => {
		return (
			<ChakraLink
				ref={ref}
				as={props.to ? RouterLink : undefined}
				{...(unstyled ? {} : LinkStyles)}
				{...(suppressUnmodifiedClicks ? { onClick: onClickStopUnmodifiedPropagation } : {})}
				{...props}
			>
				{children}
			</ChakraLink>
		);
	},
);

const LinkStyles: SystemProps = {
	color: 'blue.500',
	_hover: {
		textDecoration: 'underline',
		color: 'blue.700',
	},
};

Link.displayName = 'Link';
Link.defaultProps = LinkDefaultProps;

const LinkNotation = Object.assign(Link, { Box: LinkBox, Overlay: LinkOverlay });

export { LinkNotation as Link };
