import { FormControlProps as ChakraFormControlProps, FormLabelProps } from '@chakra-ui/react';

export interface FormControlProps extends ChakraFormControlProps {
	id?: string;
	label?: string;
	subLabel?: string; // a smaller italic line under the label
	/** A sub label that appears below the component */
	hint?: string;
	caption?: string;
	/** If present, it will replace the hint and apply invalid styles */
	error?: string;
	isRequired?: boolean;
	isDisabled?: boolean;
	isReadOnly?: boolean;
	labelProps?: FormLabelProps;
	labelComponent?: React.ReactNode;
}

export const FormControlDefaultProps: FormControlProps = {
	label: '',
	hint: '',
	caption: '',
	error: '',
	isRequired: false,
	isDisabled: false,
	isReadOnly: false,
};
