import { ChangeEventHandler, FocusEventHandler } from 'react';
import { InputProps as ChakraInputProps } from '@chakra-ui/react';

export interface InputProps extends ChakraInputProps {
	id?: string;
	label?: string;
	subLabel?: string; // a smaller italic line under the label
	placeholder?: string;
	/** A sub label that appears below the component */
	hint?: string;
	caption?: string;
	/** If present, it will replace the hint and apply invalid styles */
	error?: string;
	isRequired?: boolean;
	isInvalid?: boolean;
	isDisabled?: boolean;
	isReadOnly?: boolean;
	variant?: 'outline' | 'filled' | 'flushed' | 'unstyled';
	size?: 'xs' | 'sm' | 'md' | 'lg';
	onChange?: ChangeEventHandler<HTMLInputElement>;
	onFocus?: FocusEventHandler<HTMLInputElement>;
	onBlur?: FocusEventHandler<HTMLInputElement>;
	contentLeft?: React.ReactNode;
	contentRight?: React.ReactNode;
}

export const InputDefaultProps: InputProps = {
	label: '',
	subLabel: '',
	hint: '',
	caption: '',
	error: '',
	placeholder: '',
	isRequired: false,
	isDisabled: false,
	isInvalid: false,
	isReadOnly: false,
	variant: 'outline',
	size: 'md',
};
