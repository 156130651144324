import { forwardRef, Fragment, useState } from 'react';
import { useCombobox } from 'downshift';

import { HiOutlineSearch } from 'react-icons/hi';
import { List, ListItem } from '@chakra-ui/react';
import { Box, Divider, Icon, Input, InputLeftElement, Text } from '@elemeno/ui';

import { SearchOption, SearchProps, SearchDefaultProps } from './Search.types';

const EXPANDED_SEARCH_VALUE = 'expanded-search';

const Search = forwardRef<HTMLInputElement, SearchProps>(
	// eslint-disable-next-line sonarjs/cognitive-complexity
	({ onSelect, onSubmit, search, render, ...props }, ref) => {
		const [items, setItems] = useState<SearchOption[]>([]);
		const { isOpen, inputValue, selectedItem, highlightedIndex, getMenuProps, getItemProps, getInputProps } =
			useCombobox({
				id: props.id,
				items,
				stateReducer(_state, actionAndChanges) {
					const { type, changes } = actionAndChanges;

					switch (type) {
						// clear input value when select an item
						case useCombobox.stateChangeTypes.ItemClick:
						case useCombobox.stateChangeTypes.InputKeyDownEnter:
						case useCombobox.stateChangeTypes.InputBlur:
							return {
								...changes,
								// if we had an item selected.
								...(changes.selectedItem && {
									// clear the inputValue if the selected item was not the expanded search
									inputValue:
										changes.selectedItem.value !== EXPANDED_SEARCH_VALUE ? '' : changes.inputValue,
									// close the menu
									isOpen: false,
								}),
							};

						default:
							return changes;
					}
				},
				itemToString(item) {
					return item?.label || '';
				},
				onStateChange(changes) {
					// search when enter and no selection
					if (changes.type === useCombobox.stateChangeTypes.InputKeyDownEnter && !changes.selectedItem) {
						onSubmit?.(inputValue);
					}
				},
				onInputValueChange({ inputValue }) {
					// update items when input value changes
					setItems(() => {
						// clear items when input value is empty or don't have search function
						if (!search || !inputValue || inputValue === '') {
							return [];
						}

						// filter items when input value changes
						const filteredItems = (search ? search(inputValue) : [])
							// limit to 5 results
							.slice(0, 5);

						// add additional option to search
						filteredItems.push({
							value: EXPANDED_SEARCH_VALUE,
							label: inputValue,
						});

						return filteredItems;
					});
				},
				onSelectedItemChange({ selectedItem }) {
					// navigate to selected item
					if (selectedItem) {
						if (selectedItem.value === EXPANDED_SEARCH_VALUE) {
							onSubmit?.(selectedItem.label);
						} else {
							onSelect?.(
								selectedItem.value,
								selectedItem,
								inputValue,
								// remove the "expanded search" item from the list of "values
								items.slice(0, items.length - 1).map(({ value }) => value),
							);
						}
					}
				},
			});

		const isMenuVisible = isOpen && !props.isDisabled && !!inputValue;

		return (
			<Box position="relative">
				<Input
					w="full"
					shadow="none"
					rounded="xl"
					borderColor="gray.400"
					_hover={{ borderColor: 'gray.500' }}
					_focus={{ borderColor: 'gray.500' }}
					_focusVisible={{ outline: 'none' }}
					contentLeft={
						<InputLeftElement h={props.h || props.height}>
							<Icon.Button
								aria-label="Search"
								icon={<Icon as={HiOutlineSearch} color="gray.500" fontSize="lg" />}
								variant="link"
								onClick={() => onSubmit?.(inputValue)}
							/>
						</InputLeftElement>
					}
					{...props}
					{...getInputProps({ ref })}
				/>
				<List
					position="absolute"
					mt={2}
					w="full"
					bg="white"
					shadow="md"
					rounded="md"
					zIndex="dropdown"
					maxH={310}
					overflowY="auto"
					display={isMenuVisible ? 'block' : 'none'}
					{...getMenuProps()}
				>
					{isMenuVisible &&
						items.map((item, index) => (
							<Fragment key={item.value}>
								{items.length > 1 && item.value === EXPANDED_SEARCH_VALUE && <Divider />}
								<ListItem
									key={item.value}
									py={2}
									px={3}
									bg={highlightedIndex === index ? 'gray.100' : 'transparent'}
									fontWeight={selectedItem === item ? 'semibold' : 'normal'}
									cursor="pointer"
									{...getItemProps({ item, index })}
								>
									{item.value === EXPANDED_SEARCH_VALUE ? (
										<Text fontSize="md" fontWeight="medium" fontStyle="italic">
											Expanded search for&nbsp;
											<strong>{item.label}</strong>
										</Text>
									) : render ? (
										render(item)
									) : (
										<Text>{item.label}</Text>
									)}
								</ListItem>
							</Fragment>
						))}
				</List>
			</Box>
		);
	},
);

Search.displayName = 'Search';
Search.defaultProps = SearchDefaultProps;

export { Search };
