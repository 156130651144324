import { TextProps as ChakraTextProps, HighlightProps } from '@chakra-ui/react';
import { SystemProps } from '@elemeno/ui';

export interface TextProps extends ChakraTextProps {
	highlight?: Omit<HighlightProps, 'children'>;
	/** If present, a tooltip will be rendered. */
	tooltip?: string;
	/** If `true`, the tooltip will remain closed even if a tooltip is present. */
	isTooltipDisabled?: boolean;
	/** If `true`, a `<p />` tag will be rendered in place of a `<div />`. */
	isParagraph?: boolean;
	/** Truncate the text if it is greater then this number */
	lineClamp?: number;
	align?: SystemProps['textAlign'];
	casing?: SystemProps['textTransform'];
	decoration?: SystemProps['textDecoration'];
}

export const TextDefaultProps: TextProps = {
	tooltip: '',
	isTooltipDisabled: false,
	isParagraph: false,
	align: 'start',
	casing: 'none',
	decoration: 'none',
};
