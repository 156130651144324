import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Button as ChakraButton, ButtonGroup } from '@chakra-ui/react';
import { Icon, getChakraColorScheme } from '@elemeno/ui';

import { ButtonProps, ButtonDefaultProps } from './Button.types';

const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ colorScheme, rightIcon, leftIcon, ...props }, ref) => {
	return (
		<ChakraButton
			ref={ref}
			as={props.href ? 'a' : props.to ? RouterLink : undefined}
			rightIcon={rightIcon ? <Icon as={rightIcon} color="inherit" /> : undefined}
			leftIcon={leftIcon ? <Icon as={leftIcon} color="inherit" /> : undefined}
			colorScheme={getChakraColorScheme(colorScheme)}
			rounded="xl"
			{...props}
		/>
	);
});

Button.displayName = 'Button';
Button.defaultProps = ButtonDefaultProps;

const ButtonNotation = Object.assign(Button, { Group: ButtonGroup });
export { ButtonNotation as Button };
