import { ProgressProps as ChakraProgressProps } from '@chakra-ui/react';
import { ColorScheme } from '@elemeno/ui';

export interface ProgressProps extends Omit<ChakraProgressProps, 'colorScheme'> {
	size?: 'xs' | 'sm' | 'md' | 'lg';
	colorScheme?: ColorScheme | ChakraProgressProps['colorScheme'];
	value?: number;
	max?: number;
	min?: number;
	isIndeterminate?: boolean;
	hasStripe?: boolean;
	isAnimated?: boolean;
	title?: string;
}

export const ProgressDefaultProps: ProgressProps = {
	size: 'lg',
	colorScheme: 'elemenoMediumBlue' as ColorScheme,
	value: 0,
	max: 100,
	min: 0,
	isIndeterminate: false,
	hasStripe: false,
	isAnimated: false,
};

export const progressSizes = {
	xs: {
		track: {
			h: '1',
		},
	},
	sm: {
		track: {
			h: '3',
		},
	},
	md: {
		track: {
			h: '5',
		},
	},
	lg: {
		track: {
			h: '7',
		},
	},
};
