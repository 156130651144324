import { StatProps as ChakraStatProps } from '@chakra-ui/react';

export interface StatProps extends ChakraStatProps {
	variant?: 'normal' | 'increase' | 'decrease';
	label?: string;
	/** Label below the stat. This must be present to use the variants `increase` & `decrease`. */
	subLabel?: string; // a smaller italic line under the label
}

export const StatDefaultProps: StatProps = {
	variant: 'normal',
	label: '',
	subLabel: '',
};
