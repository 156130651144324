import { CodeProps } from '@chakra-ui/react';
import { ColorScheme } from '@elemeno/ui';

export interface CodeLightProps extends CodeProps {
	variant?: 'solid' | 'subtle' | 'outline';
	colorScheme?: ColorScheme;
}

export const CodeLightDefaultProps: CodeLightProps = {
	variant: 'subtle',
	colorScheme: 'gray',
};
