import { BoxProps } from '@elemeno/ui';

export interface DraggableListProps extends Omit<BoxProps, 'onChange'> {
	initialItems: string[];
	onChange?: (newItems: string[]) => void;
}

export const DraggableListDefaultProps: DraggableListProps = {
	initialItems: [],
};
